export const banks = [
  {
    bank_code: '001',
    name: 'Banco do Brasil S.A.',
  },
  {
    bank_code: '070',
    name: 'BRB - BANCO DE BRASILIA S.A.',
  },
  {
    bank_code: '272',
    name: 'AGK CORRETORA DE CAMBIO S.A.',
  },
  {
    bank_code: '136',
    name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI',
  },
  {
    bank_code: '104',
    name: 'CAIXA ECONOMICA FEDERAL',
  },
  {
    bank_code: '077',
    name: 'Banco Inter S.A.',
  },
  {
    bank_code: '741',
    name: 'BANCO RIBEIRAO PRETO S.A.',
  },
  {
    bank_code: '330',
    name: 'BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.',
  },
  {
    bank_code: '739',
    name: 'Banco Cetelem S.A.',
  },
  {
    bank_code: '743',
    name: 'Banco Semear S.A.',
  },
  {
    bank_code: '100',
    name: 'Planner Corretora de Valores S.A.',
  },
  {
    bank_code: '096',
    name: 'Banco B3 S.A.',
  },
  {
    bank_code: '747',
    name: 'Banco Rabobank International Brasil S.A.',
  },
  {
    bank_code: '322',
    name: 'Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz',
  },
  {
    bank_code: '748',
    name: 'BANCO COOPERATIVO SICREDI S.A.',
  },
  {
    bank_code: '752',
    name: 'Banco BNP Paribas Brasil S.A.',
  },
  {
    bank_code: '091',
    name: 'CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S',
  },
  {
    bank_code: '399',
    name: 'Kirton Bank S.A. - Banco Múltiplo',
  },
  {
    bank_code: '108',
    name: 'PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    bank_code: '756',
    name: 'BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB',
  },
  {
    bank_code: '757',
    name: 'BANCO KEB HANA DO BRASIL S.A.',
  },
  {
    bank_code: '102',
    name: 'XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A',
  },
  {
    bank_code: '084',
    name: 'UNIPRIME NORTE DO PARANÁ - COOPERATIVA DE CRÉDITO LTDA',
  },
  {
    bank_code: '180',
    name: 'CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  {
    bank_code: '066',
    name: 'BANCO MORGAN STANLEY S.A.',
  },
  {
    bank_code: '015',
    name: 'UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
  },
  {
    bank_code: '143',
    name: 'Treviso Corretora de Câmbio S.A.',
  },
  {
    bank_code: '062',
    name: 'Hipercard Banco Múltiplo S.A.',
  },
  {
    bank_code: '074',
    name: 'Banco J. Safra S.A.',
  },
  {
    bank_code: '099',
    name: 'UNIPRIME CENTRAL - CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.',
  },
  {
    bank_code: '326',
    name: 'PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
  },
  {
    bank_code: '025',
    name: 'Banco Alfa S.A.',
  },
  {
    bank_code: '315',
    name: 'PI Distribuidora de Títulos e Valores Mobiliários S.A.',
  },
  {
    bank_code: '075',
    name: 'Banco ABN Amro S.A.',
  },
  {
    bank_code: '040',
    name: 'Banco Cargill S.A.',
  },
  {
    bank_code: '307',
    name: 'Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    bank_code: '190',
    name: 'SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN',
  },
  {
    bank_code: '296',
    name: 'VISION S.A. CORRETORA DE CAMBIO',
  },
  {
    bank_code: '191',
    name: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    bank_code: '064',
    name: 'GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.',
  },
  {
    bank_code: '097',
    name: 'Credisis - Central de Cooperativas de Crédito Ltda.',
  },
  {
    bank_code: '016',
    name: 'COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI',
  },
  {
    bank_code: '299',
    name: 'SOROCRED   CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
  },
  {
    bank_code: '012',
    name: 'Banco Inbursa S.A.',
  },
  {
    bank_code: '003',
    name: 'BANCO DA AMAZONIA S.A.',
  },
  {
    bank_code: '060',
    name: 'Confidence Corretora de Câmbio S.A.',
  },
  {
    bank_code: '037',
    name: 'Banco do Estado do Pará S.A.',
  },
  {
    bank_code: '159',
    name: 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
  },
  {
    bank_code: '085',
    name: 'Cooperativa Central de Crédito - Ailos',
  },
  {
    bank_code: '114',
    name: 'Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP',
  },
  {
    bank_code: '036',
    name: 'Banco Bradesco BBI S.A.',
  },
  {
    bank_code: '394',
    name: 'Banco Bradesco Financiamentos S.A.',
  },
  {
    bank_code: '004',
    name: 'Banco do Nordeste do Brasil S.A.',
  },
  {
    bank_code: '320',
    name: 'China Construction Bank (Brasil) Banco Múltiplo S/A',
  },
  {
    bank_code: '189',
    name: 'HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS',
  },
  {
    bank_code: '105',
    name: 'Lecca Crédito, Financiamento e Investimento S/A',
  },
  {
    bank_code: '076',
    name: 'Banco KDB do Brasil S.A.',
  },
  {
    bank_code: '082',
    name: 'BANCO TOPÁZIO S.A.',
  },
  {
    bank_code: '286',
    name: 'COOPERATIVA DE CRÉDITO RURAL DE OURO   SULCREDI/OURO',
  },
  {
    bank_code: '093',
    name: 'PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT',
  },
  {
    bank_code: '273',
    name: 'Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel',
  },
  {
    bank_code: '290',
    name: 'Pagseguro Internet S.A.',
  },
  {
    bank_code: '259',
    name: 'MONEYCORP BANCO DE CÂMBIO S.A.',
  },
  {
    bank_code: '364',
    name: 'GERENCIANET PAGAMENTOS DO BRASIL LTDA',
  },
  {
    bank_code: '157',
    name: 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    bank_code: '183',
    name: 'SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P',
  },
  {
    bank_code: '014',
    name: 'STATE STREET BRASIL S.A. ? BANCO COMERCIAL',
  },
  {
    bank_code: '130',
    name: 'CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    bank_code: '127',
    name: 'Codepe Corretora de Valores e Câmbio S.A.',
  },
  {
    bank_code: '079',
    name: 'Banco Original do Agronegócio S.A.',
  },
  {
    bank_code: '340',
    name: 'Super Pagamentos e Administração de Meios Eletrônicos S.A.',
  },
  {
    bank_code: '081',
    name: 'BancoSeguro S.A.',
  },
  {
    bank_code: '133',
    name: 'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E',
  },
  {
    bank_code: '323',
    name: 'MERCADOPAGO.COM REPRESENTACOES LTDA.',
  },
  {
    bank_code: '121',
    name: 'Banco Agibank S.A.',
  },
  {
    bank_code: '083',
    name: 'Banco da China Brasil S.A.',
  },
  {
    bank_code: '138',
    name: 'Get Money Corretora de Câmbio S.A.',
  },
  {
    bank_code: '024',
    name: 'Banco Bandepe S.A.',
  },
  {
    bank_code: '319',
    name: 'OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  {
    bank_code: '274',
    name: 'MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT',
  },
  {
    bank_code: '095',
    name: 'Travelex Banco de Câmbio S.A.',
  },
  {
    bank_code: '094',
    name: 'Banco Finaxis S.A.',
  },
  {
    bank_code: '276',
    name: 'Senff S.A. - Crédito, Financiamento e Investimento',
  },
  {
    bank_code: '092',
    name: 'BRK S.A. Crédito, Financiamento e Investimento',
  },
  {
    bank_code: '047',
    name: 'Banco do Estado de Sergipe S.A.',
  },
  {
    bank_code: '144',
    name: 'BEXS BANCO DE CÂMBIO S/A',
  },
  {
    bank_code: '332',
    name: 'Acesso Soluções de Pagamento S.A.',
  },
  {
    bank_code: '126',
    name: 'BR Partners Banco de Investimento S.A.',
  },
  {
    bank_code: '325',
    name: 'Órama Distribuidora de Títulos e Valores Mobiliários S.A.',
  },
  {
    bank_code: '301',
    name: 'BPP Instituição de Pagamento S.A.',
  },
  {
    bank_code: '173',
    name: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
  },
  {
    bank_code: '331',
    name: 'Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.',
  },
  {
    bank_code: '380',
    name: 'PICPAY SERVICOS S.A.',
  },
  {
    bank_code: '119',
    name: 'Banco Western Union do Brasil S.A.',
  },
  {
    bank_code: '309',
    name: 'CAMBIONET CORRETORA DE CÂMBIO LTDA.',
  },
  {
    bank_code: '254',
    name: 'PARANÁ BANCO S.A.',
  },
  {
    bank_code: '268',
    name: 'BARI COMPANHIA HIPOTECÁRIA',
  },
  {
    bank_code: '107',
    name: 'Banco Bocom BBM S.A.',
  },
  {
    bank_code: '412',
    name: 'BANCO CAPITAL S.A.',
  },
  {
    bank_code: '124',
    name: 'Banco Woori Bank do Brasil S.A.',
  },
  {
    bank_code: '149',
    name: 'Facta Financeira S.A. - Crédito Financiamento e Investimento',
  },
  {
    bank_code: '197',
    name: 'Stone Pagamentos S.A.',
  },
  {
    bank_code: '142',
    name: 'Broker Brasil Corretora de Câmbio Ltda.',
  },
  {
    bank_code: '389',
    name: 'Banco Mercantil do Brasil S.A.',
  },
  {
    bank_code: '634',
    name: 'BANCO TRIANGULO S.A.',
  },
  {
    bank_code: '545',
    name: 'SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A',
  },
  {
    bank_code: '132',
    name: 'ICBC do Brasil Banco Múltiplo S.A.',
  },
  {
    bank_code: '298',
    name: "Vip's Corretora de Câmbio Ltda.",
  },
  {
    bank_code: '321',
    name: 'CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT',
  },
  {
    bank_code: '260',
    name: 'Nu Pagamentos S.A.',
  },
  {
    bank_code: '129',
    name: 'UBS Brasil Banco de Investimento S.A.',
  },
  {
    bank_code: '128',
    name: 'MS Bank S.A. Banco de Câmbio',
  },
  {
    bank_code: '194',
    name: 'PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  {
    bank_code: '310',
    name: 'VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
  },
  {
    bank_code: '163',
    name: 'Commerzbank Brasil S.A. - Banco Múltiplo',
  },
  {
    bank_code: '280',
    name: 'Avista S.A. Crédito, Financiamento e Investimento',
  },
  {
    bank_code: '146',
    name: 'GUITTA CORRETORA DE CAMBIO LTDA.',
  },
  {
    bank_code: '343',
    name: 'FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
  },
  {
    bank_code: '279',
    name: 'COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE',
  },
  {
    bank_code: '335',
    name: 'Banco Digio S.A.',
  },
  {
    bank_code: '349',
    name: 'AMAGGI S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    bank_code: '278',
    name: 'Genial Investimentos Corretora de Valores Mobiliários S.A.',
  },
  {
    bank_code: '271',
    name: 'IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
  },
  {
    bank_code: '021',
    name: 'BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO',
  },
  {
    bank_code: '246',
    name: 'Banco ABC Brasil S.A.',
  },
  {
    bank_code: '292',
    name: 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.',
  },
  {
    bank_code: '751',
    name: 'Scotiabank Brasil S.A. Banco Múltiplo',
  },
  {
    bank_code: '352',
    name: 'TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  {
    bank_code: '208',
    name: 'Banco BTG Pactual S.A.',
  },
  {
    bank_code: '746',
    name: 'Banco Modal S.A.',
  },
  {
    bank_code: '241',
    name: 'BANCO CLASSICO S.A.',
  },
  {
    bank_code: '336',
    name: 'Banco C6 S.A.',
  },
  {
    bank_code: '612',
    name: 'Banco Guanabara S.A.',
  },
  {
    bank_code: '604',
    name: 'Banco Industrial do Brasil S.A.',
  },
  {
    bank_code: '505',
    name: 'Banco Credit Suisse (Brasil) S.A.',
  },
  {
    bank_code: '329',
    name: 'QI Sociedade de Crédito Direto S.A.',
  },
  {
    bank_code: '196',
    name: 'FAIR CORRETORA DE CAMBIO S.A.',
  },
  {
    bank_code: '342',
    name: 'Creditas Sociedade de Crédito Direto S.A.',
  },
  {
    bank_code: '300',
    name: 'Banco de la Nacion Argentina',
  },
  {
    bank_code: '477',
    name: 'Citibank N.A.',
  },
  {
    bank_code: '266',
    name: 'BANCO CEDULA S.A.',
  },
  {
    bank_code: '122',
    name: 'Banco Bradesco BERJ S.A.',
  },
  {
    bank_code: '376',
    name: 'BANCO J.P. MORGAN S.A.',
  },
  {
    bank_code: '348',
    name: 'Banco XP S.A.',
  },
  {
    bank_code: '473',
    name: 'Banco Caixa Geral - Brasil S.A.',
  },
  {
    bank_code: '745',
    name: 'Banco Citibank S.A.',
  },
  {
    bank_code: '120',
    name: 'BANCO RODOBENS S.A.',
  },
  {
    bank_code: '265',
    name: 'Banco Fator S.A.',
  },
  {
    bank_code: '007',
    name: 'BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL',
  },
  {
    bank_code: '188',
    name: 'ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES',
  },
  {
    bank_code: '134',
    name: 'BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  {
    bank_code: '029',
    name: 'Banco Itaú Consignado S.A.',
  },
  {
    bank_code: '243',
    name: 'Banco Máxima S.A.',
  },
  {
    bank_code: '078',
    name: 'Haitong Banco de Investimento do Brasil S.A.',
  },
  {
    bank_code: '355',
    name: 'ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    bank_code: '367',
    name: 'VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
  },
  {
    bank_code: '373',
    name: 'UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
  },
  {
    bank_code: '111',
    name: 'OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.',
  },
  {
    bank_code: '306',
    name: 'PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
  },
  {
    bank_code: '017',
    name: 'BNY Mellon Banco S.A.',
  },
  {
    bank_code: '174',
    name: 'PERNAMBUCANAS FINANCIADORA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    bank_code: '495',
    name: 'Banco de La Provincia de Buenos Aires',
  },
  {
    bank_code: '125',
    name: 'Plural S.A. Banco Múltiplo',
  },
  {
    bank_code: '488',
    name: 'JPMorgan Chase Bank, National Association',
  },
  {
    bank_code: '065',
    name: 'Banco AndBank (Brasil) S.A.',
  },
  {
    bank_code: '492',
    name: 'ING Bank N.V.',
  },
  {
    bank_code: '145',
    name: 'LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.',
  },
  {
    bank_code: '250',
    name: 'BCV - BANCO DE CRÉDITO E VAREJO S.A.',
  },
  {
    bank_code: '354',
    name: 'NECTON INVESTIMENTOS  S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES',
  },
  {
    bank_code: '253',
    name: 'Bexs Corretora de Câmbio S/A',
  },
  {
    bank_code: '269',
    name: 'HSBC BRASIL S.A. - BANCO DE INVESTIMENTO',
  },
  {
    bank_code: '213',
    name: 'Banco Arbi S.A.',
  },
  {
    bank_code: '139',
    name: 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo',
  },
  {
    bank_code: '018',
    name: 'Banco Tricury S.A.',
  },
  {
    bank_code: '422',
    name: 'Banco Safra S.A.',
  },
  {
    bank_code: '630',
    name: 'Banco Smartbank S.A.',
  },
  {
    bank_code: '224',
    name: 'Banco Fibra S.A.',
  },
  {
    bank_code: '600',
    name: 'Banco Luso Brasileiro S.A.',
  },
  {
    bank_code: '623',
    name: 'Banco Pan S.A.',
  },
  {
    bank_code: '655',
    name: 'Banco Votorantim S.A.',
  },
  {
    bank_code: '456',
    name: 'Banco MUFG Brasil S.A.',
  },
  {
    bank_code: '464',
    name: 'Banco Sumitomo Mitsui Brasileiro S.A.',
  },
  {
    bank_code: '341',
    name: 'ITAÚ UNIBANCO S.A.',
  },
  {
    bank_code: '237',
    name: 'Banco Bradesco S.A.',
  },
  {
    bank_code: '613',
    name: 'Omni Banco S.A.',
  },
  {
    bank_code: '652',
    name: 'Itaú Unibanco Holding S.A.',
  },
  {
    bank_code: '637',
    name: 'BANCO SOFISA S.A.',
  },
  {
    bank_code: '653',
    name: 'BANCO INDUSVAL S.A.',
  },
  {
    bank_code: '069',
    name: 'Banco Crefisa S.A.',
  },
  {
    bank_code: '370',
    name: 'Banco Mizuho do Brasil S.A.',
  },
  {
    bank_code: '249',
    name: 'Banco Investcred Unibanco S.A.',
  },
  {
    bank_code: '318',
    name: 'Banco BMG S.A.',
  },
  {
    bank_code: '626',
    name: 'BANCO FICSA S.A.',
  },
  {
    bank_code: '270',
    name: 'Sagitur Corretora de Câmbio Ltda.',
  },
  {
    bank_code: '366',
    name: 'BANCO SOCIETE GENERALE BRASIL S.A.',
  },
  {
    bank_code: '113',
    name: 'Magliano S.A. Corretora de Cambio e Valores Mobiliarios',
  },
  {
    bank_code: '131',
    name: 'TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA',
  },
  {
    bank_code: '011',
    name: 'CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A',
  },
  {
    bank_code: '611',
    name: 'Banco Paulista S.A.',
  },
  {
    bank_code: '755',
    name: 'Bank of America Merrill Lynch Banco Múltiplo S.A.',
  },
  {
    bank_code: '089',
    name: 'CREDISAN COOPERATIVA DE CRÉDITO',
  },
  {
    bank_code: '643',
    name: 'Banco Pine S.A.',
  },
  {
    bank_code: '140',
    name: 'Easynvest - Título Corretora de Valores SA',
  },
  {
    bank_code: '707',
    name: 'Banco Daycoval S.A.',
  },
  {
    bank_code: '288',
    name: 'CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
  },
  {
    bank_code: '101',
    name: 'RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  {
    bank_code: '487',
    name: 'DEUTSCHE BANK S.A. - BANCO ALEMAO',
  },
  {
    bank_code: '233',
    name: 'Banco Cifra S.A.',
  },
  {
    bank_code: '177',
    name: 'Guide Investimentos S.A. Corretora de Valores',
  },
  {
    bank_code: '365',
    name: 'SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS',
  },
  {
    bank_code: '633',
    name: 'Banco Rendimento S.A.',
  },
  {
    bank_code: '218',
    name: 'Banco BS2 S.A.',
  },
  {
    bank_code: '169',
    name: 'Banco Olé Bonsucesso Consignado S.A.',
  },
  {
    bank_code: '293',
    name: 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    bank_code: '285',
    name: 'Frente Corretora de Câmbio Ltda.',
  },
  {
    bank_code: 80,
    name: 'B&T CORRETORA DE CAMBIO LTDA.',
  },
  {
    bank_code: '753',
    name: 'Novo Banco Continental S.A. - Banco Múltiplo',
  },
  {
    bank_code: '222',
    name: 'BANCO CRÉDIT AGRICOLE BRASIL S.A.',
  },
  {
    bank_code: '281',
    name: 'Cooperativa de Crédito Rural Coopavel',
  },
  {
    bank_code: '754',
    name: 'Banco Sistema S.A.',
  },
  {
    bank_code: '098',
    name: 'Credialiança Cooperativa de Crédito Rural',
  },
  {
    bank_code: '610',
    name: 'Banco VR S.A.',
  },
  {
    bank_code: '712',
    name: 'Banco Ourinvest S.A.',
  },
  {
    bank_code: '010',
    name: 'CREDICOAMO CREDITO RURAL COOPERATIVA',
  },
  {
    bank_code: '283',
    name: 'RB CAPITAL INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LIMITADA',
  },
  {
    bank_code: '033',
    name: 'BANCO SANTANDER (BRASIL) S.A.',
  },
  {
    bank_code: '217',
    name: 'Banco John Deere S.A.',
  },
  {
    bank_code: '041',
    name: 'Banco do Estado do Rio Grande do Sul S.A.',
  },
  {
    bank_code: '117',
    name: 'ADVANCED CORRETORA DE CÂMBIO LTDA',
  },
  {
    bank_code: '654',
    name: 'BANCO A.J. RENNER S.A.',
  },
  {
    bank_code: '212',
    name: 'Banco Original S.A.',
  },
  {
    bank_code: '289',
    name: 'DECYSEO CORRETORA DE CAMBIO LTDA.',
  },
  {
    bank_code: '403',
    name: 'Cora SCD S/A.',
  },
  {
    bank_code: '536',
    name: 'Banco Neon',
  },
  {
    bank_code: '383',
    name: 'Banco Juno',
  },
  {
    bank_code: '461',
    name: 'Banco Asaas',
  },
  {
    bank_code: '274',
    name: 'Banco Grafeno Via Mais Digital',
  },
  {
    bank_code: '542',
    name: 'Banco Cloudwalk Infinite Pay',
  },
  {
    bank_code: '404',
    name: 'SUMUP Sociedade de Crédito Direto S.A.',
  },
];
