import axiosXavier from '@/services/axios';

export default {
  update: member =>
    axiosXavier
      .put(`members/${member.id}`, {
        id: member.id,
        member,
      })
      .then(res => res.data)
      .catch(err => Promise.reject(err)),
};
