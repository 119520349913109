import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import breadcrumb from '@/shared/store/breadcrumb';
import filter from '@/shared/store/filter';
import home from '@/shared/store/home';
import school from '@/shared/store/school';
import sidemenu from '@/shared/store/sidemenu';
import membersArea from '@/shared/store/members-area';

import campaign from '@/sparkfunnels/store/campaign';
import email from '@/sparkfunnels/store/email';
import funnel from '@/sparkfunnels/store/funnel';
import jwt from '@/sparkfunnels/store/jwt';
import lead from '@/sparkfunnels/store/lead';
import template from '@/sparkfunnels/store/template';
import track from '@/sparkfunnels/store/track';

import course from '@/sparkmembers/store/course';
import enrollment from '@/sparkmembers/store/enrollment';
import member from '@/sparkmembers/store/member';
import product from '@/sparkmembers/store/product';
import upsell from '@/sparkmembers/store/upsell';

import sale from '@/sparkpay/store/sale';
import offerWizard from '@/sparkpay/store/offer-wizard';

import auth from '@/auth/store/auth';

import plan from '@/myspark/store/plan';

Vue.use(Vuex);

const mutationsToCheck = [
  'school/setSelectedSchool',
  'auth/setLoggedUser',
  'auth/setChannels',
  'school/setDomains',
  'school/setFeatures',
  'auth/setConsense',
];
const vuexPersist = new VuexPersist({
  key: process.env.VUE_APP_STORED_DATA,
  storage: localStorage,
  reducer: state => {
    return {
      school: {
        selectedSchool: state.school.selectedSchool,
      },
      auth: {
        loggedUser: state.auth.loggedUser,
        channels: state.auth.channels,
      },
    };
  },
  filter: mutation => mutationsToCheck.includes(mutation.type),
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    breadcrumb,
    campaign,
    course,
    enrollment,
    email,
    filter,
    funnel,
    home,
    jwt,
    lead,
    member,
    plan,
    product,
    sale,
    school,
    sidemenu,
    template,
    track,
    upsell,
    offerWizard,
    membersArea,
  },
  plugins: [vuexPersist.plugin],
});
