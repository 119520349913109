import axios from 'axios';

export default {
  async sendPhoneValidationToken(user_id: string, ddi: string, phone: string, sms_token_id: string, signup: boolean) {
    const url = `${process.env.VUE_APP_BASE_URL}signup/send_phone_validation_token`;
    const response = await axios.post(url, { user_id, ddi, phone, sms_token_id, signup });

    return response;
  },
};
