import axiosXavier from '@/services/axios';
import axios from 'axios';
import generalHelper from '@/shared/helpers/general';

export default {
  // Course
  getAll: params => axiosXavier.get(`courses?${generalHelper.parseToQueryString(params)}`),
  get: id => axiosXavier.get(`courses/${id}`),
  update: course => axiosXavier.put(`courses/${course.id}`, course),
  // CourseModules
  getCourseModule: id => axiosXavier.get(`course_modules/${id}`),
  getCourseModules: (id, payload = []) => {
    let url = `courses/${id}/course_modules?`;
    payload.forEach(p => {
      url += `&${p.key}=${p.value}`;
    });
    return axiosXavier.get(url);
  },
  createCourseModule: (id, payload) => {
    return axiosXavier.post(`courses/${id}/course_modules`, {
      course_module: payload,
    });
  },
  updateCourseModule: payload => axiosXavier.put(`course_modules/${payload.id}`, payload),
  normalizeCourseModulesOrder(ids) {
    try {
      const requests = ids.map((id, order) => this.updateCourseModule({ id, course_module: { id, order } }));
      return axios.all(requests);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteCourseModule: payload => axiosXavier.delete(`course_modules/${payload.id}`, payload),
  deleteCourse: id => axiosXavier.delete(`courses/${id}`),
  createCourseContent: (module_id, course_content) => {
    return axiosXavier
      .post(`course_modules/${module_id}/course_contents`, {
        course_content,
      })
      .then(res => res.data);
  },
  getCourseContent: content_id => axiosXavier.get(`course_contents/${content_id}?disableDrm=true`),
  getCourseContents: (module_id, payload = []) => {
    let url = `course_modules/${module_id}/course_contents?disableDrm=true`;
    payload.forEach(p => {
      url += `&${p.key}=${p.value}`;
    });
    return axiosXavier.get(url).then(res => res.data);
  },
  updateCourseContent: payload => axiosXavier.put(`course_contents/${payload.id}`, payload),
  normalizeCourseContentsOrder(ids) {
    try {
      const requests = ids.map((id, order) => this.updateCourseContent({ id, course_content: { id, order } }));
      return axios.all(requests);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteCourseContent: (content_id, payload) => {
    return axiosXavier.delete(`course_contents/${content_id}`, payload);
  },
  create(attributes) {
    return axiosXavier.post(`courses`, attributes).then(res => res.data);
  },
  hasVideoLessons: async function(courseId) {
    const { data } = await this.getCourseModules(courseId);
    const allCourseContents = await Promise.all(data.course_modules.map(module => this.getCourseContents(module.id)));
    const allContents = allCourseContents.reduce((acc, val) => [...acc, ...val.course_contents], []);
    const videoLessons = allContents.filter(
      content =>
        content.lesson.media && content.lesson.media.type === 'Video' && content.lesson.media.host === 'SparkVideos'
    );
    this.videoLessons = videoLessons;
    return !!videoLessons.length;
  },
};
