import axiosXavier from '../../services/axios';

export const ActivityService = {
  create: activity =>
    axiosXavier
      .post('activities', {
        activity,
      })
      .then(res => res.data),
  update: activity =>
    axiosXavier
      .patch(`activities/${activity.id}`, {
        activity,
      })
      .then(res => res.data),
  delete: id => axiosXavier.delete(`activities/${id}`),
  get: id => axiosXavier.get(`activities/${id}`).then(res => res.data),
  deleteQuestion: id => axiosXavier.delete(`exam_questions/${id}`),
  deleteQuestionAnswer: ({ questionId, answerId }) =>
    axiosXavier.delete(`exam_questions/${questionId}/exam_question_options/${answerId}`),
};
