import MD5 from 'crypto-js/md5';

interface Gravatar {
  email: string;
  size: number;
  fallback: string;
}

export default {
  /**
   * Returns an image url from Gravatar, if the e-mail has one.
   * More info: https://br.gravatar.com/site/implement/images/
   * @param {email: string} email E-mail to search on Gravatar
   * @param {size: number} size Size of the image to be returned from Gravatar, in pixels.
   * @param {fallback: string} fallback Fallback to be used if e-mail is not found
   */
  fromGravatar({ email, size = 200, fallback = '404' }: Gravatar): string {
    return `http://gravatar.com/avatar/${MD5(email).toString()}?s=${size}&d=${fallback}`;
  },
};
