import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    hasChanges: false,
    hasDefaultFooter: false,
    configs: {
      header: {
        banners: [],
      },
      general: {
        favicon_url: null,
        show_product_name: true,
      },
      sections: [
        {
          active: true,
          card_format: 'horizontal',
          kind: 'school_products',
          order: 1,
        },
      ],
      footer: {
        external_links: [],
        custom_text: null,
        logo_url: null,
        show_custom_text: false,
        show_external_links: false,
        show_herospark_logo: true,
      },
    },
  },
  actions,
  getters,
  mutations,
};
