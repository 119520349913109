import axiosXavier from '../../services/axios';

interface HermesAction {
  id: Number;
}

export default {
  createHermesAction: (hermes_action: HermesAction) =>
    axiosXavier.post('actions', { hermes_action }).then((res: any) => res.data),
  deleteHermesAction: (id: number) => axiosXavier.delete(`actions/${id}`),
  updateHermesAction: (hermes_action: HermesAction) =>
    axiosXavier.put(`actions/${hermes_action.id}`, {
      id: hermes_action.id,
      hermes_action,
    }),
};
