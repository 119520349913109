import httpClient from '@/services/sparkecommerce/http_client.js';

export default {
  create: data => {
    return httpClient.post(`after_purchase/flows`, data);
  },
  delete: flowId => {
    return httpClient.delete(`after_purchase/flows/${flowId}`);
  },
  update: (flowId, data) => {
    return httpClient.put(`after_purchase/flows/${flowId}`, data);
  },
};
