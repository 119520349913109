import router from '@/router';
import { AxiosError } from 'axios';
import toastHelper from '@/shared/helpers/toast';
import i18nHelper from '@/shared/helpers/i18n';

const $t = i18nHelper.$t;

let isToastAlreadyTriggered = false;

export const checkUnauthorizedResponse = (error: AxiosError<any>) => {
  if (
    error.response &&
    error.response.status === 401 &&
    !['MyAccountSettings', 'Login', 'SignUp'].includes(router.currentRoute.name) &&
    !isToastAlreadyTriggered
  ) {
    isToastAlreadyTriggered = true;
    const event = new CustomEvent('UNAUTHORIZED', { detail: error });
    document.dispatchEvent(event);
    toastHelper.infoMessage($t('authentication.toast.unauthorized'));
  }
  return Promise.reject(error);
};
