import Vue from 'vue';

export default {
  activeFilter(state, filters) {
    state.activatedFilters = filters;
  },

  selectFilter(state, { filters, index }) {
    Vue.set(state.selectedFilters, index, filters);
  },

  removeFilter(state, { indexFilters, indexFilter }) {
    const data = state.selectedFilters[indexFilters].filter((e, i) => i !== indexFilter);
    Vue.set(state.selectedFilters, indexFilters, data);
  },

  clearActivatedFilter(state, index) {
    Vue.delete(state.selectedFilters, index);
  },

  clearSelectedFilters(state) {
    state.selectedFilters = [];
  },

  clearStore(state) {
    state.activatedFilters = [];
    state.selectedFilters = [];
  },
};
