import axiosXavier from '../../services/axios';
import axios from 'axios';

export default {
  get(id) {
    return axiosXavier.get(`media/${id}?disableDrm=true`);
  },
  getAll(ids) {
    try {
      const requests = ids.map(id => this.get(id));
      return axios.all(requests);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  create(data) {
    return axiosXavier.post('media', data);
  },
  update(data) {
    return axiosXavier.put(`media/${data.id}`, data);
  },
};
