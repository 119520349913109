import axios from 'axios';
import GeneralHelper from '@/shared/helpers/general';

export default {
  getImages: params =>
    axios
      .get(
        `https://api.unsplash.com/search/photos?client_id=${
          process.env.VUE_APP_UNSPLASH_CLIENT_ID
        }&${GeneralHelper.parseToQueryString(params)}`
      )
      .then(res => res.data),

  getImage: url =>
    axios.get(url, { responseType: 'blob' }).then(res => {
      const blob = res.data;
      const file = new File([blob], Math.random(), { type: blob.type });
      return file;
    }),

  registerImageDownload: downloadRegistrationUrl =>
    axios.get(`${downloadRegistrationUrl}&client_id=${process.env.VUE_APP_UNSPLASH_CLIENT_ID}`),
};
