import axiosXavier from '@/services/axios';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';
import axios from 'axios';
import GeneralHelper from '@/shared/helpers/general';
import store from '@/store';

export default {
  getAll(params = []) {
    return axiosXavier.get(`school_products?${GeneralHelper.parseToQueryString(params)}`).then(res => res.data);
  },
  get: id => {
    return axiosXavier.get(`school_products/${id}`);
  },
  delete: id => {
    return axiosXavier.delete(`school_products/${id}`);
  },
  update: school_product => {
    return axiosXavier.put(`school_products/${school_product.id}`, {
      school_product,
    });
  },
  create(schoolId, attributes) {
    return axiosXavier.post(`/schools/${schoolId}/school_products`, {
      school_product: {
        ...attributes,
      },
    });
  },
  getProductTopic: school_product_id => {
    return axiosXavier.get(`school_products/${school_product_id}/school_product_topics`);
  },
  createProductTopic: (school_product_id, attributes) => {
    return axiosXavier.post(`school_products/${school_product_id}/school_product_topics`, {
      school_product_topic: {
        ...attributes,
        school_product_id,
      },
    });
  },
  createBatchProductTopic(topics) {
    const requests = topics.map(topic => this.createProductTopic(topic.school_product_id, topic));
    return axios.all(requests);
  },
  updateProductTopic: attributes => {
    return axiosXavier.put(`school_product_topics/${attributes.id}`, {
      school_product_topic: {
        ...attributes,
      },
    });
  },
  updateBatchProductTopic(topics) {
    const requests = topics.map(topic => this.updateProductTopic(topic));
    return axios.all(requests);
  },
  deleteProductTopic: product_topic_id => {
    return axiosXavier.delete(`school_product_topics/${product_topic_id}`);
  },
  getProductTeachers: () => {
    return axiosXavier.get('class_teachers');
  },
  deleteProductTeacher: teacher_id => {
    return axiosXavier.delete(`class_teachers/${teacher_id}`);
  },
  updateProductTeacher: teacher => {
    return axiosXavier.put(`class_teachers/${teacher.id}`, teacher);
  },
  createProductTeachers: (school_id, attributes) => {
    return axiosXavier.post('class_teachers', {
      class_teacher: {
        ...attributes,
        school_id: school_id,
      },
    });
  },
  getProductGallery: school_product_id => {
    return axiosXavier.get(`school_products/${school_product_id}/gallery_media`);
  },
  createProductGallery: attributes => {
    return axiosXavier.post('gallery_media', {
      gallery_media: {
        ...attributes,
      },
    });
  },
  deleteProductGallery: media_gallery_id => {
    return axiosXavier.delete(`gallery_media/${media_gallery_id}`);
  },
  getProductPaymentOptions: (school_product_id, params = {}) => {
    if (store.state.school.selectedSchool.features.includes('spark_checkout')) {
      return SparkEcommerceApiClient.offers.all(school_product_id, params);
    } else {
      return axiosXavier.get(`school_products/${school_product_id}/payment_options`);
    }
  },
  createProductPaymentOption: (school_product_id, payload) => {
    if (store.state.school.selectedSchool.features.includes('spark_checkout')) {
      return SparkEcommerceApiClient.offers.create(school_product_id, payload);
    } else {
      return axiosXavier.post(`school_products/${school_product_id}/payment_options`, payload);
    }
  },
  deleteProductPaymentOption: (school_product_id, payment_option_id) => {
    if (store.state.school.selectedSchool.features.includes('spark_checkout')) {
      return SparkEcommerceApiClient.offers.delete(payment_option_id);
    } else {
      return axiosXavier.delete(`school_products/${school_product_id}/payment_options/${payment_option_id}`);
    }
  },
  updateProductPaymentOption: (school_product_id, payload) => {
    if (store.state.school.selectedSchool.features.includes('spark_checkout')) {
      return SparkEcommerceApiClient.offers.update(payload);
    } else {
      return axiosXavier.put(`school_products/${school_product_id}/payment_options/${payload.id}`, payload);
    }
  },
  getLessonsProgresses: (school_product_id, params) =>
    axiosXavier
      .get(`school_products/${school_product_id}/lessons_progresses?${GeneralHelper.parseToQueryString(params)}`)
      .then(res => res.data),

  updateProductSlug: school_product => {
    return axiosXavier.put(`school_products/${school_product.id}`, {
      school_product,
    });
  },
  createProductPage: productId =>
    axiosXavier.post(`school_products/${productId}/school_product_pages`).then(({ data }) => data),

  getProductPageList: productId =>
    axiosXavier.get(`school_products/${productId}/school_product_pages`).then(({ data }) => data),

  deleteProductPage: pageId => axiosXavier.delete(`school_product_pages/${pageId}`),
  getName: id => {
    return axiosXavier.get(`school_products/${id}/name`).then(({ data }) => data);
  },

  getProductCourses: (params, pathId) =>
    axiosXavier
      .get(`school_products/${pathId}/courses?${GeneralHelper.parseToQueryString(params)}`)
      .then(({ data }) => data),

  updateRecommended: payload => axiosXavier.patch('school_products/update_recommended', payload),
};
