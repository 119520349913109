export default {
  isAuthenticated: state => !!state.token,
  name: state => (state.loggedUser ? state.loggedUser.first_name || state.loggedUser.full_name : ''),
  fullName: ({ loggedUser }) =>
    loggedUser?.full_name || `${loggedUser?.first_name || ''} ${loggedUser?.last_name || ''}`,
  hasAcceptedTerms: state => (state.loggedUser ? state.loggedUser.terms_of_use_consense : false),
  getNumberOfTheUnreadChannels: state => state.channels.filter(channel => !channel.read).length,
  getJWTToken: state => state.loggedUser.jwt,
  getRole: state => state.loggedUser.role.name,
  hasPermission: state => permission => state.loggedUser?.cerbero_permissions?.includes(permission) || false,
};
