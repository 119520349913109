import Vue from 'vue';
import { v4 } from 'uuid';

const getDefaultState = () => ({
  illustration: require('@/assets/images/SparkFunnels/Funnels/Templates/default-illustration.svg'),
  language: null,
  objectives: [],
  stages: [],
  subtitle: '',
  title: '',
  emails: {},
});

export default {
  addStage(state, stage) {
    state.stages.push({ ...stage, id: v4() });
  },
  deleteStage(state, stageId) {
    const stages = state.stages.filter(stage => stage.id !== stageId);
    Vue.set(state, 'stages', stages);
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setIllustration(state, illustration) {
    Vue.set(state, 'illustration', illustration);
  },
  resetIllustration(state) {
    Vue.set(state, 'illustration', getDefaultState().illustration);
  },
  setLanguage(state, language) {
    state.language = language;
  },
  setObjectives(state, objectives) {
    Vue.set(state, 'objectives', objectives);
  },
  setStageName(state, { stageId, name }) {
    state.stages.forEach(stage => {
      if (stage.id === stageId) stage.name = name;
    });
  },
  setStageObjective(state, { stageId, objective }) {
    state.stages.forEach(stage => {
      if (stage.id === stageId) stage.objective = objective;
    });
  },
  setStagePageThemeId(state, { stageId, pageThemeId, theme }) {
    state.stages.forEach((stage, index) => {
      if (stage.id === stageId) {
        Vue.set(state.stages[index], 'pageThemeId', pageThemeId);
        Vue.set(state.stages[index], 'theme', theme);
      }
    });
  },
  setStages(state, stages) {
    Vue.set(state, 'stages', stages);
  },
  setSubtitle(state, subtitle) {
    state.subtitle = subtitle;
  },
  setTitle(state, title) {
    state.title = title;
  },
  addEmail(state, { stageId, email }) {
    const stageEmails = state.emails[stageId];
    if (stageEmails) {
      Vue.set(state.emails, stageId, stageEmails.concat({ ...email, id: v4() }));
    } else {
      Vue.set(state.emails, stageId, [{ ...email, id: v4() }]);
    }
  },
  updateEmail(state, { stageId, email }) {
    const index = state.emails[stageId].findIndex(mail => mail.id == email.id);
    if (index >= 0) Vue.set(state.emails[stageId], index, email);
  },
  deleteEmail(state, { stageId, email }) {
    const index = state.emails[stageId].findIndex(mail => mail.id == email.id);
    if (index >= 0) Vue.delete(state.emails[stageId], index);
  },
  deleteEmails(state, stageId) {
    Vue.delete(state.emails, stageId);
  },
  moveStage(state, { oldIndex, newIndex }) {
    state.stages.splice(newIndex, 0, state.stages.splice(oldIndex, 1)[0]);
  },
  setTabIndex(state, tabIndex) {
    Vue.set(state, 'tabIndex', tabIndex);
  },
  setStageOpened(state, stageOpened) {
    Vue.set(state, 'stageOpened', stageOpened);
  },
};
