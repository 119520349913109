import getters from './getters';
import mutations from './mutations';
import defaultEmail from './defaultEmail';

export const getDefaultState = () => ({
  emailTemplate: {
    datetime: {
      sendAt: new Date(),
      days: 0,
      hours: 0,
      minutes: 0,
    },
    data: {
      mjml: defaultEmail.mjml,
      body: defaultEmail.html,
      subject: '',
    },
  },
  funnel: null,
  name: null,
  tabIndex: 0,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations,
};
