export default {
  setSelectedCourse(state, course) {
    state.selectedCourse = course;
  },
  setSelectedPath(state, path) {
    state.selectedPath = path;
  },
  setCourseContents(state, course_contents) {
    state.courseContents = course_contents;
  },
};
