import { schoolApi, customerService } from '@/services';
import sparkPayService from '@/sparkpay/services/sparkpay';

export default {
  async addDomainToSchool({ commit }, { newDomain, schoolId }) {
    return schoolApi.addDomainToSchool({ domain: newDomain, schoolId }).then(data => {
      return data;
    });
  },
  addDomain({ commit }, { newDomain, domains, schoolEmail, schoolId }) {
    return schoolApi.addDomain({ domains, newDomain, schoolEmail, schoolId }).then(data => {
      commit('setDomains', domains);
      return data;
    });
  },
  getParams({ state, commit }, { subDomain }) {
    return schoolApi.getParams(subDomain, state.selectedSchool.account_type).then(async ({ data }) => {
      let uid = state.selectedSchool.uid;
      if (!uid) uid = await customerService.findOrCreateUid({ school: { ...data.school, features: data.features } });

      commit('setSelectedSchool', { ...data.school, uid });
      commit('setFeatures', data.features);

      return data;
    });
  },
  getSchools({ commit }, { email, captcha_token }) {
    return schoolApi.getSchools(email, captcha_token).then(({ data }) => {
      commit('setSchools', data.schools);
      return data.schools;
    });
  },
  getSchoolById({ commit }, id) {
    return schoolApi
      .getSchoolById(id)
      .then(school => {
        commit('setSelectedSchool', { ...school });
        return school;
      })
      .catch(err => Promise.reject(err));
  },
  getSchoolsByName({ commit }, { name }) {
    return schoolApi
      .findSchoolsByName(name)
      .then(({ data }) => {
        commit('setSchools', data.schools);
        return data.schools;
      })
      .catch(err => Promise.reject(err));
  },
  updateSchool: (
    { commit, state },
    {
      id,
      name,
      subdomain,
      domains,
      logo,
      email,
      phone,
      cnpj,
      cpf,
      site,
      company_name,
      address,
      extra_settings,
      sms_token,
      sms_token_id,
      ddi,
      postal_code,
      street,
      neighborhood,
      city,
      uf,
      street_number,
      complementary,
    }
  ) => {
    const fields = JSON.parse(
      JSON.stringify({
        id,
        name,
        subdomain,
        domains,
        logo,
        email,
        phone,
        cnpj,
        cpf,
        site,
        company_name,
        address,
        extra_settings,
        sms_token,
        sms_token_id,
        ddi,
        postal_code,
        street,
        neighborhood,
        city,
        uf,
        street_number,
        complementary,
      })
    );
    return schoolApi.updateSchool(fields).then(() => {
      commit('setSelectedSchool', { ...state.selectedSchool, ...fields });
    });
  },
  async enableFeature({ state, commit }, { featureId, featureKey }) {
    try {
      const res = await schoolApi.enableFeature(featureId, state.selectedSchool.id);
      commit('addFeature', featureKey);
      return res;
    } catch (e) {
      throw e;
    }
  },
  async getSchoolValidation({ commit }) {
    try {
      const res = await sparkPayService.recipientValidated();
      if (res) commit('setSchoolValidation', res);
    } catch (e) {
      throw e;
    }
  },
  setPhoneValidated({ commit }) {
    const now = new Date();
    commit('setPhoneValidated', { allow_change_phone: false, phone_verified_at: now.toDateString() });
  },
};
