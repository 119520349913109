import httpClient from '@/services/sparkecommerce/http_client.js';
import httpMongoClient from '@/services/sparkecommerce/http_mongo_client.js';
import GeneralHelper from '@/shared/helpers/general';
import gatewayService from '@/services/gateway.js';
import store from '@/store';
import {
  convertFromCoreToSparkEcommercePaymentStatus,
  convertFromSparkEcommerceToCorePaymentMethod,
  convertFromCoreToSparkEcommercePaginationParams,
} from '@/services/sparkecommerce/converters.js';
import dayjs from 'dayjs';

const convertParams = params => {
  params = convertFromCoreToSparkEcommercePaginationParams(params);

  let paymentMethodFilter = params.find(obj => obj.key === 'type');
  if (paymentMethodFilter) {
    paymentMethodFilter.key = 'payment_method';
  }
  let buyerFilter = params.find(obj => obj.key === 'full_name');
  if (buyerFilter) {
    buyerFilter.key = 'buyer';
  }
  let productFilter = params.find(obj => obj.key === 'product_id');
  if (productFilter) {
    productFilter.key = 'core_product_id';
  }
  let studentId = params.find(obj => obj.key === 'student_id');
  if (studentId) {
    studentId.key = 'buyer_core_student_id';
  }
  return GeneralHelper.parseToQueryString(params);
};

const buildStrategySalesPayload = payment => {
  const { firstName, lastName } = GeneralHelper.splitName(payment.buyer.full_name);

  const coupon = payment.coupon ? { name: payment.coupon.code } : null;

  return {
    ...payment,
    kind: 'common',
    status: payment.status,
    total: payment.price_cents,
    amount_to_pay: payment.total_amount_to_be_paid_cents,
    total_fees: payment.total_fees_cents,
    affiliates_fees_cents: payment.affiliates_fees_cents || 0,
    coproductions_fees_cents: payment.coproductions_fees_cents || 0,
    net_value_cents: payment.net_value_cents,
    bank_slip_expiration_date: payment.bank_slip_expiration_date,
    error_message: payment.error_message,
    installments_text: payment.installments_text,
    public_id: payment.cart_id,
    products: [
      {
        title: payment.product.name,
      },
    ],
    coupon: coupon,
    student: {
      cpf: payment.buyer.document_id,
      email: payment.buyer.email,
      phone: payment.buyer.phone,
      ddi: payment.buyer.ddi,
      first_name: firstName,
      last_name: lastName,
    },
    payments: [
      {
        amount: payment.total_amount_to_be_paid_cents,
        installments: payment.installments,
        kind: [convertFromSparkEcommerceToCorePaymentMethod(payment.payment_method)],
        gateway_reference: payment.payment_service_identifier,
      },
    ],
  };
};

const getTotalPaidByBuyer = (purchase, coupon) => {
  return purchase.offerPrice + purchase.installmentsFees - (coupon?.coupon_base_price || 0);
};

const buildOrderBump = bump => {
  return {
    id: bump.product_id,
    name: bump.product_name,
    cover_url: bump.product_cover_url,
  };
};

const findPriorityPurchase = payment => {
  const priorityStatus = ['paid', 'refunded', 'chargeback'];

  for (const status of priorityStatus) {
    const purchase = payment.purchases.find(purchase =>
      Array.isArray(purchase.unifiedStatus)
        ? purchase.unifiedStatus.includes(status)
        : purchase.unifiedStatus === status
    );
    if (purchase) {
      return {
        ...purchase,
        status,
      };
    }
  }

  const sortedPurchases = payment.purchases.sort((a, b) => new Date(b.firstCreatedAt) - new Date(a.firstCreatedAt));
  const selectedStatus = sortedPurchases[0].unifiedStatus;
  const consolidatedStatus = Array.isArray(selectedStatus) ? selectedStatus[0] : selectedStatus;

  return {
    ...sortedPurchases[0],
    status: consolidatedStatus,
  };
};

const extractPurchaseAttempt = (purchase, status) => {
  return purchase.allAttempts.find(attempt => attempt.status === status);
};

const multicardInstallmentText = purchase => {
  const multicardAttempts = purchase.allAttempts.sort((a, b) => new Date(b.paidAt) - new Date(a.paidAt));
  const {
    installmentsFees: firstInstallmentsFees,
    totalAmount: firstTotalAmount,
    installments: firstInstallments,
  } = multicardAttempts[0];
  const {
    installmentsFees: secondInstallmentsFees,
    totalAmount: secondTotalAmount,
    installments: secondInstallments,
  } = multicardAttempts[1];

  return `${firstInstallments}x R$ ${((firstTotalAmount + firstInstallmentsFees) / firstInstallments).toFixed(
    2
  )} + ${secondInstallments}x R$ ${((secondTotalAmount + secondInstallmentsFees) / secondInstallments).toFixed(2)}`;
};

const defaultInstallmentsText = (purchase, coupon = null) => {
  const totalPaid = getTotalPaidByBuyer(purchase, coupon);
  const paidByInstallment = (totalPaid / purchase.installments).toFixed(2);
  return `${purchase.installments}x R$ ${paidByInstallment}`;
};

const getRecipientId = async () => {
  const { selectedSchool } = store.state.school;

  const getwayResponse = await gatewayService.getGateway(selectedSchool.id, [{ key: 'gateway', value: 'spark_pay' }]);

  return getwayResponse.gateway_credentials[0].split_rules[0].recipient_id;
};

const buildUnitarySalesPayload = sale => {
  const { buyer, products, participant, cart } = sale;

  const { firstName, lastName } = GeneralHelper.splitName(buyer.fullName);
  const priorityPurchase = findPriorityPurchase(sale);
  const priorityPurchaseAttempt = extractPurchaseAttempt(priorityPurchase, priorityPurchase.status);
  const totalPaidByBuyer = getTotalPaidByBuyer(priorityPurchase, products.coupon);
  const isMulticard = priorityPurchase.multi && priorityPurchase.allAttempts.length > 1;
  const installmentsText = isMulticard
    ? multicardInstallmentText(priorityPurchase)
    : defaultInstallmentsText(priorityPurchase, products.coupon);
  const purchaseCreatedAt = new Date(priorityPurchase.firstCreatedAt);
  const refundLimitDate = new Date(purchaseCreatedAt.setDate(purchaseCreatedAt.getDate() + 90));
  const dateToday = new Date();
  const refundDays = refundLimitDate - dateToday;

  return {
    saleId: priorityPurchaseAttempt.paymentId,
    paymentServiceIdentifier: priorityPurchaseAttempt.paymentServiceIdentifier,
    purchaseId: priorityPurchaseAttempt.purchaseId,
    participation: participant.kind,
    productName: products.productName,
    purchasedAt: priorityPurchase.firstCreatedAt,
    offerBasePrice: GeneralHelper.currency(priorityPurchase.offerPrice),
    heroSparkFees: GeneralHelper.currency(priorityPurchase.offerTotalFees),
    totalComissions: priorityPurchase.offerCommissionToDivide,
    totalNetValue: GeneralHelper.currency(priorityPurchase.offerCommissionToReceive),
    paymentMethod: isMulticard ? 'multicard' : priorityPurchase.paymentMethod,
    totalPaidByBuyer: GeneralHelper.currency(totalPaidByBuyer),
    installmentsPaidByBuyer: installmentsText,
    orderBumpUsed: cart.orderBumpUsed,
    orderBumpItems: products.bumps?.map(bump => buildOrderBump(bump)),
    cartId: cart.cartId,
    errorMessage: priorityPurchaseAttempt.errorMessage,
    status: priorityPurchase.status,
    isUpsell: Boolean(priorityPurchaseAttempt.paymentToken),
    enableRefund: priorityPurchase.status === 'paid' && dateToday <= refundLimitDate,
    refundLimitDays: Math.ceil(refundDays / (1000 * 3600 * 24)),
    refundLimitDate: refundLimitDate,
    refundedAt: priorityPurchaseAttempt.refundedAt,
    refundRequestAt: priorityPurchaseAttempt.refundRequestAt,
    paidAt: priorityPurchaseAttempt.paidAt,
    bankSlipExpirationDate: priorityPurchaseAttempt.bankSlipExpirationDate,
    lastUpdatedAt: priorityPurchaseAttempt.updatedAt,
    couponCode: products.coupon?.couponCode,
    buyer: {
      firstName: firstName,
      lastName: lastName,
      fullName: `${firstName} ${lastName}`,
      email: buyer.email,
      document: buyer.document,
      phone: buyer.phone,
      ddi: buyer.ddi,
    },
  };
};

const buildRecurrencySalesPayload = sale => {
  const { buyer, products, cart } = sale;

  const { firstName, lastName } = GeneralHelper.splitName(buyer.fullName);
  const priorityPurchase = findPriorityPurchase(sale);
  const purchaseCreatedAt = new Date(priorityPurchase.firstCreatedAt);
  const refundLimitDate = new Date(purchaseCreatedAt.setDate(purchaseCreatedAt.getDate() + 90));
  const dateToday = new Date();
  const priorityPurchaseAttempt = extractPurchaseAttempt(priorityPurchase, priorityPurchase.status);
  const totalPaidByBuyer = priorityPurchase.offerPrice + priorityPurchase.installmentsFees;

  return {
    participation: sale.participant.kind,
    subscriptionId: priorityPurchase.subscriptionId,
    mongoDocumentId: sale._id,
    productName: products.productName,
    purchasedAt: priorityPurchase.firstCreatedAt,
    offerBasePrice: GeneralHelper.currency(priorityPurchase.offerPrice),
    paymentMethod: priorityPurchase.paymentMethod,
    totalPaidByBuyer: GeneralHelper.currency(totalPaidByBuyer),
    installmentsPaidByBuyer: defaultInstallmentsText(priorityPurchase),
    totalNetValue: GeneralHelper.currency(priorityPurchase.offerCommissionToReceive),
    subscriptionPeriod: products.offerPeriod,
    cartId: cart.cartId,
    nextInvoiceAt: cart.nextInvoiceAt,
    orderBumpUsed: cart.orderBumpUsed,
    orderBumpItems: products.bumps?.map(bump => buildOrderBump(bump)),
    subscriptionStatus: priorityPurchase.subscriptionStatus,
    enableRefund: priorityPurchase.status === 'paid' && dateToday <= refundLimitDate,
    totalComissions: priorityPurchase.offerCommissionToDivide,
    heroSparkFees: GeneralHelper.currency(priorityPurchase.offerTotalFees),
    errorMessage: priorityPurchaseAttempt.errorMessage,
    buyer: {
      firstName: firstName,
      lastName: lastName,
      fullName: `${firstName} ${lastName}`,
      email: buyer.email,
      document: buyer.document,
      phone: buyer.phone,
      ddi: buyer.ddi,
    },
  };
};

const buildRecurrencySale = sale => {
  const { buyer, products, cart } = sale;

  const { firstName, lastName } = GeneralHelper.splitName(buyer.fullName);
  const priorityPurchase = findPriorityPurchase(sale);

  return {
    subscriptionId: priorityPurchase.subscriptionId,
    offerBasePrice: GeneralHelper.currency(priorityPurchase.offerPrice),
    purchasedAt: priorityPurchase.firstCreatedAt,
    totalNetValue: GeneralHelper.currency(priorityPurchase.offerCommissionToReceive),
    productName: products.productName,
    cartId: cart.cartId,
    subscriptionStatus: priorityPurchase.subscriptionStatus,
    paymentMethod: priorityPurchase.paymentMethod,
    cancelledBy: cart.cancelledBy,
    cancelledAt: cart.canceledAt,
    totalCollected: cart.totalCollected,
    subscriptionPeriod: products.offerPeriod,
    transactions: priorityPurchase.allAttempts.map(payment => ({
      ...payment,
      status: payment.status,
      totalAmountRefund: GeneralHelper.currency(payment.totalAmount),
    })),
    buyer: {
      fullName: `${firstName} ${lastName}`,
      email: buyer.email,
      document: buyer.document,
      phone: buyer.phone,
      ddi: buyer.ddi,
    },
  };
};
const dateFormatter = (date, isStart) => {
  const formattedDate = isStart
    ? dayjs(date)
        .startOf('date')
        .format('YYYY-MM-DDTHH:mm:ss')
    : dayjs(date)
        .endOf('date')
        .format('YYYY-MM-DDTHH:mm:ss');
  return formattedDate;
};

export default {
  async getUnitarySales(params) {
    const pageFilter = params.find(obj => obj.key === 'page');
    const page = pageFilter === undefined ? 1 : pageFilter.value;
    const perPageFilter = params.find(obj => obj.key === 'per_page');
    const perPage = perPageFilter === undefined ? 10 : perPageFilter.value;
    const statusFilter = params.find(obj => obj.key === 'status');
    const createdAtStartDateFilter = params.find(obj => obj.key === 'created_at_start');
    const createdAtEndDateFilter = params.find(obj => obj.key === 'created_at_end');

    if (statusFilter) {
      let convertedStatuses = statusFilter.value.map(s => convertFromCoreToSparkEcommercePaymentStatus(s)).flat();
      statusFilter.value = convertedStatuses;
    }

    const status = statusFilter === undefined ? null : statusFilter.value;

    const paymentMethodFilter = params.find(obj => obj.key === 'type');
    const paymentMethod = paymentMethodFilter === undefined ? null : paymentMethodFilter.value;
    const fullNameFilter = params.find(obj => obj.key === 'full_name');
    const fullName = fullNameFilter === undefined ? null : fullNameFilter.value;
    const productFilter = params.find(obj => obj.key === 'product_id');
    const productName = productFilter === undefined ? null : productFilter.value;
    const studentIdFilter = params.find(obj => obj.key === 'student_id');
    const studentId = studentIdFilter === undefined ? null : studentIdFilter.value;

    const createdAtStartDate =
      createdAtStartDateFilter === undefined ? null : dateFormatter(createdAtStartDateFilter.value, true);
    const createdAtEndDate = createdAtEndDateFilter === undefined ? null : dateFormatter(createdAtEndDateFilter.value);

    const recipientId = await getRecipientId();
    const { selectedSchool } = store.state.school;

    const payload = {
      recipientId,
      schoolId: selectedSchool.id,
      kind: 'common',
      searchForPeopleWith: fullName,
      paymentStatus: status,
      paymentMethod: paymentMethod,
      productName: productName,
      studentId: studentId,
      createdAtStartDate,
      createdAtEndDate,
      skip: (page - 1) * 10,
      limit: parseInt(perPage),
    };

    const { data } = await httpMongoClient.post(`/cart?secret=${process.env.VUE_APP_MONGO_HTTP_SECRET}`, payload);
    const [rawTransaction] = data;

    const transactions = rawTransaction.documents.map(payment => buildUnitarySalesPayload(payment));
    const arrayTotalDocuments = rawTransaction.totalCount;
    const totalDocuments = arrayTotalDocuments.length === 0 ? 0 : arrayTotalDocuments[0].count;

    return {
      orders: transactions,
      total_count: totalDocuments,
      per_page: 10,
      current_page: page,
      total_pages: Math.floor(totalDocuments / 10),
    };
  },
  async getRecurrencySaleById(id) {
    const recipientId = await getRecipientId();
    const { selectedSchool } = store.state.school;

    const page = 1;

    const payload = {
      recipientId,
      schoolId: selectedSchool.id,
      kind: 'subscription',
      skip: (page - 1) * 10,
      limit: 10,
      subscriptionId: id,
    };

    const { data } = await httpMongoClient.post(
      `/cart/subscription?secret=${process.env.VUE_APP_MONGO_HTTP_SECRET}`,
      payload
    );
    const [rawTransaction] = data;
    const transaction = rawTransaction.documents[0];

    const recurrencySale = buildRecurrencySale(transaction);

    return recurrencySale;
  },
  async getRecurrencySales(params) {
    const pageFilter = params.find(obj => obj.key === 'page');
    const page = pageFilter === undefined ? 1 : pageFilter.value;
    const statusFilter = params.find(obj => obj.key === 'status');
    const paymentMethodFilter = params.find(obj => obj.key === 'type');
    const paymentMethod = paymentMethodFilter === undefined ? null : paymentMethodFilter.value;
    const createdAtStartDateFilter = params.find(obj => obj.key === 'created_at_start');
    const createdAtEndDateFilter = params.find(obj => obj.key === 'created_at_end');

    const status = statusFilter === undefined ? null : statusFilter.value;
    const buyerFilter = params.find(obj => obj.key === 'full_name');
    const buyer = buyerFilter === undefined ? null : buyerFilter.value;
    const productFilter = params.find(obj => obj.key === 'product_id');
    const productName = productFilter === undefined ? null : productFilter.value;

    const createdAtStartDate =
      createdAtStartDateFilter === undefined ? null : dateFormatter(createdAtStartDateFilter.value, true);
    const createdAtEndDate = createdAtEndDateFilter === undefined ? null : dateFormatter(createdAtEndDateFilter.value);

    const recipientId = await getRecipientId();
    const { selectedSchool } = store.state.school;

    const payload = {
      recipientId,
      schoolId: selectedSchool.id,
      kind: 'subscription',
      searchForPeopleWith: buyer,
      paymentMethod,
      paymentStatus: status,
      productName: productName,
      createdAtStartDate,
      createdAtEndDate,
      skip: (page - 1) * 10,
      limit: 10,
    };

    const { data } = await httpMongoClient.post(
      `/cart/subscription?secret=${process.env.VUE_APP_MONGO_HTTP_SECRET}`,
      payload
    );
    const [rawTransaction] = data;

    const transactions = rawTransaction.documents.map(payment => buildRecurrencySalesPayload(payment));
    const arrayTotalDocuments = rawTransaction.totalCount;
    const totalDocuments = arrayTotalDocuments.length === 0 ? 0 : arrayTotalDocuments[0].count;

    return {
      orders: transactions,
      total_count: totalDocuments,
      per_page: 10,
      current_page: page,
      total_pages: Math.floor(totalDocuments / 10),
    };
  },
  getStrategySales(params) {
    const subscriptionTypeParams = params.concat([
      { key: 'subscription_type', value: ['smart_installment', 'installment'] },
    ]);

    const convertedParams = convertParams(subscriptionTypeParams);

    return httpClient.get(`subscriptions?${convertedParams}`).then(({ data, headers }) => {
      const payments = data.map(payment => buildStrategySalesPayload(payment));

      return {
        orders: payments,
        total_count: headers['total-count'],
        per_page: headers['page-items'],
        current_page: headers['current-page'],
        total_pages: headers['total-pages'],
      };
    });
  },
  dashboard(group_by) {
    return httpClient
      .get('/payments/dashboard', { params: { group_by } })
      .then(res => res.data.dashboard)
      .catch(() => []);
  },
  getById(id) {
    const url = `subscriptions/${id}`;

    return httpClient.get(url).then(({ data }) => {
      const { subscription } = data;
      const [first_name, last_name] = subscription.buyer.full_name.split(' ');
      const payments = data.payments.map(payment => ({
        ...payment,
        status: payment.status,
        amount: payment.price_cents,
        enabledReprocess: payment.enabled_reprocess,
      }));

      return {
        ...subscription,
        id: subscription.id,
        cart_id: subscription.cart_id,
        amount_total_subscriptions: subscription.total_collected,
        total: subscription.amount,
        student: {
          full_name: subscription.buyer.full_name,
          first_name,
          last_name,
          email: subscription.buyer.email,
          cpf: subscription.buyer.document_id,
        },
        products: [
          {
            title: subscription.product.name,
          },
        ],
        created_at: subscription.created_at,
        kind: 'subscription',
        status: subscription.status,
        coupon: null,
        payments,
        payment_method: subscription.payment_method,
        total_installments: subscription.total_installments,
        total_net_value_cents:
          subscription.net_value_cents * subscription.total_installments - subscription.coproductions_fees_cents,
      };
    });
  },
  cancel(id) {
    const url = `subscriptions/${id}/cancel?by_producer=true`;

    return httpClient.post(url);
  },
  refund(paymentId) {
    const url = `payments/${paymentId}/refund`;

    return httpClient.post(url);
  },
  reprocess(paymentId) {
    const url = `payments/${paymentId}/reprocess`;

    return httpClient.post(url);
  },
  reactivatePayment(paymentId) {
    const url = `payments/${paymentId}/reactivate_payment`;

    return httpClient.post(url);
  },
  payment_simulation(params) {
    return httpClient.get('payments/payment_simulation', { params }).then(({ data }) => data);
  },
  getPublicCart(id) {
    return httpClient.get(`/carts/${id}`).then(({ data }) => data);
  },
  findPriorityPurchase,
};
