const paymentMethodMap = {
  bank_slip: 'boleto',
  credit_card: 'credit_card',
  pix: 'pix',
  multicard: 'multicard',
};

const convertFromSparkEcommerceToCorePaymentMethod = payment_method => {
  return paymentMethodMap[payment_method];
};

const paymentStatusMap = {
  chargeback: 'chargeback',
  initiated: 'initiated',
  error: 'canceled',
  paid: 'confirmed',
  refunded: 'refunded',
  refund_analyzing: 'refund_analyzing',
  refused: 'canceled',
  waiting_payment: 'waiting_payment',
  cancelled: 'canceled',
  overdue: 'overdue',
};

const paymentSubscriptionStatusMap = {
  ...paymentStatusMap,
  paid: 'active',
  error: 'error',
  canceled: 'subscription_canceled',
};

const convertFromCoreToSparkEcommercePaymentStatus = (payment_status, isSubscription = false) => {
  const statusMap = isSubscription ? paymentSubscriptionStatusMap : paymentStatusMap;
  return Object.entries(statusMap)
    .filter(([_key, value]) => value === payment_status)
    .map(([key, _value]) => key);
};

const convertFromCoreToSparkEcommercePaginationParams = paginationParams => {
  let items = paginationParams.find(obj => obj.key === 'per_page');
  if (items) {
    items.key = 'items';
  }

  return paginationParams;
};

export {
  convertFromSparkEcommerceToCorePaymentMethod,
  convertFromCoreToSparkEcommercePaymentStatus,
  convertFromCoreToSparkEcommercePaginationParams,
};
