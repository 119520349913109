export default {
  hasChanges: state => {
    if (state.configs.has_updates) {
      return true;
    }

    return state.hasChanges;
  },
  activeMembersAreaConfig: state => {
    return state.configs.setup;
  },
  getGeneralConfig: state => {
    return state.configs.general;
  },
  getSectionsConfig: state => {
    return state.configs.sections;
  },
  getFooterConfig: state => {
    return state.configs.footer;
  },
  getMyProductsSectionConfig: state => {
    return state.configs.sections.find(section => section.kind === 'school_products');
  },
};
