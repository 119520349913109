import loadProductGuard from './guards/loadProductGuard';
import loadProductOfferGuard from './guards/loadProductOfferGuard';
import store from '@/store';

export default {
  path: 'sparkmembers',
  name: 'SparkMembers',
  redirect: { name: 'MembersList' },
  meta: { feature: 'spark_members', noFeatureAccessRoute: 'SparkMembersLandingPage' },
  component: () => import(/* webpackChunkName: "sparkmembers" */ '@/views/EmptyRouterView.vue'),
  children: [
    {
      path: 'members',
      name: 'Members',
      redirect: { name: 'MembersList' },
      component: () => import(/* webpackChunkName: "members" */ '@/views/EmptyRouterView.vue'),
      children: [
        {
          path: '',
          name: 'MembersList',
          component: () => import(/* webpackChunkName: "members" */ '@/sparkmembers/views/MembersList'),
          meta: { title: `Lista de membros – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: ':id',
          name: 'MemberDetail',
          redirect: { name: 'MemberDetails' },
          meta: { breadcrumb: 'MemberDetail' },
          component: () => import(/* webpackChunkName: "members" */ '@/views/EmptyRouterView.vue'),
          children: [
            {
              path: '',
              name: 'MemberDetails',
              component: () => import(/* webpackChunkName: "members" */ '@/sparkmembers/views/MemberDetail'),
              meta: { title: `Ver Membro – ${process.env.VUE_APP_APPLICATION_TITLE}`, breadcrumb: null },
            },
            {
              path: 'enrollment/:enrollmentId',
              name: 'AccessHistory',
              component: () => import(/* webpackChunkName: "members" */ '@/sparkmembers/views/AccessHistory'),
              meta: { title: `Ver Histórico de acesso – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
          ],
        },
      ],
    },
    {
      path: 'products',
      name: 'Products',
      redirect: { name: 'ProductsList' },
      component: () => import(/* webpackChunkName: "products" */ '@/views/EmptyRouterView.vue'),
      beforeEnter: (to, from, next) => {
        if (
          store.getters['auth/hasPermission']('core_product_can_read') ||
          store.getters['auth/hasPermission']('core_product_can_view') ||
          store.getters['auth/hasPermission']('core_product_can_manage_sale')
        )
          next();
        else next({ name: 'MembersList' });
      },
      children: [
        {
          path: '',
          name: 'ProductsList',
          component: () => import(/* webpackChunkName: "products" */ '@/sparkmembers/views/ProductList'),
          meta: { title: `Meus Produtos – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'create',
          name: 'ProductCreator',
          component: () => import(/* webpackChunkName: "products" */ '@/sparkmembers/views/ProductCreator'),
          meta: { title: `Criar Produto – ${process.env.VUE_APP_APPLICATION_TITLE}` },
          props: true,
        },
        {
          path: ':id',
          name: 'ProductDashboard',
          redirect: { name: 'DashboardList' },
          meta: { breadcrumb: 'ProductDashboard' },
          component: () => import(/* webpackChunkName: "products" */ '@/views/EmptyRouterView.vue'),
          beforeEnter: loadProductGuard,
          children: [
            {
              path: '',
              name: 'DashboardList',
              component: () => import(/* webpackChunkName: "products" */ '@/sparkmembers/views/ProductDashboard'),
              meta: { title: `Dashboard – ${process.env.VUE_APP_APPLICATION_TITLE}`, breadcrumb: null },
            },
            {
              path: 'sales-page',
              name: 'ProductSalesPage',
              component: () => import(/* webpackChunkName: "products" */ '@/sparkmembers/views/ProductSalesPage'),
              meta: { title: `Página de Venda – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'sales-setup',
              name: 'ProductSetup',
              component: () => import(/* webpackChunkName: "products" */ '@/sparkmembers/views/ProductSetup'),
              meta: { title: `Página de Venda – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'offers',
              component: () => import(/* webpackChunkName: "offers" */ '@/views/EmptyRouterView.vue'),
              children: [
                {
                  path: '',
                  name: 'ProductOffers',
                  component: () => import(/* webpackChunkName: "offers" */ '@/sparkmembers/views/Offers'),
                  meta: { title: `Ofertas – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                },
                {
                  path: 'create',
                  name: 'ProductCreateOffers',
                  component: () => import(/* webpackChunkName: "offers" */ '@/sparkmembers/views/Offers/Create'),
                  meta: { title: `Criar Oferta – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                },
                {
                  path: ':offerId',
                  name: 'ProductEditOffers',
                  beforeEnter: loadProductOfferGuard,
                  component: () => import(/* webpackChunkName: "offers" */ '@/sparkmembers/views/Offers/Edit'),
                  meta: { title: `Editar Oferta – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                },
              ],
            },
            {
              path: 'access-rules',
              name: 'ProductAccessRules',
              component: () => import(/* webpackChunkName: "rules" */ '@/sparkmembers/views/ProductAccessRules'),
              meta: { title: `Regras de Acesso – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'coupons',
              name: 'Coupons',
              redirect: { name: 'CouponsList' },
              component: () => import(/* webpackChunkName: "coupons" */ '@/sparkmembers/views/Coupons'),
              children: [
                {
                  path: '',
                  name: 'CouponsList',
                  component: () => import(/* webpackChunkName: "coupons" */ '@/sparkmembers/views/Coupons/views/List'),
                  meta: { title: `Cupons – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                },
              ],
            },
            {
              path: 'comments',
              name: 'Comments',
              component: () => import(/* webpackChunkName: "Forum" */ '@/sparkmembers/views/Comments'),
              meta: { title: `Comentários – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'contents',
              name: 'Contents',
              redirect: { name: 'ContentsList' },
              component: () => import(/* webpackChunkName: "contents" */ '@/sparkmembers/views/Contents'),
              children: [
                {
                  path: '',
                  name: 'ContentsList',
                  component: () =>
                    import(/* webpackChunkName: "contents" */ '@/sparkmembers/views/Contents/views/List'),
                  meta: { title: `Conteúdo – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                },
                {
                  path: 'path',
                  name: 'ContentPaths',
                  component: () =>
                    import(/* webpackChunkName: "contents" */ '@/sparkmembers/views/Contents/views/Paths'),
                  meta: { title: `Editar Conteúdo – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                },
                {
                  path: 'live_meets/:courseId',
                  name: 'ContentLives',
                  props: true,
                  component: () =>
                    import(/* webpackChunkName: "livemeet" */ '@/sparkmembers/views/Contents/views/Lives'),
                  meta: { title: `Editar Conteúdo – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                  children: [
                    {
                      path: 'create',
                      name: 'CreateLive',
                      component: () =>
                        import(/* webpackChunkName: "livemeet" */ '@/sparkmembers/views/Contents/views/Create'),
                      meta: { title: `Criar Encontro Ao vivo – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                    },
                    {
                      path: ':liveMeetId',
                      name: 'EditLive',
                      props: true,
                      component: () =>
                        import(/* webpackChunkName: "livemeet" */ '@/sparkmembers/views/Contents/views/Create'),
                      meta: { title: `Editar Encontro ao vivo – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                    },
                  ],
                },
                {
                  path: ':contentId',
                  name: 'ContentEditor',
                  component: () =>
                    import(/* webpackChunkName: "contents" */ '@/sparkmembers/views/Contents/views/Editor'),
                  meta: { title: `Editar Conteúdo – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                },
              ],
            },
            {
              path: 'certificates',
              name: 'Certificates',
              redirect: { name: 'CertificatesConfiguration' },
              meta: { breadcrumb: 'Certificates' },
              component: () => import(/* webpackChunkName: "certificates" */ '@/sparkmembers/views/Certificates'),
              children: [
                {
                  path: '',
                  name: 'CertificatesConfiguration',
                  component: () =>
                    import(/* webpackChunkName: "certificates" */ '@/sparkmembers/views/Certificates/views/Configuration'),
                  meta: { title: `Certificados – ${process.env.VUE_APP_APPLICATION_TITLE}`, breadcrumb: null },
                },
                {
                  path: 'basicEditor',
                  name: 'CertificatesBasicEditor',
                  component: () =>
                    import(/* webpackChunkName: "certificates" */ '@/sparkmembers/views/Certificates/views/BasicCertificateEditor'),
                  meta: {
                    title: `Certificados – ${process.env.VUE_APP_APPLICATION_TITLE}`,
                    breadcrumb: 'CertificatesBasicEditor',
                  },
                },
                {
                  path: 'advancedEditor',
                  name: 'CertificatesAdvancedEditor',
                  component: () =>
                    import(/* webpackChunkName: "certificates" */ '@/sparkmembers/views/Certificates/views/AdvancedCertificateEditor'),
                  meta: {
                    title: `Certificados – ${process.env.VUE_APP_APPLICATION_TITLE}`,
                    breadcrumb: 'CertificatesAdvancedEditor',
                  },
                },
              ],
            },
            {
              path: 'reports',
              name: 'Reports',
              component: () => import(/* webpackChunkName: "reports" */ '@/sparkmembers/views/Reports'),
              meta: { title: `Relatórios – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'ebooksConfig',
              name: 'EbooksConfig',
              component: () => import(/* webpackChunkName: "ebooks" */ '@/sparkmembers/views/EbooksConfig'),
              meta: { title: `Proteção para Ebooks – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
          ],
        },
      ],
    },
  ],
};
