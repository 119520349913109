export default {
  setProduct(state, product) {
    state.selectedProduct = { ...product, pages: product?.pages || [] };
  },
  setProductPaymentOptions(state, paymentOptions) {
    state.productPaymentOptions = paymentOptions;
  },
  setProductPaymentOption(state, paymentOption) {
    state.productPaymentOption = paymentOption;
  },
  setProductPages(state, pages) {
    state.selectedProduct.pages = pages;
  },
  addProductPage(state, page) {
    state.selectedProduct.pages.push(page);
  },
  updateProductPage(state, page) {
    const index = state.selectedProduct.pages?.findIndex(({ page_id }) => page_id === page.page_id);

    if (![-1, undefined, null].includes(index)) {
      const pages = [...state.selectedProduct.pages];
      pages[index] = page;

      state.selectedProduct.pages = pages;
    }
  },
  removeProductPage(state, id) {
    const index = state.selectedProduct.pages?.findIndex(page => page.id === id);
    if (![-1, undefined, null].includes(index)) state.selectedProduct.pages.splice(index, 1);
  },
};
