import axios from 'axios';
import TrackingHelper from '@/shared/helpers/tracking';
import hubspotService from '@/services/hubspot';

export const New_Questionary_Form = Object.freeze({
  ID: '734319',
  TOKEN:
    process.env.NODE_ENV === 'production'
      ? '0ca26c41-7ec8-4fb6-a44e-4fbd15358270'
      : '0ca26c41-7ec8-4fb6-a44e-4fbd15358270',
});

export const Questionary_Form = Object.freeze({
  ID: '734319',
  TOKEN:
    process.env.NODE_ENV === 'production'
      ? '7bab1596-3d1e-4af5-95a3-bb765dbcac0d'
      : 'e250029d-10fe-4e46-9a51-c62fae097f1a',
});

export const Signup_Form = Object.freeze({
  ID: '734319',
  TOKEN:
    process.env.NODE_ENV === 'production'
      ? '2a7949d0-4d92-42f3-9cca-bf89cebb9082'
      : 'f1d63dde-f956-4eee-b3c8-a6a2e477d777',
});

export default {
  sendNewQuestionnaire(answer, id, name, email, referral = 'not-set') {
    let ref = referral;
    if (!['free', 'growth', 'consultor-growth', 'consultor-personalizado'].includes(ref)) ref = 'not-set';
    const data = {
      fields: [
        {
          name: 'email',
          value: email,
        },
        {
          name: 'school_id',
          value: id,
        },
        {
          name: 'school_name',
          value: name,
        },
        {
          name: 'customerkind',
          value: answer.customerKind || '',
        },
        {
          name: 'teamsize',
          value: answer.teamSize || '',
        },
        {
          name: 'saleExperience',
          value: answer.saleExperience.join(';') || '',
        },
        {
          name: 'platformUsed',
          value: answer.platformUsed.join(';') || '',
        },
        {
          name: 'instagramProfile',
          value: answer.instagramProfile || '',
        },
        {
          name: 'youtubeProfile',
          value: answer.youtubeProfile || '',
        },
        {
          name: 'othersProfile',
          value: answer.othersProfile || '',
        },
        {
          name: 'plg_plan_referral',
          value: ref || 'not-set',
        },
      ],
    };
    return axios.post(
      `${process.env.VUE_APP_HUBSPOT_BASE_URL}/submissions/v3/integration/submit/${New_Questionary_Form.ID}/${
        New_Questionary_Form.TOKEN
      }`,
      data
    );
  },
  sendQuestionnaire(answer, id, name, email, referral = 'not-set') {
    let ref = referral;
    if (!['free', 'growth', 'consultor-growth', 'consultor-personalizado'].includes(ref)) ref = 'not-set';
    const data = {
      fields: [
        {
          name: 'email',
          value: email,
        },
        {
          name: 'school_id',
          value: id,
        },
        {
          name: 'school_name',
          value: name,
        },
        {
          name: 'plg_team_size',
          value: answer.teamSize,
        },
        {
          name: 'sellcourse',
          value: answer.sellCourse,
        },
        {
          name: 'status',
          value: answer.status,
        },
        {
          name: 'profile',
          value: answer.profile,
        },
        {
          name: 'producttype',
          value: answer.productType.join(';'),
        },
        {
          name: 'sellstrategy',
          value: answer.sellStrategy.join(';'),
        },
        {
          name: 'plg_plan_referral',
          value: ref || 'not-set',
        },
      ],
    };
    return axios.post(
      `${process.env.VUE_APP_HUBSPOT_BASE_URL}/submissions/v3/integration/submit/${Questionary_Form.ID}/${
        Questionary_Form.TOKEN
      }`,
      data
    );
  },
  sendSignUp(form) {
    const firstName = form.name.split(' ')[0];
    const data = {
      fields: [
        {
          name: 'email',
          value: form.email,
        },
        {
          name: 'firstname',
          value: firstName,
        },
        {
          name: 'lastname',
          value: form.name.replace(`${firstName} `, ''),
        },
        {
          name: 'phone',
          value: form.phone,
        },
        {
          name: 'utm_campaign',
          value: form.utm.utm_campaign || '',
        },
        {
          name: 'utm_medium',
          value: form.utm.utm_medium || '',
        },
        {
          name: 'utm_content',
          value: form.utm.utm_content || '',
        },
        {
          name: 'utm_source',
          value: form.utm.utm_source || '',
        },
        {
          name: 'utm_term',
          value: form.utm.utm_term || '',
        },
        {
          name: 'utm_name',
          value: form.utm.utm_name || '',
        },
        {
          name: 'campanha_signup',
          value: form.utm.utm_campaign || 'Não definido',
        },
        {
          name: 'source_signup',
          value: form.utm.utm_source || 'Não definido',
        },
        {
          name: 'medium_signup',
          value: form.utm.utm_medium || 'Não definido',
        },
        {
          name: 'content_signup',
          value: form.utm.utm_content || 'Não definido',
        },
      ],
    };
    return axios.post(
      `${process.env.VUE_APP_HUBSPOT_BASE_URL}/submissions/v3/integration/submit/${Signup_Form.ID}/${
        Signup_Form.TOKEN
      }`,
      data
    );
  },
  mountedHubScript(loggedUser) {
    TrackingHelper.trackCommunityPageViewed();
    const scriptJquery = document.createElement('script');
    scriptJquery.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    document.body.appendChild(scriptJquery);
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    hubspotService.startHubForm(script, loggedUser);
    return [script, scriptJquery];
  },
  startHubForm(script, loggedUser) {
    script.addEventListener('load', () => {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '734319',
        formId: 'e5a91535-6f75-4fa0-a42a-14a77fc58e13',
        target: '#hubspotForm',
        onFormReady: function($form) {
          const firstName = loggedUser.first_name;
          const lastName = loggedUser.last_name;
          const email = loggedUser.email;
          $form
            .find('input[name="firstname"]')
            .val(firstName)
            .change();
          $form
            .find('input[name="lastname"]')
            .val(lastName)
            .change();
          $form
            .find('input[name="email"]')
            .val(email)
            .change();
        },
        onFormSubmit: function() {
          TrackingHelper.trackCommunitySendForm();
        },
      });
    });
  },
  mountEmbededHubScript() {
    const scriptJquery = document.createElement('script');
    scriptJquery.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    document.body.appendChild(scriptJquery);
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);
    hubspotService.startEmbededHubForm(script);
    return [script, scriptJquery, false];
  },
  startEmbededHubForm(script) {
    script.addEventListener('load', () => {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '734319',
        formId: 'e0225827-2356-456c-8e93-ab85f72824cc',
        target: '#hubspotPromotionalForm',
      });
    });
  },
};
