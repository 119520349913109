import axiosXavier from './axios';
import axios from 'axios';
import xml2js from 'xml2js';

export default {
  getAwsKeys: (contentType, schoolId) =>
    axiosXavier.get(`/s3/presignedpost?content_type=${contentType}&id=${schoolId}&type=School`),

  getAwsKeysByBucket: ({ contentType, bucket, key }) =>
    axiosXavier.get(`/funnels/presigned_post`, { params: { content_type: contentType, bucket, dir: key } }),

  upload(bucketUrl, keys, file, onUploadProgress) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append('key', keys.key),
        formData.append('AWSAccessKeyId', keys.AWSAccessKeyId),
        formData.append('policy', keys.policy),
        formData.append('signature', keys.signature),
        formData.append('success_action_status', keys.success_action_status),
        formData.append('acl', keys.acl),
        formData.append('Content-Type', file.type),
        formData.append('file', file);

      axios
        .post(bucketUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress,
        })
        .then(res => {
          xml2js.parseString(res.data, (error, jsonData) => {
            resolve(jsonData.PostResponse.Location[0]);
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  listObjects: ({ url, prefix }) => {
    return new Promise(resolve => {
      axios
        .get(url, {
          params: { 'list-type': 2, prefix },
        })
        .then(({ data }) => {
          xml2js.parseString(data, (err, { ListBucketResult }) => {
            let images = [];
            if (ListBucketResult && ListBucketResult.Contents) {
              images = ListBucketResult.Contents.map(content => url + content.Key[0]);
            }
            resolve(images);
          });
        });
    });
  },
};
