import GeneralHelper from '@/shared/helpers/general';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

export default {
  getDefaultDomain: state => {
    if (state.selectedSchool && state.selectedSchool.subdomain) {
      return GeneralHelper.accountDomain({
        accountType: state.selectedSchool.account_type,
        subDomain: state.selectedSchool.subdomain,
      });
    }
    return '';
  },
  getDomain: (state, getters) => {
    if (state.selectedSchool?.domains?.length) {
      return state.selectedSchool.domains[0];
    }
    return getters.getDefaultDomain;
  },
  getDomainWithHttp: (state, getters) => {
    const domain = getters.getDomain;
    return domain.indexOf('http') !== 0 ? `https://${domain}` : domain;
  },
  hasFeature: state => feature => state.selectedSchool.features.includes(feature),
  isSchoolValidated: state => state.selectedSchool.validated_info?.valid,
  forceValidation: () => true,
  isNewSchool: state => dayjs(state.selectedSchool.created_at).isSameOrAfter(dayjs('2024-01-10')),
};
