import merge from 'lodash.merge';
import Vue from 'vue';
import VueI18n, { IVueI18n } from 'vue-i18n';

Vue.use(VueI18n);

let i18nInstance: VueI18n;
const availableLocales = ['pt-BR', 'en', 'en-US'];
const defaultLocale = 'pt-BR';

const getFallbackLocale = (locale: string) => {
  if (/pt-/.test(locale)) return 'pt-BR';
  if (/en-/.test(locale)) return 'en';
  if (/en/.test(locale)) return 'en';

  return 'pt-BR';
};

const getTranslator = async (locale: string) => {
  if (!locale || !availableLocales.includes(locale)) {
    return (await import(`@/locales/${defaultLocale}.json`)).default;
  }

  const fbLocale = getFallbackLocale(locale);
  const fbLocaleJson = (await import(`@/locales/${fbLocale}.json`)).default;

  if (locale === fbLocale) {
    return fbLocaleJson;
  }

  try {
    return merge((await import(`@/locales/${locale}.json`)).default, fbLocaleJson);
  } catch (error) {
    return fbLocaleJson;
  }
};

const init = async (rawLocale: string) => {
  const locale: string = rawLocale || defaultLocale;

  const translator = await getTranslator(locale);

  const i18nJson = JSON.stringify(translator);
  const content = i18nJson.replace(/___?(.+?)___?/g, (e, match) => {
    return `{${match}}`;
  });

  const messages: any = {};

  const config = {
    locale: locale,
    messages: messages,
    silentTranslationWarn: true,
  };
  config.messages[locale] = JSON.parse(content);

  i18nInstance = new VueI18n(config);
  return i18nInstance;
};

const $t = (key: string) => {
  if (!key) {
    return new Error(`i18n - No found the key: ${key}`);
  }
  return i18nInstance.t(key);
};

export default {
  init,
  $t,
};
