import store from '@/store';

export default {
  path: 'sparkpay',
  name: 'SparkPay',
  redirect: { name: 'Sales' },
  meta: { feature: 'spark_members' },
  component: () => import(/* webpackChunkName: "sparkpay" */ '@/views/EmptyRouterView.vue'),
  children: [
    {
      path: 'releases_report',
      name: 'ReleasesReport',
      component: () =>
        import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/Reports/ReleasesReport/index.vue'),
      meta: { title: `Painel de lançamento – ${process.env.VUE_APP_APPLICATION_TITLE}` },
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/hasPermission']('core_sales_can_manage')) next();
        else next({ name: 'ProductsList' });
      },
    },
    {
      path: 'sales_made_report',
      name: 'SalesMadeReport',
      component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/Reports/SalesMadeReport'),
      meta: { title: `Transações realizadas – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
    {
      path: 'invoice_report',
      name: 'InvoiceReport',
      component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/Reports/InvoiceReport'),
      meta: { title: `Emissão de Nota Fiscal – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
    {
      path: 'order_bump_sales_made_report',
      name: 'OrderBumpSalesMadeReport',
      component: () =>
        import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/Reports/OrderBumpSalesMadeReport'),
      meta: { title: `Order Bump – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
    {
      path: 'subscription_sales_made_report',
      name: 'SubscriptionSalesMadeReport',
      component: () =>
        import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/Reports/SubscriptionsSalesMadeReport'),
      meta: { title: `Assinaturas – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
    {
      path: 'abandoned_sales_report',
      name: 'AbandonedSalesReport',
      component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/Reports/AbandonedSalesReport'),
      meta: { title: `Transações em aberto – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
    {
      path: 'refused_sales_report',
      name: 'RefusedSalesReport',
      component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/Reports/RefusedSalesReport'),
      meta: { title: `Vendas recusadas – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
    {
      path: 'refunded_sales_report',
      name: 'RefundedSalesReport',
      component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/Reports/RefundedSalesReport'),
      meta: { title: `Estorno e Chargeback – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
    {
      path: 'abandoned_cart_report',
      name: 'AbandonedCartReport',
      component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/Reports/AbandonedCartReport'),
      meta: { title: `Abandono de carrinho – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
    {
      path: 'affiliates_coproducer_report',
      name: 'AffiliatesCoproducerReport',
      component: () =>
        import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/Reports/AffiliatesCoproducerReport'),
      meta: { title: `Afiliados e coprodutores – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
    {
      path: 'sales_strategy_report',
      name: 'SalesStrategyReport',
      component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/Reports/SalesStrategyReport'),
      meta: { title: `Parcelamento inteligente – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
    {
      path: 'sales',
      name: 'Sales',
      redirect: { name: 'UnitsList' },
      component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales'),
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/hasPermission']('core_sales_can_manage')) next();
        else next({ name: 'ProductsList' });
      },
      children: [
        {
          path: 'units',
          name: 'UnitsList',
          component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/UnitsList'),
          meta: { title: `Vendas – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'subscriptions',
          name: 'SubscriptionsList',
          component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/SubscriptionsList'),
          meta: { title: `Assinaturas – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'sales-strategy',
          name: 'SalesStrategyList',
          component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/SalesStrategyList'),
          meta: { title: `Parcelamento Inteligente – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'sales_report',
          name: 'SalesReport',
          component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/Reports/SalesReport'),
          meta: { title: `Relatórios de venda – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: ':id',
          name: 'SalesDetails',
          component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/SalesDetails'),
          meta: { title: `Detalhes da Venda – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: ':id/sale-installment',
          name: 'SalesInstallmentDetails',
          component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Sales/SalesInstallmentDetails'),
          meta: { title: `Detalhes da Venda – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
      ],
    },
    {
      path: 'wallet',
      name: 'Wallet',
      redirect: { name: 'WalletBalance' },
      component: () => import(/* webpackChunkName: "wallet" */ '@/sparkpay/views/Wallet'),
      beforeEnter: (to, from, next) => {
        if (
          store.getters['auth/hasPermission']('core_wallet_can_manage') ||
          store.getters['auth/hasPermission']('sparkpay_wallet_can_view')
        )
          next();
        else next({ name: 'ProductsList' });
      },
      children: [
        {
          path: 'balance',
          name: 'WalletBalance',
          component: () => import(/* webpackChunkName: "wallet" */ '@/sparkpay/views/Wallet/tabs/WalletBalance.vue'),
          meta: { title: `Carteira – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'withdrawals',
          name: 'WalletWithdrawals',
          component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Wallet/tabs/WalletWithdrawals.vue'),
          meta: { title: `Saques – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'bankaccount',
          name: 'WalletBankAccount',
          component: () => import(/* webpackChunkName: "sales" */ '@/sparkpay/views/Wallet/tabs/WalletBankAccount.vue'),
          meta: { title: `Conta Bancária – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
      ],
    },
  ],
};
