import TokenHelper from '@/shared/helpers/token';
import axios from 'axios';
import { checkUnauthorizedResponse } from '@/libs/authentication';

const axiosXavier = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

axiosXavier.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json';
    const token = TokenHelper.getToken();

    if (token) {
      config.headers['Authorization'] = token;
    }

    return config;
  },
  error => Promise.reject(error)
);

axiosXavier.interceptors.response.use(response => response, checkUnauthorizedResponse);

export default axiosXavier;
