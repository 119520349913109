import axiosXavier from '../../services/axios';
import GeneralHelper from '@/shared/helpers/general';

export default {
  get(params) {
    return axiosXavier.get(`enrollments?${GeneralHelper.parseToQueryString(params)}`).then(res => res.data);
  },

  async getEnrollmentData(enrollmentId) {
    const { data } = await axiosXavier.get(`enrollments/${enrollmentId}`);
    return data;
  },

  updateEnrollment(enrollmentId, payload) {
    return axiosXavier.put(`enrollments/${enrollmentId}`, payload);
  },

  massDeactivateEnrollments(enrollmentsId) {
    return axiosXavier.post('enrollments/mass_deactivate', { enrollments: enrollmentsId }).then(res => res.data);
  },

  async getEnrollmentLessonProgresses(enrollmentId, params = []) {
    const { data } = await axiosXavier.get(
      `enrollments/${enrollmentId}/lessons_progresses?${GeneralHelper.parseToQueryString(params)}`
    );
    return data;
  },

  async getEnrollmentActivities(enrollmentId, params = []) {
    const { data } = await axiosXavier.get(
      `enrollments/${enrollmentId}/activities?${GeneralHelper.parseToQueryString(params)}`
    );
    return data;
  },

  async getEnrollmentActivityData(enrollmentId, activityId, params = []) {
    const { data } = await axiosXavier.get(
      `enrollments/${enrollmentId}/activities/${activityId}?${GeneralHelper.parseToQueryString(params)}`
    );
    return data;
  },
};
