const Stages = Object.freeze({
  PAGE: 'Funnel::PageStage',
  WEBINAR: 'Funnel::WebinarStage',
  CHECKOUT: 'Funnel::CheckoutStage',
});

export default {
  enabledTypes: () => Stages,
  stageById: state => stageId => {
    const stage = state.stages.find(stage => stage.id === stageId);
    return stage ? { ...stage } : null;
  },
  emailsByStage: state => stageId => {
    return state.emails[stageId];
  },
  payloadMounted: state => {
    const payload = {
      title: state.title,
      subtitle: state.subtitle,
      language: state.language,
      media: [{ image_type: 'illustration', url: state.illustration }],
    };

    const funnelObjectivesAttributes = state.objectives.map(objective => ({ title: objective }));
    payload['funnelObjectivesAttributes'] = funnelObjectivesAttributes;

    const funnelStagesAttributes = state.stages.map((stage, index) => ({
      name: stage.name,
      objective: stage.objective,
      stage_type: stage.stageType,
      order: index,
      funnel_emails_attributes: state.emails[stage.id],
      page_theme_id: stage.pageThemeId,
    }));

    payload['funnelStagesAttributes'] = funnelStagesAttributes;

    return payload;
  },
  openedStage: state => state.stages[state.stageOpened],
  emailsFromOpenedStage: (state, getters) => {
    const stage = getters.openedStage;
    if (stage) return state.emails[stage.id];

    return [];
  },
  pagesPreview: (_, getters) => {
    if (getters.openedStage && getters.openedStage.theme) {
      if (getters.openedStage.theme.previews && Object.keys(getters.openedStage.theme.previews).length > 0) {
        return (
          getters.openedStage.theme.previews.desktop ||
          getters.openedStage.theme.previews.tablet ||
          getters.openedStage.theme.previews.mobile ||
          ''
        );
      }
      return getters.openedStage.theme.preview || '';
    }
    return '';
  },
};
