import axios from 'axios';

const isPagePublished = async ({ jwt, pageId }) => {
  try {
    const res = await axios.post(
      `${process.env.VUE_APP_PAGES_API_URL}/graphql`,
      {
        query: `
            query Page($id: ID!){
                page(id: $id) {
                    published
                }
            }
    `,
        variables: {
          id: pageId,
        },
      },
      {
        headers: {
          Authorization: jwt,
        },
      }
    );
    return res.data.data.page.published;
  } catch (e) {
    return undefined;
  }
};

const getThemeData = async ({ jwt, themeId }) => {
  try {
    const res = await axios.post(
      `${process.env.VUE_APP_PAGES_API_URL}/graphql`,
      {
        query: `
            query Theme($id: ID!){
                theme(id: $id) {
                  thumbnail
                  preview
                  previews
                }
            }
    `,
        variables: {
          id: themeId,
        },
      },
      {
        headers: {
          Authorization: jwt,
        },
      }
    );
    return res.data.data.theme;
  } catch (e) {
    return false;
  }
};

const publishPages = async ({ jwt, pages }) => {
  try {
    const res = await axios.post(
      `${process.env.VUE_APP_PAGES_API_URL}/graphql`,
      {
        query: `
          mutation PublishPages($input: [PublishPagesIDs!]) {
            publishPages(input: $input)
          }
      `,
        variables: {
          input: pages.map(page => ({ page })),
        },
      },
      {
        headers: {
          Authorization: jwt,
        },
      }
    );
    return res.data.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

const getPages = async ({ jwt, pageList }) => {
  try {
    const res = await axios.post(
      `${process.env.VUE_APP_PAGES_API_URL}/graphql`,
      {
        query: `
      fragment pageDataFrag on PageContent {
        name
        title
        published
      }
    
      {
        ${pageList.map((id, index) => `page${index}: page(id: "${id}") { ...pageDataFrag }`).join('\n')}
      }
      `,
      },
      {
        headers: {
          Authorization: jwt,
        },
      }
    );
    return Object.values(res.data.data);
  } catch (e) {
    return null;
  }
};

const updatePage = async ({ jwt, page }) => {
  try {
    const res = await axios.post(
      `${process.env.VUE_APP_PAGES_API_URL}/graphql`,
      {
        query: `mutation SavePageMutation($input: SavePageInput!) {
          savePage(input: $input) {
            name
            title
            published
          }
        }`,
        variables: {
          input: {
            id: page.page_id,
            name: page.name,
            title: page.title,
            published: page.published,
          },
        },
      },
      {
        headers: {
          Authorization: jwt,
        },
      }
    );
    return res.data.data;
  } catch (e) {
    return null;
  }
};

const multipleRequestsOfIsPagePublished = array => {
  try {
    return axios.all(array.map(a => isPagePublished({ jwt: a.jwt, pageId: a.pageId })));
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  isPagePublished,
  multipleRequestsOfIsPagePublished,
  getThemeData,
  publishPages,
  getPages,
  updatePage,
};
