import GeneralHelper from '@/shared/helpers/general';
import axios from 'axios';
import axiosXavier from './axios';

export default {
  addDomainToSchool({ schoolId, domain }) {
    return axiosXavier.post(`schools/${schoolId}/domains`, {
      domain: {
        domain,
      },
    });
  },
  listDomains(schoolId) {
    return axiosXavier.get(`schools/${schoolId}/domains`);
  },
  deleteDomain({ schoolId, domain }) {
    return axiosXavier.delete(`schools/${schoolId}/domains/${domain}`);
  },
  addDomain({ domains, newDomain, schoolEmail, schoolId }) {
    return axiosXavier.put(`schools/${schoolId}`, {
      id: schoolId,
      school: {
        id: schoolId,
        email: schoolEmail,
        domains,
        newDomain,
      },
    });
  },
  getParams: (subDomain, accountType) => {
    const url = new URL(
      `${location.protocol}//${GeneralHelper.accountDomain({ accountType, subDomain })}/themes/params`
    );
    const configProtocol = process.env.VUE_APP_CORE_SCHOOL_API_PROTOCOL || '';
    if (configProtocol.length) url.protocol = configProtocol;
    return axios.get(url.href);
  },
  getSchools(email, captcha_token) {
    return axiosXavier.get('schools', {
      params: {
        email,
        type: 'Collaborator',
        status: ['trial', 'active', 'blocked'],
        per_page: 50,
        'g-recaptcha-response': captcha_token,
      },
      headers: {
        Authorization: process.env.VUE_APP_TOKEN,
      },
    });
  },
  getSchoolById: id =>
    axiosXavier
      .get(`schools/${id}`, {
        headers: {
          Authorization: process.env.VUE_APP_TOKEN,
        },
      })
      .then(res => res.data)
      .catch(error => Promise.reject(error)),
  findSchoolsByName: name =>
    axiosXavier.get(`schools?name=${name}`, {
      headers: {
        Authorization: process.env.VUE_APP_TOKEN,
      },
    }),
  updateSchool({
    id,
    name,
    subdomain,
    domains,
    logo,
    email,
    phone,
    cnpj,
    cpf,
    site,
    company_name,
    address,
    extra_settings,
    sms_token,
    sms_token_id,
    ddi,
    postal_code,
    street,
    neighborhood,
    city,
    uf,
    street_number,
    complementary,
  }) {
    return axiosXavier.patch(`schools/${id}`, {
      school: {
        name,
        subdomain,
        domains,
        logo,
        email,
        phone,
        cnpj,
        cpf,
        site,
        company_name,
        address,
        extra_settings,
        sms_token,
        sms_token_id,
        ddi,
        postal_code,
        street,
        neighborhood,
        city,
        state: uf,
        street_number,
        complementary,
      },
    });
  },
  async enableFeature(featureId, schoolId) {
    const res = await axiosXavier.get(`schools/${schoolId}/school_features?per_page=9999`);
    const schoolFeature = res.data.schoolfeatures.find(sf => sf.feature_id === featureId);
    if (schoolFeature) {
      return axiosXavier.put(`school_features/${schoolFeature.id}/activate`, {
        id: schoolFeature.id,
      });
    }
    return axiosXavier.post('school_features', {
      school_feature: {
        feature_id: featureId,
        active: true,
        available: true,
        school_id: schoolId,
      },
    });
  },
  getMetaBaseUrl: (id, bi_id, bi_type = 'question') =>
    axiosXavier.get(`schools/${id}/sales_report_url`, {
      params: { bi_type, bi_id },
      headers: {
        Authorization: process.env.VUE_APP_TOKEN,
      },
    }),
};
