import store from '@/store';

export default function(to, from, next) {
  const id = parseInt(to.params.offerId);

  if (!store.state.product.productPaymentOption || store.state.product.productPaymentOption.id !== id) {
    const { payment_options } = store.state.product.selectedProduct;
    const payload = payment_options
      .map(option => ({
        ...option,
        price: option.price / 100,
        base_price: option.base_price / 100,
      }))
      .filter(option => option.id === id)[0];
    store.dispatch('product/getProductPaymentOption', payload).then(
      res => {
        if (res) next();
      },
      () => {
        next({ name: 'ProductOffers' });
      }
    );
  } else {
    next();
  }
}
