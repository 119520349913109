import membersAreaService from '@/services/members-area';
import uploadService from '@/services/upload';

export default {
  async setInitialConfig({ commit }, schoolId) {
    try {
      const setupResponse = await membersAreaService.getMembersAreaConfigs(schoolId);
      commit('SET_CONFIGS', setupResponse.data.members_config);
      if (!setupResponse.data.members_config.sections) commit('SET_SECTIONS_INITIAL_STATE');
      if (!setupResponse.data.members_config.general) commit('SET_GENERAL_INITIAL_STATE');
      if (!setupResponse.data.members_config.footer) commit('SET_FOOTER_INITIAL_STATE');
      if (setupResponse.data.members_config?.header?.banners) {
        const banners = setupResponse.data.members_config.header.banners.map(banner => {
          const id = banner._id['$oid'];
          delete banner._id;
          return {
            ...banner,
            id,
          };
        });
        commit('SET_BANNER_CONFIG', banners);
      } else {
        commit('SET_BANNER_INITIAL_STATE');
      }
    } catch (error) {
      throw error;
    }
  },

  async uploadFavicon({ commit }, { file, schoolId }) {
    try {
      if (!file) {
        commit('UPDATE_GENERAL_CONFIG', { key: 'favicon_url', value: null });
        const res = await membersAreaService.updateMembersAreaConfigs(schoolId, 'setup', {
          members_config: {
            general: {
              favicon_url: null,
            },
          },
        });

        commit('SET_UPDATED_AT', { date: res.data.updated_at });

        return;
      }

      const { data } = await uploadService.getAwsKeys(file.type, schoolId);
      const imageUrl = await uploadService.upload(data.url, data.fields, file);

      commit('UPDATE_GENERAL_CONFIG', { key: 'favicon_url', value: imageUrl });

      const res = await membersAreaService.updateMembersAreaConfigs(schoolId, {
        members_config: {
          general: {
            favicon_url: imageUrl,
          },
        },
      });

      commit('SET_UPDATED_AT', { data: res.data.members_config.updated_at });

      return res.data;
    } catch (error) {
      throw error;
    }
  },

  async saveConfigs({ commit }, schoolId) {
    try {
      await membersAreaService.publishMembersAreaConfigs(schoolId);

      commit('RESET_HAS_UPDATES');
    } catch (error) {
      throw error;
    }
  },

  async updateSectionConfig({ commit, state }, { key, value, schoolId, kind }) {
    try {
      if (state.hasDefaultSections) {
        // First update the local state
        commit('UPDATE_SECTION_CONFIG', { key, value, kind });

        // Then immediately save to the API
        await membersAreaService.updateMembersAreaConfigs(schoolId, {
          members_config: {
            sections: state.configs.sections,
          },
        });

        return;
      }
      const section = state.configs.sections.find(section => section.kind === kind);
      section[key] = value;
      commit('UPDATE_SECTION_CONFIG', { key, value, kind });

      // Then immediately save to the API
      await membersAreaService.updateMembersAreaConfigs(schoolId, {
        members_config: {
          sections: [section],
        },
      });
    } catch (error) {
      throw error;
    }
  },

  async updateGeneralConfig({ commit, state }, { key, value, schoolId }) {
    try {
      // First update the local state
      commit('UPDATE_GENERAL_CONFIG', { key, value });

      // Then immediately save to the API
      await membersAreaService.updateMembersAreaConfigs(schoolId, {
        members_config: {
          general: state.configs.general,
        },
      });
    } catch (error) {
      throw error;
    }
  },

  async updateFooterConfig({ commit, state }, { data, schoolId }) {
    try {
      commit('UPDATE_FOOTER_CONFIG', { data });

      await membersAreaService.updateMembersAreaConfigs(schoolId, {
        members_config: {
          footer: { ...data },
        },
      });
    } catch (error) {
      throw error;
    }
  },

  async removeFooterConfig({ commit, state }, { schoolId }) {
    const resetFooterConfig = {
      _id: state.configs.footer._id,
      custom_text: '',
      deleted: true,
      external_links: [],
      logo_url: null,
      show_custom_text: false,
      show_external_links: false,
      show_herospark_logo: state.configs.footer.show_herospark_logo,
    };

    try {
      commit('UPDATE_FOOTER_CONFIG', { data: resetFooterConfig });

      await membersAreaService.updateMembersAreaConfigs(schoolId, {
        members_config: {
          footer: resetFooterConfig,
        },
      });
    } catch (error) {
      throw error;
    }
  },

  async updateBannerConfig({ commit, state }, { data, schoolId }) {
    try {
      // Save to the API
      const res = await membersAreaService.updateMembersAreaConfigs(schoolId, {
        members_config: {
          header: {
            banners: [...data],
          },
        },
      });

      const updatedBanners = res.data.members_config.header.banners.map(banner => {
        const formattedBanner = { ...banner };
        formattedBanner.id = banner._id['$oid'];
        delete formattedBanner._id;
        return formattedBanner;
      });

      commit('UPDATE_BANNER_CONFIG', { data: updatedBanners });
    } catch (error) {
      throw error;
    }
  },

  async deleteBannerConfig({ commit, state }, { id, schoolId }) {
    try {
      // Filter out the deleted banner and update orders
      const updatedBanners = state.configs.header.banners
        .filter(banner => banner.id !== id)
        .map((banner, index) => ({
          ...banner,
          order: index,
        }));

      // Update local state first
      commit('UPDATE_BANNER_CONFIG', { data: updatedBanners });

      // Then save to the API
      await membersAreaService.updateMembersAreaConfigs(schoolId, {
        members_config: {
          header: {
            banners: updatedBanners,
          },
        },
      });
    } catch (error) {
      throw error;
    }
  },
};
