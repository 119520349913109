import httpClient from '@/sparkaffiliates/services/http_client.js';

export default {
  acceptInvite(invite_code: string, platform_name: string, school_id: number, email: string, name: string) {
    const invite = {
      invite_code,
      email,
      name,
      platform_name,
      school_id,
    };
    return httpClient.post(`invite_api/v1/invite/accept`, { invite });
  },
};
