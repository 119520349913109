import { HomeService } from '@/services';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';
import i18n from '@/shared/helpers/i18n';

export default {
  async requestHomeInfo({ commit }) {
    const groupBy = localStorage.getItem('home-chart-groupby') || 'day';

    const homeData = await HomeService.getHomeInfo().catch(() => {});
    const dashboard = await SparkEcommerceApiClient.payments.dashboard(groupBy);

    const { active_leads_count = 0, active_students_count = 0, team_size = '' } = homeData || {};

    const parsedData = {
      active_leads_count,
      active_students_count,
      team_size,
      dashboard,
    };

    return commit('setHomeInfo', parsedData);
  },
  async requestHomeDashboard({ commit }, groupBy) {
    const dashboard = await SparkEcommerceApiClient.payments.dashboard(groupBy);

    return commit('setDashboard', dashboard);
  },
  setCanSeeMoney({ commit }, canSeeMoney) {
    localStorage.setItem('canSeeMoney', canSeeMoney);
    return commit('setCanSeeMoney', canSeeMoney);
  },
};
