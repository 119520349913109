import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { DEFAULT_CHECKOUT_SETTINGS } from './data';

export default {
  namespaced: true,
  state: {
    checkoutSettings: DEFAULT_CHECKOUT_SETTINGS,
    isTimerEnabled: false,
    isWhatsappEnabled: false,
    isTopBannerEnabled: false,
    isSideBannerEnabled: false,
    isEditingOffer: false,
  },
  actions,
  getters,
  mutations,
};
