export default {
  activeFilter({ commit }, payload) {
    commit('activeFilter', payload);
  },

  selectFilter({ commit }, payload) {
    commit('selectFilter', payload);
  },

  removeFilter({ commit }, payload) {
    commit('removeFilter', payload);
  },

  clearActivatedFilter({ commit }, index) {
    commit('clearActivatedFilter', index);
  },

  clearSelectedFilters({ commit }) {
    commit('clearSelectedFilters');
  },

  clearStore({ commit }) {
    commit('clearStore');
  },
};
