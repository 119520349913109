import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';

const upsellAPI = SparkEcommerceApiClient.upsell;

export default {
  setSelectedOption({ commit }, selectedOption) {
    commit('setSelectedOption', selectedOption);
  },
  setSelectorStep({ commit }, selectorStep) {
    commit('setSelectorStep', selectorStep);
  },
  setIsEditingUpsell({ commit }, isEditing) {
    commit('setIsEditingUpsell', isEditing);
  },
  async createUpsellFlow({ commit, rootState }, flow) {
    try {
      const { data } = await upsellAPI.create(flow);

      const offer = rootState.product.productPaymentOption;

      commit(
        'product/setProductPaymentOption',
        {
          ...offer,
          upsell_flow_steps: data,
        },
        { root: true }
      );
    } catch (error) {
      throw error;
    }
  },
  async udpdateUpsellFlow({ commit, rootState }, { flowId, flow }) {
    const { data: updatedFlow } = await upsellAPI.update(flowId, flow);

    const offer = rootState.product.productPaymentOption;

    commit(
      'product/setProductPaymentOption',
      {
        ...offer,
        upsell_flow_steps: updatedFlow,
      },
      { root: true }
    );
  },
};
