import store from '@/store';

export default {
  addLoadedStage(state, stage) {
    if (!store.getters['track/hasLoadedStage'](stage.id)) state.loadedStages.push(stage);
  },
  clearLoadedStages(state) {
    state.loadedStages = [];
  },
  addEmailTemplate(state, { stageId, emailTemplate }) {
    if (!store.getters['track/hasLoadedEmailTemplateStored'](stageId)) {
      state.loadedEmailTemplates.push({ stageId, emailTemplate });
    }
  },
  clearLoadedEmailTemplates(state) {
    state.loadedEmailTemplates = [];
  },
};
