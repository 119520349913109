import { parse } from '@/shared/helpers/dates';
import funnelApi from '@/sparkfunnels/services/funnel';

export default {
  createFunnel(context, { name, schoolId, stages, template_name, template_type }) {
    return funnelApi.create({ name, schoolId, stages_attributes: stages, template_name, template_type });
  },
  updateFunnel(context, funnel) {
    return funnelApi.update(funnel);
  },
  getFunnels({ commit }, { currentPage, perPage }) {
    return funnelApi.getFunnels({ currentPage, perPage }).then(({ data }) => {
      commit('setFunnels', data.funnels);
      return data;
    });
  },
  getFunnelsLimit() {
    return funnelApi.getFunnelsLimit().then(({ data }) => data);
  },
  getFunnel(context, funnelId) {
    return funnelApi.getFunnel(funnelId);
  },
  loadFunnel({ commit }, funnelId) {
    return funnelApi.getFunnel(funnelId).then(({ data }) => {
      commit('setLoadedFunnel', data);
      return data;
    });
  },
  updateLoadedFunnel({ commit }, funnel) {
    return funnelApi.update(funnel).then(() => {
      commit('setLoadedFunnel', funnel);
      return funnel;
    });
  },
  getStage(context, { funnelId, stageId }) {
    return funnelApi.getStage(funnelId, stageId);
  },
  getFunnelTemplates({ commit }, { language, published, perPage }) {
    return funnelApi.getFunnelTemplates({ language, published, perPage }).then(({ data }) => {
      commit('setTemplates', data.funneltemplates);

      return data;
    });
  },
  updateStageCheckout({ commit }, checkoutData) {
    const { stageId, url, type } = checkoutData;
    return funnelApi.updateStageCheckout(checkoutData).then(() => {
      commit('setLoadedFunnelStageCheckout', { stageId, url, type });
    });
  },
  updateStageSlug({ commit }, { funnelId, stageId, newSlug }) {
    return funnelApi.updateStageSlug({ funnelId, stageId, newSlug }).then(() => {
      commit('setLoadedFunnelStageSlug', { stageId, newSlug });
    });
  },
  getMedia({ commit }, { mediaId }) {
    return funnelApi.getMedia(mediaId).then(({ data }) => {
      commit('setLoadedMedia', { ...data, start_at: data.start_at ? parse(data.start_at) : null });
      return data;
    });
  },
  updateMedia({ commit }, { mediaId, media }) {
    return funnelApi.updateMedia(mediaId, media).then(({ data }) => {
      commit('setLoadedMedia', { ...media });
      return data;
    });
  },
  updateEmailTemplate: ({ commit }, { funnelId, emailTemplate, stageId }) => {
    return funnelApi.updateEmailTemplate({ funnelId, stageId, emailTemplate }).then(() => {
      commit('updateEmailTemplate', { emailTemplate });
    });
  },
  loadEmailTemplates: ({ commit, state, getters }) => {
    return funnelApi.getEmailTemplates(state.loadedFunnel.id, state.selectedStage.id, 1, 50).then(({ data }) => {
      commit('setEmailTemplates', {
        emailTemplates: data.email_templates.map(email => ({
          ...email,
          send_at: email.send_at ? parse(email.send_at) : null,
        })),
      });
      if (state.selectedStage) {
        commit(
          'track/addEmailTemplate',
          {
            stageId: state.selectedStage.id,
            emailTemplate: getters.getEmailTemplatesEditStatus,
          },
          { root: true }
        );
      }
    });
  },
  updateStatus: ({ commit }, { funnelId, newStatus }) => {
    const operation = newStatus === 'active' ? 'activate' : 'deactivate';
    return funnelApi.updateStatus({ funnelId, operation }).then(() => {
      commit('setFunnelStatus', { funnelId, newStatus });
    });
  },
  updateLoadedFunnelStatus: ({ commit }, { funnel, newStatus }) => {
    const operation = newStatus === 'active' ? 'activate' : 'deactivate';
    return funnelApi.updateStatus({ funnelId: funnel.id, operation }).then(res => {
      commit('setLoadedFunnelStatus', { newStatus });
      return res;
    });
  },
  deleteEmailTemplate: ({ commit }, { funnelId, stageId, emailTemplateId }) => {
    return funnelApi.deleteEmailTemplate({ funnelId, stageId, emailTemplateId }).then(() => {
      commit('deleteEmailTemplate', { emailTemplateId });
    });
  },
  createEmailTemplate: ({ commit }, { funnelId, stageId, emailTemplate }) => {
    return funnelApi.createEmailTemplate({ funnelId, stageId, emailTemplate }).then(({ data }) => {
      commit('addEmailTemplate', { emailTemplate: data });
    });
  },
  objectivesSelected: ({ commit }, selected) => {
    commit('setObjectivesSelected', selected);
  },
  cleanObjectivesSelected: ({ commit }) => {
    commit('cleanObjectivesSelected');
  },
  stagesEmailsContent: ({ commit }, stage) => {
    commit('updateStagesEmailsContent', stage);
  },
  cleanStagesEmailsContent: ({ commit }) => {
    commit('cleanStagesEmailsContent');
  },
  funnelObjectives({ commit }) {
    funnelApi.funnelObjectives().then(({ data }) => {
      commit('setFunnelObjectives', data.objectives);
    });
  },
  strategyTemplates({ commit }, strategyId) {
    funnelApi
      .getStrategyTemplates(strategyId)
      .then(({ data }) => {
        const { funnel_templates } = data;
        commit('setStrategyTemplates', { funnel_templates, strategyId });
      })
      .catch(__err => commit('setStrategyTemplates', []));
  },
};
