import httpClient from '@/services/sparkecommerce/http_client.js';
import { convertFromCoreToSparkEcommercePaginationParams } from '@/services/sparkecommerce/converters.js';
import GeneralHelper from '@/shared/helpers/general';

export default {
  all: params => {
    let convertedParams = convertFromCoreToSparkEcommercePaginationParams(params);

    const index = convertedParams.findIndex(param => param.key === 'product_ids');
    const removedParam = convertedParams.splice(index, 1);
    let school_product_id = removedParam[0].value;

    return httpClient
      .get(`products/${school_product_id}/coupons?${GeneralHelper.parseToQueryString(convertedParams)}`)
      .then(({ data, headers }) => {
        let coupons = data.map(coupon => ({
          ...coupon,
          name: coupon.code,
          value: coupon.percentage_value || coupon.fixed_amount_cents,
          percentual: !!coupon.percentage_value,
          product_ids: school_product_id,
          used_quantity: coupon.consumed,
        }));
        return {
          coupons: coupons,
          total_count: headers['total-count'],
          per_page: headers['page-items'],
          current_page: headers['current-page'],
          total_pages: headers['total-pages'],
        };
      });
  },
  allGlobal: params => {
    let convertedParams = convertFromCoreToSparkEcommercePaginationParams(params);

    return httpClient
      .get(`coupons/global?${GeneralHelper.parseToQueryString(convertedParams)}`)
      .then(({ data, headers }) => {
        return {
          coupons: data,
          total_count: headers['total-count'],
          per_page: headers['page-items'],
          current_page: headers['current-page'],
          total_pages: headers['total-pages'],
        };
      });
  },
  create: coupon => {
    const payload = {
      coupon: {
        code: coupon.name,
        percentage_value: coupon.percentual ? coupon.value : null,
        fixed_amount_cents: coupon.percentual ? null : coupon.value * 100,
        available_until: coupon.available_until,
        quantity: coupon.quantity,
      },
    };

    const school_product_id = coupon.product_ids[0];

    return httpClient.post(`products/${school_product_id}/coupons`, payload);
  },
  createGlobalCoupon: coupon => {
    const payload = {
      coupon: {
        code: coupon.code,
        percentage_value: coupon.discount,
        available_until: coupon.validUntil,
        quantity: coupon.unlimited ? 999999 : coupon.quantity,
      },
    };

    return httpClient.post(`coupons/create_global`, payload);
  },
  delete: couponId => {
    return httpClient.delete(`coupons/${couponId}`);
  },
};
