import httpClient from '@/services/sparkecommerce/http_client.js';

const translatePaymentOptionToOffer = payment_option => {
  const acceptedPaymentMethods = payment_option.payment_method;

  let priceFormat;
  if (acceptedPaymentMethods.length === 1 && acceptedPaymentMethods.includes('bank_slip')) {
    priceFormat = 'full';
  } else {
    priceFormat = 'installments';
  }

  const kind = payment_option.kind ? payment_option.kind : 'common';

  return {
    offer: {
      allow_overdue_access: payment_option.allow_overdue_access,
      accepted_payment_methods: acceptedPaymentMethods,
      price_cents: payment_option.price,
      anchor_price_cents: payment_option.base_price > payment_option.price ? payment_option.base_price : 0,
      default: payment_option.default,
      price_format: priceFormat,
      max_installments: payment_option.installments_amount_limit,
      title: payment_option.title || null,
      facebook_pixel: payment_option.facebook_pixel,
      facebook_api_token: payment_option.facebook_api_token,
      ga_tracking_id: payment_option.ga_tracking_id,
      google_ads_tracking_id: payment_option.google_ads_tracking_id,
      google_ads_tracking_label: payment_option.google_ads_tracking_label,
      frequency_type: payment_option.frequency_type || 'unlimited',
      frequency_limit: payment_option.frequency_limit || null,
      kind,
      period: kind === 'common' ? 'unitary' : payment_option.period || 'monthly',
      overdue_type: payment_option.overdue_type || 'none',
      overdue_limit: payment_option.overdue_limit || null,
      banner_url: payment_option.banner_url || null,
      thank_you_urls: payment_option.thank_you_urls || [],
      smart_installment: payment_option.smart_installment,
      bank_slip_installment: payment_option.bank_slip_installment,
      pix_installment: payment_option.pix_installment,
      bank_slip_max_installments: payment_option.bank_slip_max_installments,
      pix_max_installments: payment_option.pix_max_installments,
      checkout_settings: payment_option.checkout_settings,
    },
  };
};

const translateOfferToPaymentOption = (offer, school_product_id) => {
  const kind = offer.kind || 'common';

  return {
    id: offer.id,
    url: offer.url,
    slug: offer.slug,
    title: offer.title,
    default: offer.default,
    price: offer.price_cents,
    price_format: offer.price_format,
    ga_tracking_id: offer.ga_tracking_id,
    base_price: offer.anchor_price_cents,
    facebook_pixel: offer.facebook_pixel,
    facebook_api_token: offer.facebook_api_token,
    school_product_id: school_product_id,
    installments_amount_limit: offer.max_installments,
    payment_method: offer.accepted_payment_methods,
    google_ads_tracking_id: offer.google_ads_tracking_id,
    google_ads_tracking_label: offer.google_ads_tracking_label,
    kind,
    frequency_type: offer.frequency_type || 'unlimited',
    frequency_limit: offer.frequency_limit || null,
    period: kind === 'common' ? 'unitary' : offer.period || 'monthly',
    overdue_type: offer.overdue_type || 'none',
    overdue_limit: offer.overdue_limit || null,
    banner_url: offer.banner_url || null,
    thank_you_urls: offer.thank_you_urls,
    upsell_flow_steps: offer.upsell_flow_steps,
    promotional: null,
    reference_id: offer.reference_id || offer.id,
    smart_installment: offer.smart_installment,
    bank_slip_installment: offer.bank_slip_installment,
    pix_installment: offer.pix_installment,
    bank_slip_max_installments: offer.bank_slip_max_installments,
    pix_max_installments: offer.pix_max_installments,
    checkout_settings: offer.checkout_settings,
    allow_overdue_access: offer.allow_overdue_access,
  };
};

export default {
  translateOfferToPaymentOption,
  all: (school_product_id, params = {}) => {
    return httpClient.get(`products/${school_product_id}/offers`, { params }).then(({ data, headers }) => {
      let payment_options = data.map(offer => translateOfferToPaymentOption(offer, school_product_id));
      return {
        data: {
          payment_options: payment_options,
        },
        headers,
      };
    });
  },
  getById(id, school_product_id, params = {}) {
    return httpClient
      .get(`offers/${id}`, { params })
      .then(({ data }) => translateOfferToPaymentOption(data, school_product_id));
  },
  create: (school_product_id, payload) => {
    const translatedPayload = translatePaymentOptionToOffer(payload);
    return httpClient.post(`products/${school_product_id}/offers`, translatedPayload);
  },
  update: payload => {
    const translatedPayload = translatePaymentOptionToOffer(payload);
    return httpClient.put(`offers/${payload.id}`, translatedPayload);
  },
  delete: offerId => {
    return httpClient.delete(`offers/${offerId}`);
  },
};
