import store from '@/store';

export default {
  customer: uid => {
    window.tap('customer', uid || store.state.school.selectedSchool.uid, {
      meta_data: {
        domain: store.state.school.selectedSchool.subdomain,
        email: store.state.school.selectedSchool.email,
        id: store.state.school.selectedSchool.id,
        name: store.state.school.selectedSchool.name,
      },
    });
  },
  detect: () => window.tap('detect'),
  tapFiliateCookieIsNotPresent() {
    return document.cookie.indexOf('tap_vid') < 0;
  },
  tapFiliateCookieIsPresent() {
    return !tapFiliateCookieIsNotPresent();
  },
};
