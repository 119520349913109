import { funnelApi, pagesService } from '@/services';

export default {
  createTemplate(
    { commit },
    { title, subtitle, language, media, funnelObjectivesAttributes, funnelTagsAttributes, funnelStagesAttributes }
  ) {
    return funnelApi
      .createFunnelTemplate({
        title,
        subtitle,
        language,
        media,
        funnelObjectivesAttributes,
        funnelTagsAttributes,
        funnelStagesAttributes,
      })
      .then(({ data }) => {
        commit('resetState');

        return data;
      });
  },
  deleteStage({ commit }, stageId) {
    commit('deleteStage', stageId);
    commit('deleteEmails', stageId);
  },
  setStagePageThemeId({ rootState, commit }, { stageId, pageThemeId }) {
    pagesService
      .getThemeData({
        jwt: rootState.jwt.encoded,
        themeId: pageThemeId,
      })
      .then(res => commit('setStagePageThemeId', { stageId, pageThemeId, theme: res }))
      .catch(() => commit('setStagePageThemeId', { stageId, pageThemeId, theme: null }));
  },
};
