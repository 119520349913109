export default {
  setSelectedOption(state, selectedOption) {
    state.selectedOption = selectedOption;
  },
  setSelectorStep(state, selectorStep) {
    state.selectorStep = selectorStep;
  },
  setIsEditingUpsell(state, isEditing) {
    state.isEditingUpsell = isEditing;
  },
};
