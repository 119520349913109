import dayjs from 'dayjs';
import enUS from 'dayjs/locale/en';
import ptBR from 'dayjs/locale/pt-br';
import es from 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';

const locales = {
  en: enUS,
  es: es,
  'pt-BR': ptBR,
};
dayjs.locale(locales[navigator.language]);
dayjs.extend(localizedFormat);
dayjs.extend(utc);

export function parse(dateString) {
  return dayjs(dateString).toDate();
}

export function format(date, formatString) {
  return dayjs(date).format(formatString);
}

/**
 * @deprecated please consider using dayjs functions instead.
 */
export default {
  isFutureDate: date => dayjs(date).diff(dayjs()) > 0,
  difference: date => dayjs().diff(dayjs(date)),
  format: ({ date, format }) => dayjs(date).format(format),
  utc: date => dayjs(date).utc(),
};
