export default {
  setOfferCheckoutSettings(state, checkoutSettings) {
    state.checkoutSettings = checkoutSettings;
  },
  setTimerSettings(state, timerSettings) {
    state.checkoutSettings.timer_settings = timerSettings;
  },
  setIsTimerEnabled(state, isTimerEnabled) {
    state.isTimerEnabled = isTimerEnabled;
  },
  setWhatsappSettings(state, whatsappSettings) {
    state.checkoutSettings.whatsapp_settings = whatsappSettings;
  },
  setIsWhatsappEnabled(state, isWhatsappEnabled) {
    state.isWhatsappEnabled = isWhatsappEnabled;
  },
  setEmailConfirmation(state, emailConfirmation) {
    state.checkoutSettings.email_confirmation = emailConfirmation;
  },
  setRequirePhone(state, requirePhone) {
    state.checkoutSettings.require_phone = requirePhone;
  },
  setRequireAddress(state, requireAddress) {
    state.checkoutSettings.require_address = requireAddress;
  },
  setIsTopBannerEnabled(state, isTopBannerEnabled) {
    state.isTopBannerEnabled = isTopBannerEnabled;
  },
  setIsSideBannerEnabled(state, isSideBannerEnabled) {
    state.isSideBannerEnabled = isSideBannerEnabled;
  },
  setBannerSettings(state, bannerSettings) {
    state.checkoutSettings.banner_settings = bannerSettings;
  },
  setTopBannerSettings(state, topBanner) {
    state.checkoutSettings.banner_settings.top_banner = topBanner;
  },
  setSideBannerSettings(state, sideBanner) {
    state.checkoutSettings.banner_settings.side_banner = sideBanner;
  },
  setBackgroundSettings(state, backgroundSettings) {
    state.checkoutSettings.background_settings = backgroundSettings;
  },
  setIsEditingOffer(state, isEditingOffer) {
    state.isEditingOffer = isEditingOffer;
  },
};
