import { jwtApi } from '@/services';
import { default as jwtDecode } from 'jwt-decode';

export default {
  async load({ commit }, from = process.env.VUE_APP_XAVIER_BASE_URL) {
    try {
      const response = await jwtApi.getJWT(from);
      const encoded = response.data.token;
      const decoded = jwtDecode(encoded);
      if (decoded) {
        commit('setDecoded', decoded);
        commit('setEncoded', encoded);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async unload({ commit }) {
    commit('setDecoded', null);
    commit('setEncoded', null);
  },
};
