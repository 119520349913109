import { OtherStageTypes, CheckoutStageType, CheckoutStages as CStages } from '@/types/funnel';

const { CHECKOUT, CHECKOUT_HEROSPARK, CHECKOUT_EDOOLS, CHECKOUT_EADBOX, CHECKOUT_HOTMART } = CheckoutStageType;

export const Stages = Object.freeze({
  ...OtherStageTypes,
  ...CheckoutStageType,
});

export const CheckoutStages = [
  CHECKOUT,
  CHECKOUT_HEROSPARK,
  CHECKOUT_EDOOLS,
  CHECKOUT_EADBOX,
  CHECKOUT_HOTMART,
] as CStages;

CheckoutStages.is = (a: CheckoutStageType) => CheckoutStages.indexOf(a) > -1;

CheckoutStages.gt = (a, b) => CheckoutStages.indexOf(a) > CheckoutStages.indexOf(b);

export enum FunnelObjectives {
  LEAD_CAPTURE = 'Funnel::Objective::LeadCapture',
  SELL_SOMETHING = 'Funnel::Objective::SellSomething',
  BROADCAST_WEBINAR = 'Funnel::Objective::BroadcastWebinar',
  MAKE_LAUNCH = 'Funnel::Objective::MakeALaunch',
  DONT_KNOW = 'Funnel::Objective::DontKnow',
}

export const FunnelObjectivesOptions = Object.freeze({
  LEAD_CAPTURE: FunnelObjectives.LEAD_CAPTURE,
  SELL_SOMETHING: FunnelObjectives.SELL_SOMETHING,
  BROADCAST_WEBINAR: FunnelObjectives.BROADCAST_WEBINAR,
  MAKE_LAUNCH: FunnelObjectives.MAKE_LAUNCH,
  DONT_KNOW: FunnelObjectives.DONT_KNOW,
});
