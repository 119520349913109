import { Stages, FunnelObjectivesOptions } from '@/libs/funnel';

const Statuses = Object.freeze({
  AWAITING: 'awaiting',
  STARTED: 'started',
  CAPTURED: 'captured',
});

export default {
  getFirstSqueezePage: (state, getters) => {
    if (getters.hasSqueezePage) {
      return state.loadedFunnel.stages
        .sort((s1, s2) => s1.order - s2.order)
        .find(stage => stage.type === Stages.SQUEEZE);
    }

    if (getters.hasFunnelPage) {
      return state.loadedFunnel.stages.sort((s1, s2) => s1.order - s2.order).find(stage => stage.type === Stages.PAGE);
    }

    return null;
  },
  hasSqueezePage: state => state?.loadedFunnel.stages.some(stage => stage.type === Stages.SQUEEZE),
  hasFunnelPage: state => state?.loadedFunnel.stages.some(stage => stage.type === Stages.PAGE),
  loadedFunnelHasLeads: state => {
    return state.loadedFunnel ? state.loadedFunnel.stages.some(stage => stage.completed_lead_stage_count > 0) : null;
  },
  getEmailTemplatesEditStatus: state =>
    state.emailTemplates.map(et => ({ id: et.id, edited: et.created_at !== et.updated_at })),
  availableStageStatuses: () => stage => {
    const type = stage.type || stage.stageType;
    switch (type) {
      case Stages.SQUEEZE:
        return [Statuses.CAPTURED];
      case Stages.CHECKOUT_HOTMART:
      case Stages.THANK_YOU:
      case Stages.CPL:
      case Stages.SALES_VIDEO:
      case Stages.SANDROSAN_SCHEDULE:
      case Stages.SANDROSAN_THANK_SCHEDULE:
      case Stages.SANDROSAN_WAIT:
      case Stages.SANDROSAN_WEBINAR:
      case Stages.PAGE:
        return [Statuses.AWAITING, Statuses.CAPTURED];
      default:
        return Object.keys(Statuses).map(status => Statuses[status]);
    }
  },
  isWebinarHerospark: state => stage => {
    if (stage) {
      return stage.type === Stages.WEBINAR && stage.media && stage.media.type === 'Webinar';
    }
    if (state.loadedStages) {
      return !!state.loadedStages.find(lst => lst.type === Stages.WEBINAR && lst.media && lst.media.type === 'Webinar');
    }
    return false;
  },
  webinarMedia: state => {
    if (state.loadedStages) {
      const stg = state.loadedStages.find(lst => lst.type === Stages.WEBINAR && lst.media);
      return stg ? stg.media : {};
    }
    return {};
  },
  broadcastViewerLink: (state, getters, rootState, rootGetters) => {
    return `${rootGetters['school/getDomainWithHttp']}/webinar?xcod=${state.loadedFunnel.id}/${state.selectedStage.id}`;
  },
  broadcastViewerLinkFromFunnelStage: (state, getters, rootState, rootGetters) => (funnel, stage) => {
    return `${rootGetters['school/getDomainWithHttp']}/webinar?xcod=${funnel.id}/${stage.id}`;
  },
  pageUrl: (state, getters, rootState, rootGetters) => stage => {
    return stage?.slug ? `${rootGetters['school/getDomainWithHttp']}/p/${stage.slug}` : null;
  },
  getFunnelObjectives: () => FunnelObjectivesOptions,
  getCheckoutStage: () => {
    return [Stages.CHECKOUT, Stages.CHECKOUT_EADBOX, Stages.CHECKOUT_HOTMART, Stages.CHECKOUT_EDOOLS];
  },
  getStagesEmails: state => {
    return state.stagesEmailsContent;
  },
  funnelObjectives: state => {
    return state.objectives;
  },
  getObjectivesInOrder: state => {
    return state.objectivesInOrder;
  },
  strategyTemplates: state => {
    return state.strategyTemplates;
  },
  objectivesSelected: state => {
    return state.objectivesSelected;
  },
  objectivesFilter: state => {
    const selected = new Set(state.objectivesSelected.objectives || []);

    let valuesMount = [];

    state.objectives.forEach(obj => {
      if (selected.has(obj.id)) {
        obj.strategies.forEach(strategy => {
          valuesMount.push(strategy);
        });
      }
    });

    return valuesMount;
  },
  strategies: state => {
    return state.strategies;
  },
};
