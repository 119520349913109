import { courseService, pathService } from '@/services';

export default {
  getCourse({ commit }, id) {
    return courseService.get(id).then(({ data }) => {
      commit('setSelectedCourse', data);
      return data;
    });
  },
  updateCourse({ commit, state }, course) {
    const newCourse = { ...state.selectedCourse, ...course };
    return courseService.update(course).then(() => {
      commit('setSelectedCourse', newCourse);
      return newCourse;
    });
  },
  getPath({ commit }, id) {
    return pathService.get(id).then(({ data }) => {
      commit('setSelectedPath', data);
      return data;
    });
  },
  updatePath({ commit, state }, path) {
    const newPath = { ...state.setSelectedPath, ...path };
    return pathService.update(path).then(() => {
      commit('setSelectedPath', newPath);
      return newPath;
    });
  },
  setCourseContents({ commit }, course_contents) {
    commit('setCourseContents', course_contents);
  },
};
