import store from '@/store';

const Affiliations = 'Affiliations';
const AffiliationsPanel = 'AffiliationsPanel';
const MyAffiliations = 'MyAffiliations';

export default {
  path: 'sparkaffiliates',
  name: 'SparkAffiliates',
  redirect: { name: 'SparkAffiliatesMainPanel' },
  component: () => import(/* webpackChunkName: "programs" */ '@/views/EmptyRouterView.vue'),
  meta: {
    breadcrumb: 'SparkAffiliates',
  },
  beforeEnter: (to, from, next) => {
    if (store.getters['auth/hasPermission']('core_affiliate_can_manage')) next();
    else next({ name: 'ProductsList' });
  },
  children: [
    {
      path: '',
      name: 'SparkAffiliatesMainPanel',
      component: () => import(/* webpackChunkName: "programs" */ '@/sparkaffiliates/views'),
      meta: {
        breadcrumb: null,
        title: `Afiliados – ${process.env.VUE_APP_APPLICATION_TITLE}`,
      },
    },
    {
      path: 'producer',
      redirect: { name: 'ProducerPanel' },
      component: () => import(/* webpackChunkName: "programs" */ '@/views/EmptyRouterView.vue'),
      meta: {
        breadcrumb: 'ProducerPanel',
      },
      children: [
        {
          path: '',
          name: 'ProducerPanel',
          component: () => import(/* webpackChunkName: "programs" */ '@/sparkaffiliates/views/Producer/ProducerPanel'),
          meta: {
            breadcrumb: null,
            title: `Painel de produtor(a) – ${process.env.VUE_APP_APPLICATION_TITLE}`,
            feature: 'spark_affiliates',
            noFeatureAccessRoute: 'SparkAffiliatesMainPanel',
          },
        },
        {
          path: 'programs',
          name: 'Programs',
          redirect: { name: 'ProgramPage' },
          meta: { feature: 'spark_affiliates', noFeatureAccessRoute: 'SparkAffiliatesMainPanel' },
          component: () => import(/* webpackChunkName: "programs" */ '@/views/EmptyRouterView.vue'),
          children: [
            {
              path: '',
              name: 'ProgramPage',
              component: () => import(/* webpackChunkName: "programs" */ '@/sparkaffiliates/views/ManagePrograms.vue'),
              meta: { title: `Programa de afiliado(a)s – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'details',
              name: 'ProgramInfo',
              component: () =>
                import(/* webpackChunkName: "programs" */ '@/sparkaffiliates/views/Producer/ProgramInfo'),
              meta: { title: `Detalhes do programa – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'edit/:id',
              name: 'ProgramEdit',
              component: () =>
                import(/* webpackChunkName: "programs" */ '@/sparkaffiliates/views/Producer/ProgramEditor'),
              meta: { title: `Editar programa – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
          ],
        },
        {
          path: 'affiliates',
          name: 'Affiliates',
          redirect: { name: 'AffiliatesPage' },
          meta: { feature: 'spark_affiliates', noFeatureAccessRoute: 'SparkAffiliatesMainPanel' },
          component: () => import(/* webpackChunkName: "programs" */ '@/views/EmptyRouterView.vue'),
          children: [
            {
              path: '',
              name: 'AffiliatesPage',
              component: () =>
                import(/* webpackChunkName: "programs" */ '@/sparkaffiliates/views/ManageAffiliates.vue'),
              meta: { title: `Gestão de afiliado(a)s – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
          ],
        },
      ],
    },
    {
      path: 'affiliations',
      name: Affiliations,
      redirect: { name: AffiliationsPanel },
      meta: { feature: 'spark_affiliates', noFeatureAccessRoute: 'SparkAffiliatesMainPanel' },
      component: () => import(/* webpackChunkName: "affiliations" */ '@/views/EmptyRouterView.vue'),
      children: [
        {
          path: '',
          name: AffiliationsPanel,
          component: () => import(/* webpackChunkName: "affiliations" */ '@/sparkaffiliates/views/Dashboard.vue'),
          meta: { breadcrumb: null, title: `Painel de afiliado – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'my-affiliations',
          name: MyAffiliations,
          component: () =>
            import(/* webpackChunkName: "affiliations" */ '@/sparkaffiliates/views/ManageAffiliations.vue'),
          meta: { title: `Gestão de afiliações – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'program/:id',
          name: 'AfiliationProgram',
          component: () => import(/* webpackChunkName: "affiliations" */ '@/sparkaffiliates/views/Program'),
          meta: { title: `Programa afiliado – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
      ],
    },
  ],
};
