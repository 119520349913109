import { DEFAULT_CHECKOUT_SETTINGS, DEFAULT_TIMER_SETTINGS, DEFAULT_WHATSAPP_SETTINGS } from './data';

export default {
  setOfferCheckoutSettings({ commit, getters }, checkoutSettings) {
    commit('setOfferCheckoutSettings', checkoutSettings);
    commit('setIsTimerEnabled', Boolean(getters.timerSettings));
    commit('setIsWhatsappEnabled', Boolean(getters.whatsappSettings));
    commit('setIsTopBannerEnabled', Boolean(getters.topBannerSettings));
    commit('setIsSideBannerEnabled', Boolean(getters.sideBannerSettings));
  },
  resetOfferCheckoutSettings({ commit }) {
    commit('setOfferCheckoutSettings', DEFAULT_CHECKOUT_SETTINGS);
    commit('setIsTimerEnabled', false);
    commit('setIsWhatsappEnabled', false);
    commit('setIsTopBannerEnabled', false);
    commit('setIsSideBannerEnabled', false);
  },
  setTimerSettings({ commit }, timerSettings) {
    commit('setTimerSettings', timerSettings);
  },
  setWhatsappSettings({ commit }, whatsappSettings) {
    commit('setWhatsappSettings', whatsappSettings);
  },
  setRequirePhone({ commit }, requirePhone) {
    commit('setRequirePhone', requirePhone);
  },
  setRequireAddress({ commit }, requireAddress) {
    commit('setRequireAddress', requireAddress);
  },
  setEmailConfirmation({ commit }, emailConfirmation) {
    commit('setEmailConfirmation', emailConfirmation);
  },
  setIsTimerEnabled({ commit, getters }, isTimerEnabled) {
    if (isTimerEnabled && !getters.timerSettings) {
      commit('setTimerSettings', DEFAULT_TIMER_SETTINGS);
    }

    if (!isTimerEnabled) commit('setTimerSettings', null);

    commit('setIsTimerEnabled', isTimerEnabled);
  },
  setIsWhatsappEnabled({ commit, getters }, isWhatsappEnabled) {
    if (isWhatsappEnabled && !getters.whatsappSettings) {
      commit('setWhatsappSettings', DEFAULT_WHATSAPP_SETTINGS);
    }

    if (!isWhatsappEnabled) commit('setWhatsappSettings', null);

    commit('setIsWhatsappEnabled', isWhatsappEnabled);
  },
  setIsTopBannerEnabled({ commit, getters }, isTopBannerEnabled) {
    if (isTopBannerEnabled && !getters.topBannerSettings) {
      commit('setBannerSettings', {
        ...getters.bannerSettings,
        top_banner: { desktop: null, mobile: null },
      });
    }

    if (!isTopBannerEnabled) commit('setTopBannerSettings', null);

    commit('setIsTopBannerEnabled', isTopBannerEnabled);
  },
  setIsSideBannerEnabled({ commit, getters }, isSideBannerEnabled) {
    if (isSideBannerEnabled && !getters.sideBannerSettings) {
      commit('setBannerSettings', {
        ...getters.bannerSettings,
        side_banner: { first: null, second: null, third: null },
      });
    }

    if (!isSideBannerEnabled) commit('setSideBannerSettings', null);

    commit('setIsSideBannerEnabled', isSideBannerEnabled);
  },
  setTopBannerSettings({ commit }, topBanner) {
    commit('setTopBannerSettings', topBanner);
  },
  setSideBannerSettings({ commit }, sideBanner) {
    commit('setSideBannerSettings', sideBanner);
  },
  setBannerSettings({ commit }, bannerSettings) {
    commit('setBannerSettings', bannerSettings);
  },
  setBackgroundSettings({ commit }, backgroundSettings) {
    commit('setBackgroundSettings', backgroundSettings);
  },
};
