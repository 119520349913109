import { Optional } from './optional';
import { BaseProduct, PaymentOption, PaymentOptionId, ProductId } from './products';

export enum OtherStageTypes {
  CPL = 'Funnel::CplPageStage',
  SQUEEZE = 'Funnel::SqueezePageStage',
  THANK_YOU = 'Funnel::ThankYouPageStage',
  SALES = 'Funnel::SalePageStage',
  SALES_VIDEO = 'Funnel::VideoSellingPageStage',
  SANDROSAN_SCHEDULE = 'Funnel::Sandrosan::SchedulePageStage',
  SANDROSAN_THANK_SCHEDULE = 'Funnel::Sandrosan::ThankSchedulePageStage',
  SANDROSAN_WAIT = 'Funnel::Sandrosan::WaitPageStage',
  SANDROSAN_WEBINAR = 'Funnel::Sandrosan::WebinarPageStage',
  PAGE = 'Funnel::PageStage',
  WEBINAR = 'Funnel::WebinarStage',
}

export enum CheckoutStageType {
  CHECKOUT = 'Funnel::CheckoutStage',
  CHECKOUT_EADBOX = 'Funnel::EadboxCheckoutStage',
  CHECKOUT_HOTMART = 'Funnel::HotmartCheckoutStage',
  CHECKOUT_EDOOLS = 'Funnel::EdoolsCheckoutStage',
  CHECKOUT_HEROSPARK = 'Funnel::HerosparkCheckoutStage',
}

export type FunnelStageType = CheckoutStageType | OtherStageTypes;

export type CheckoutStageId = number;

export interface CheckoutStage {
  id: CheckoutStageId;
  type: CheckoutStageType;
  url?: string;
  product_id: Optional<ProductId>;
  payment_option_id: Optional<PaymentOptionId>;
}

export type FunnelId = number;

export interface Funnel {
  id: FunnelId;
}

export type UpdateStageCheckout = {
  funnelId: FunnelId;
  stageId: CheckoutStageId;
  type: CheckoutStageType;
  url: string;
  product: Optional<BaseProduct>;
  paymentOption: Optional<PaymentOption>;
};

export interface CheckoutStageForm {
  type: CheckoutStageType;
  url: string;
  product: Optional<BaseProduct>;
  paymentOption: Optional<PaymentOption>;
}

export type CheckoutStages = string[] & {
  gt(a: CheckoutStageType, b: CheckoutStageType): boolean;
  is(o: CheckoutStageType): boolean;
};

export type StrategyTemplatesType = [
  {
    id: number;
    language: string;
    media: [];
    published: boolean;
    subtitle: string;
    strategyId: number;
    title: string;
  }
];

export type TemplateThemeType = {
  banner: string;
  checkout_count: number;
  description: string;
  email_count: number;
  funnel_strategy_tags: [];
  id: number;
  idObjective: number;
  level: string;
  name: string;
  page_count: number;
  titleObjective: string;
  webinar_count: number;
};
