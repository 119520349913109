import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import TokenHelper from '@/shared/helpers/token';

export default {
  namespaced: true,
  state: {
    loggedUser: null,
    roles: [],
    channels: [],
    token: TokenHelper.getToken(),
  },
  actions,
  getters,
  mutations,
};
