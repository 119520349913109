const accountTypes = Object.freeze({ HEROSPARK: 'herospark', EDOOLS: 'edools' });

const slug = text =>
  (text || '')
    .trim()
    .replace(/ /g, '-')
    .toLowerCase();

const splitName = name => {
  const EMPTY_STRING = ' ';
  const splitted = name ? name.split(EMPTY_STRING) : EMPTY_STRING.split(EMPTY_STRING);
  const firstName = splitted.shift();
  const lastName = splitted ? splitted.join(EMPTY_STRING) : EMPTY_STRING;

  return { firstName, lastName };
};

export const currency = (value, symbol = true) => {
  const symbolStyle = symbol ? { style: 'currency', currency: 'BRL' } : {};
  return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, ...symbolStyle });
};

const valueNormalizeCents = value => value / 100;
const valueInCents = value => {
  const priceInCents = value * 100;
  const fixedPrice = priceInCents.toFixed(2);

  return parseInt(fixedPrice, 10);
};

const cpf = (value, mask = false) => {
  return value.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, mask ? '***.***.***-$4' : '$1.$2.$3-$4');
};

const cnpj = (value, mask = false) => {
  return value
    .replace(/[^\d]/g, '')
    .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, mask ? '**.***.***/****-$5' : '$1.$2.$3/$4-$5');
};

const bankAccount = (value, mask = false) => {
  return mask && value ? `****${value.slice(4)}` : value;
};

const parseToQueryString = params => {
  return params
    .reduce((current, p) => {
      if (Array.isArray(p.value)) {
        p.value.map(value => {
          current.push(`${p.key}[]=${encodeURIComponent(value)}`);
        });
      } else {
        current.push(`${p.key}=${encodeURIComponent(p.value)}`);
      }
      return current;
    }, [])
    .join('&');
};

const parseFiltersToParams = filters => {
  return filters.filter(f => f.value).map(f => ({ key: f.key, value: f.value }));
};

const isSupportedFileByExtension = (filename, extension) => new RegExp(`${extension}\\b$`).test(filename);

const isSupportedFile = ({ supportedFiles, fileType, fileName }) =>
  !!supportedFiles.find(item =>
    item[0] == '.' ? isSupportedFileByExtension(fileName, item) : fileType.includes(item)
  );

const accountDomain = ({ accountType, subDomain }) => {
  if (accountType === accountTypes.HEROSPARK) return `${subDomain}.${process.env.VUE_APP_HERO_DOMAIN}`;
  return `${subDomain}.${process.env.VUE_APP_DOMAIN_URL}`;
};

export const getAdminViewSsoUrl = ({ domain, user, redirectTo }) => {
  try {
    const url = new URL(`${window.location.protocol}//${domain}/sso`);
    url.searchParams.set('email', user.email);
    url.searchParams.set('credentials', user.credentials);
    url.searchParams.set('redirect_to', redirectTo);
    if (user?.super_admin?.id) {
      url.searchParams.set('super_admin_id', user?.super_admin?.id);
    }
    return url.toString();
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    return '';
  }
};

export const isValidJSON = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export default {
  accountTypes,
  slug,
  splitName,
  currency,
  cpf,
  cnpj,
  bankAccount,
  parseToQueryString,
  parseFiltersToParams,
  valueNormalizeCents,
  valueInCents,
  isSupportedFile,
  accountDomain,
  isValidJSON,
  getAdminViewSsoUrl,
};
