export default {
  editedStageOnLoaded: state => stageId => {
    const stage = state.loadedStages.find(ls => ls.id === stageId);

    return stage ? stage.edited : null;
  },
  hasLoadedEmailTemplateStored: state => stageId =>
    !!state.loadedEmailTemplates.find(loadedET => loadedET.stageId === stageId),
  hasLoadedStage: state => stageId => !!state.loadedStages.find(ls => ls.id === stageId),
};
