export default {
  hidePopovers(state) {
    state.profilePopoverOpened = false;
  },
  hideSideMenu(state) {
    state.sideMenuOpened = false;
  },
  toggleProfilePopover(state) {
    state.profilePopoverOpened = !state.profilePopoverOpened;
  },
  toggleSideMenu(state) {
    state.sideMenuOpened = !state.sideMenuOpened;
  },
};
