import axiosXavier from '@/services/axios';

const LEADS_ROUTE = 'leads';

export default {
  getLeads({ currentPage, perPage, query } = {}) {
    return axiosXavier.get(LEADS_ROUTE, { params: { page: currentPage, per_page: perPage, query } });
  },
  deleteLeads: leadsIds => axiosXavier.post(`${LEADS_ROUTE}/delete`, { leads_ids: leadsIds }),
  import({ campaignId, funnelId, leads, stageId }) {
    return axiosXavier.post(`imports/${LEADS_ROUTE}`, {
      campaign_id: campaignId,
      funnel_id: funnelId,
      leads,
      stage_id: stageId,
    });
  },
  importBetweenFunnels({ destinationId, sourceId }) {
    return axiosXavier.post(`imports/${LEADS_ROUTE}/from_funnel`, {
      destination_id: destinationId,
      source_id: sourceId,
    });
  },
  unsubscribeFromFunnel: ({ email, funnelId }) => unsubscribe({ email, funnel_id: funnelId }),
  unsubscribeFromCampaign: ({ email, schoolId }) => unsubscribe({ email, school_id: schoolId }),
};

function unsubscribe(params) {
  return axiosXavier.post(`${LEADS_ROUTE}/unsubscribe`, params, {
    headers: {
      Authorization: '',
    },
  });
}
