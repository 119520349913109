import axiosXavier from '../../services/axios';
import GeneralHelper from '@/shared/helpers/general';

export default {
  getById: id => axiosXavier.get(`extra_contents/${id}`).then(res => res.data),
  get(params) {
    return axiosXavier.get(`extra_contents?${GeneralHelper.parseToQueryString(params)}`).then(res => res.data);
  },
  create: extra_content =>
    axiosXavier
      .post(`extra_contents`, {
        extra_content,
      })
      .then(res => res.data),
  update: extra_content => axiosXavier.put(`extra_contents/${extra_content.id}`, extra_content),
  delete: id => axiosXavier.delete(`extra_contents/${id}`),
};
