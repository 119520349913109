import _ from 'lodash';
import Vue from 'vue';

const isActive = status => status === 'active';

const ofertaDiretaTemplate = /oferta\sdireta/i;

// promove the templates that will be pushed
// to the position `1` (or recommended)
// NOTE: we can not promote templates
// with the same `objective` since we can have
// some weird ordering behavior
const promoteTemplates = templates => {
  const [promoted, rest] = _.partition(templates, ({ title }) => ofertaDiretaTemplate.test(title));
  return promoted.concat(rest);
};

export default {
  setFunnels(state, funnels) {
    state.items = funnels.map(funnel => ({
      ...funnel,
      isActive: isActive(funnel.status),
    }));
  },
  setTemplates(state, templates) {
    state.templates = promoteTemplates(templates);
  },
  setSelectedFunnel(state, funnel) {
    state.selectedFunnel = funnel;
  },
  setLoadedFunnel(state, funnel) {
    state.loadedFunnel = funnel;
  },
  setSelectedStage(state, stage) {
    state.selectedStage = stage;
  },
  setStagesFromLoadedFunnel(state, stages) {
    Vue.set(state.loadedFunnel, 'stages', stages);
  },
  setLoadedFunnelStageSlug(state, { stageId, newSlug }) {
    const stageIndex = state.loadedFunnel.stages.findIndex(stage => stage.id === stageId);
    Vue.set(state.loadedFunnel.stages[stageIndex], 'slug', newSlug);
  },
  setLoadedFunnelStageCheckout(state, { stageId, url, type }) {
    const stage = state.loadedFunnel.stages.find(stage => stage.id === stageId);
    const stageIndex = state.loadedFunnel.stages.findIndex(stage => stage.id === stageId);
    Vue.set(state.loadedFunnel.stages, stageIndex, { ...stage, url, type });
  },
  setLoadedStages(state, loadedStages) {
    state.loadedStages = loadedStages;
  },
  setLoadedMedia(state, media) {
    state.loadedMedia = media;
  },
  cleanObjectivesSelected(state) {
    state.objectivesSelected = [];
  },
  setObjectivesSelected(state, selected) {
    state.objectivesSelected = { ...selected };
  },
  setEditingEmailTemplate(state, editingEmailTemplate) {
    state.editingEmailTemplate = editingEmailTemplate;
  },
  setSelectedEmailTemplate(state, selectedEmailTemplate) {
    state.selectedEmailTemplate = selectedEmailTemplate;
  },
  setFunnelStatus(state, { funnelId, newStatus }) {
    state.items.forEach(funnel => {
      if (funnel.id === funnelId) {
        funnel.status = newStatus;
        funnel.isActive = isActive(newStatus);
      }
    });
  },
  setFunnelObjectives(state, funnelObjectives) {
    const { strategies, objectives } = funnelObjectives.reduce(
      (acc, obj) => {
        const { funnel_strategies } = obj;

        const { ids } = funnel_strategies.reduce(
          (acc, str) => {
            acc.strategies[str.id] = str;
            acc.ids.push(str.id);
            return acc;
          },
          { strategies: acc.strategies, ids: [] }
        );

        acc.objectives.push({ ...obj, strategies: ids });

        return acc;
      },
      { strategies: {}, objectives: [] }
    );

    state.strategies = strategies;
    state.objectives = objectives;
  },
  setStrategyTemplates(state, values) {
    const { funnel_templates, strategyId } = values;
    state.strategyTemplates.add({ strategyId, ...funnel_templates[0] });
  },
  setLoadedFunnelStatus(state, { newStatus }) {
    state.loadedFunnel.status = newStatus;
  },
  setEmailTemplates: (state, { emailTemplates }) => (state.emailTemplates = emailTemplates),
  deleteEmailTemplate: (state, { emailTemplateId }) =>
    (state.emailTemplates = state.emailTemplates.filter(template => template.id !== emailTemplateId)),
  addEmailTemplate: (state, { emailTemplate }) => (state.emailTemplates = [...state.emailTemplates, emailTemplate]),
  updateEmailTemplate: (state, { emailTemplate }) => {
    state.emailTemplates = state.emailTemplates.map(template => {
      if (template.id === emailTemplate.id) {
        return emailTemplate;
      }
      return template;
    });
  },
  updateStagesEmailsContent: (state, { id, emails }) => {
    state.stagesEmailsContent.push({ id, emails });
  },
  cleanStagesEmailsContent: state => {
    state.stagesEmailsContent = [];
  },
};
