import axiosXavier from '@/services/axios';

export default {
  create({ name, schoolId, stages_attributes, template_name, template_type }) {
    return axiosXavier.post('funnels', {
      funnel: {
        name,
        school_id: schoolId,
        stages_attributes,
        template_name,
        template_type,
      },
    });
  },
  getEmailTemplates(funnelId, stageId, currentPage, perPage) {
    return axiosXavier.get(`funnels/${funnelId}/stages/${stageId}/email_templates`, {
      params: { page: currentPage, per_page: perPage },
    });
  },
  getEmailTemplate({ funnelId, stageId, templateId }) {
    return axiosXavier.get(`funnels/${funnelId}/stages/${stageId}/email_templates/${templateId}`);
  },
  getFunnel(id) {
    return axiosXavier.get(`funnels/${id}`);
  },
  getFunnelLeads({ funnelId, currentPage, perPage, query }) {
    return axiosXavier.get(`funnels/${funnelId}/leads`, { params: { per_page: perPage, page: currentPage, query } });
  },
  getStage(funnelId, stageId) {
    return axiosXavier.get(`funnels/${funnelId}/stages/${stageId}`);
  },
  getFunnels({ currentPage, perPage }) {
    let queryParams = new URLSearchParams();
    Boolean(currentPage) && queryParams.set('page', currentPage);
    Boolean(perPage) && queryParams.set('per_page', perPage);
    let url = `funnels/?${queryParams.toString()}`;
    return axiosXavier.get(url);
  },
  getFunnelsLimit() {
    return axiosXavier.get('utilizations');
  },
  update(funnel) {
    return axiosXavier.put(`funnels/${funnel.id}`, funnel);
  },
  updateStageCheckout({ funnelId, stageId, url, type, product, paymentOption }) {
    return axiosXavier.put(`funnels/${funnelId}/stages/${stageId}`, {
      stage: {
        url,
        type,
        product_id: product?.id || null,
        payment_option_id: paymentOption?.id || null,
      },
    });
  },
  updateStageSlug({ funnelId, stageId, newSlug }) {
    return axiosXavier.put(`funnels/${funnelId}/stages/${stageId}`, {
      stage: {
        new_slug: newSlug,
      },
    });
  },
  getMedia(mediaId) {
    return axiosXavier.get(`media/${mediaId}`);
  },
  updateMedia(mediaId, media) {
    return axiosXavier.put(`media/${mediaId}`, media);
  },
  updateEmailTemplate: ({ funnelId, stageId, emailTemplate }) =>
    axiosXavier.put(`funnels/${funnelId}/stages/${stageId}/email_templates/${emailTemplate.id}`, emailTemplate),
  updateStatus: ({ funnelId, operation }) => axiosXavier.put(`funnels/${funnelId}/${operation}`),
  deleteEmailTemplate: ({ funnelId, stageId, emailTemplateId }) =>
    axiosXavier.delete(`funnels/${funnelId}/stages/${stageId}/email_templates/${emailTemplateId}`),
  createEmailTemplate: ({ funnelId, stageId, emailTemplate }) =>
    axiosXavier.post(`funnels/${funnelId}/stages/${stageId}/email_templates`, { ...emailTemplate }),
  getFunnelTemplates: ({ language = 'pt-BR', published, perPage = 50 }) =>
    axiosXavier.get('funnels/funnel_templates', {
      params: { language, published, per_page: perPage },
    }),
  createFromTemplate: ({ funnelTemplateId, funnelName, schoolId }) =>
    axiosXavier.post(`/funnels/funnel_templates/${funnelTemplateId}/funnel`, {
      school_id: schoolId,
      funnel_name: funnelName,
    }),
  createFunnelTemplate: ({
    title,
    subtitle,
    language,
    published = false,
    media,
    funnelObjectivesAttributes,
    funnelTagsAttributes,
    funnelStagesAttributes,
  }) => {
    return axiosXavier.post('/funnels/funnel_templates', {
      funnel_template: {
        title,
        subtitle,
        language,
        published,
        media,
        funnel_objectives_attributes: funnelObjectivesAttributes,
        funnel_tags_attributes: funnelTagsAttributes,
        funnel_stages_attributes: funnelStagesAttributes,
      },
    });
  },
  duplicateFunnel: ({ funnelId }) => axiosXavier.post(`funnels/${funnelId}/duplicate`),
  deleteFunnels: funnelsIds => axiosXavier.post(`funnels/delete`, { funnels_ids: funnelsIds }),
  funnelObjectives: () => {
    return axiosXavier.get('funnels/objectives');
  },
  getStrategyTemplates(strategyId) {
    return axiosXavier.get(`funnel_strategies/${strategyId}`);
  },
};
