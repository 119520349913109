import axios from 'axios';

export default {
  async subdomainValidation(new_subdomain: string) {
    const url = `${process.env.VUE_APP_BASE_URL}signup/check_subdomain`;
    const response = await axios.post(url, { new_subdomain });

    return response;
  },
};
