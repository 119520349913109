const defaultConfigOverwrite = {
  hiddenPremeetingButtons: ['invite'],
  disableInviteFunctions: true,
  disabledNotifications: ['notify.participantWantsToJoin'],
  disabledSounds: [
    // 'ASKED_TO_UNMUTE_SOUND',
    // 'E2EE_OFF_SOUND',
    // 'E2EE_ON_SOUND',
    'INCOMING_MSG_SOUND',
    'KNOCKING_PARTICIPANT_SOUND',
    // 'LIVE_STREAMING_OFF_SOUND',
    // 'LIVE_STREAMING_ON_SOUND',
    // 'NO_AUDIO_SIGNAL_SOUND',
    // 'NOISY_AUDIO_INPUT_SOUND',
    // 'OUTGOING_CALL_EXPIRED_SOUND',
    // 'OUTGOING_CALL_REJECTED_SOUND',
    // 'OUTGOING_CALL_RINGING_SOUND',
    // 'OUTGOING_CALL_START_SOUND',
    'PARTICIPANT_JOINED_SOUND',
    'PARTICIPANT_LEFT_SOUND',
    // 'RAISE_HAND_SOUND',
    'REACTION_SOUND',
    // 'RECORDING_OFF_SOUND',
    // 'RECORDING_ON_SOUND',
    // 'TALK_WHILE_MUTED_SOUND'
  ],
  toolbarButtons: [
    'camera',
    'chat',
    'closedcaptions',
    'desktop',
    // 'dock-iframe',
    // 'download',
    // 'embedmeeting',
    'etherpad',
    // 'feedback',
    'filmstrip',
    'fullscreen',
    'hangup',
    'help',
    'highlight',
    // 'invite',
    // 'linktosalesforce',
    'livestreaming',
    'microphone',
    'noisesuppression',
    'participants-pane',
    // 'profile',
    'raisehand',
    'recording',
    // 'security',
    'select-background',
    'settings',
    'shareaudio',
    'sharedvideo',
    'shortcuts',
    // 'stats',
    'tileview',
    'toggle-camera',
    // 'undock- iframe',
    'videoquality',
    'whiteboard',
  ],
  recordingService: {
    hideStorageWarning: true,
  },
};

const defaultInterfaceConfigOverwrite = {
  HIDE_INVITE_MORE_HEADER: true,
  SETTINGS_SECTIONS: ['devices', 'language', 'moderator', 'profile', 'calendar'],
  DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
};

export default {
  getJitsiAPI: (room, jwt, selector, configOverwrite) => {
    const api = new JitsiMeetExternalAPI('8x8.vc', {
      parentNode: document.querySelector(selector),
      lang: 'ptBR',
      roomName: `${room}`,
      jwt: `${jwt}`,
      configOverwrite: {
        ...defaultConfigOverwrite,
        ...configOverwrite,
      },
      interfaceConfigOverwrite: {
        ...defaultInterfaceConfigOverwrite,
      },
    });
    api.on('readyToClose', function() {
      const params = new URLSearchParams(window.location.search);
      const productId = params.get('id');
      const courseId = params.get('content');
      window.location = `/sparkmembers/products/${productId}/contents/live_meets/${courseId}`;
    });
    return api;
  },
};
