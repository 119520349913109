import AnalyticsService from '@/services/analytics';

const Events = Object.freeze({
  AFFILIATE_FACEBOOK_PIXEL_ADDED: 'Affiliate Facebook Pixel Added',
  BANK_ACCOUNT_CREATED: 'Bank account created',
  BANNER_LINK_CLICKED: 'Offer Banner Clicked',
  CHECKOUT_LINK: 'Checkout Link Copied',
  COMMUNITY_PAGE_OPEN_FORM: 'Community Page open form',
  COMMUNITY_PAGE_SEND_FORM: 'Community Page send form',
  COMMUNITY_PAGE_VIEWED: 'Community Page Viewed',
  FAQ_PAGE_VIEWED: 'FAQ Page Viewed',
  FUNNEL_CREATED: 'Funnel Created',
  FUNNEL_CREATION_INITIATED: 'Funnel Creation Initiated',
  FUNNEL_EMAIL_CREATED: 'Email Automation Created',
  FUNNEL_EMAIL_EDITED: 'Email Automation Edited',
  FUNNEL_EMAIL_REMOVED: 'Email Automation Removed',
  FUNNEL_SELECTED_OBJECTIVES: 'Funnels objectives',
  FUNNEL_SELECTION_SCREEN: 'Funnel Selection Screen',
  HOME_BANNER_DETAIL_DISPLAYED: 'Home Banner Detail Displayed',
  LEADS_REMOVAL: 'Funnels_lead_deleted',
  LIVE_CLASS_INTERESTED: 'Live Class Interested',
  MBM_SEARCHED: 'Member Searched',
  MBM_MESSAGE_SENT: 'Member Message Sent',
  MBM_ENROLLED: 'Member Enrolled',
  MBM_ENROLLMENT_DEACTIVATED: 'Member Enrollment deactivated',
  MBM_DELETED: 'Member Deleted',
  MBM_TAG_ADDED: 'Member Tag Added',
  MBM_IMPORTED: 'Member imported',
  MBM_DETAILS_OPENED: 'Member Details Opened',
  MY_BANNER_VIEWED: 'Offer Banner Viewed',
  MY_PLAN_VIEWED: 'My Plan Viewed',
  NEW_PRODUCT_TYPE_VOTED: 'New product type voted',
  PAYWALL_DISPLAYED: 'Paywall displayed',
  PLAN_LIST_VIEWED: 'Plan List Viewed',
  PLAN_SELECTED: 'Plan Selected',
  PRODUCT_COVER_UPDATED: 'Product Cover Updated',
  PRODUCT_CREATED: 'Product created',
  PRODUCT_CREATION_INITIATED: 'Product Creation Initiated',
  PRODUCT_DELETED: 'Product Deleted',
  PRODUCT_PRICE_DEFINED: 'Product Price Defined',
  PRODUCT_PRICE_UPDATED: 'Product Price Updated',
  PRODUCT_SALES_PAGE_CREATED: 'Product Sales Page Created',
  PRODUCT_SALES_PAGE_DELETED: 'Product Sales Page Deleted',
  PRODUCT_SALES_PAGE_EDITING_INITIATED: 'Product Sales Page Editing Initiated',
  PRODUCT_SALES_PAGE_PUBLISHED: 'Product Sales Page Published',
  PRODUCT_SALES_PAGE_SLUG_UPDATED: 'Product Sales Page Slug Updated',
  PRODUCT_SALES_PAGE_TITLE_UPDATED: 'Product Sales Page Title Updated',
  PRODUCT_SALES_PAGE_UNPUBLISHED: 'Product Sales Page Unpublished',
  SALES_PAGE_LINK: 'Sales Page Link Copied',
  SUBDOMAIN_EDITED: 'HeroSpark subdomain edited',
  FACEBOOK_PIXEL_ENABLED: 'Facebook Pixel enabled',
  CHECKOUT_BANNER_ENABLED: 'Custom banner enabled',
  GOOGLE_ANALYTICS_ENABLED: 'Google Analytics enabled',
  GOOGLE_ADS_ENABLED: 'Google ADS enabled',
  WHATSAPP_WIDGET_ENABLED: 'Whatsapp widget enabled',
  AFFILIATION_PROGRAM_NEW_PROGRAM: 'Affiliation Program Creation Started',
  AFFILIATION_PROGRAM_FILLED_BASIC_INFORMATION: 'Affiliation Program Basic Information Filled',
  AFFILIATION_PROGRAM_OFFERS_ADDED: 'Affiliation Program Offers Added',
  AFFILIATION_PROGRAM_FILLED_COMMISSION: 'Affiliation Program Commission Filled',
  AFFILIATION_PROGRAM_FILLED_EXPIRATION_DATE: 'Affiliation Program Expiration Date Filled',
  AFFILIATION_PROGRAM_CREATED: 'Affiliation Program Created',
  AFFILIATION_PROGRAM_DEACTIVATED: 'Affiliation Program Deactivated',
  AFFILIATION_PROGRAM_ACTIVATED: 'Affiliation Program Activated',
  AFFILIATE_INVITATION_STARTED: 'Affiliate Invitation Started',
  AFFILIATE_INVITED_SENT: 'Affiliate Invited',
  AFFILIATE_AFFILIATION_DEACTIVATED: 'Affiliation Deactivated',
  AFFILIATE_AFFILIATION_ACTIVATED: 'Affiliation Activated',
  AFFILIATION_OFFER_LINK_COPIED: 'Affiliation Offer Link Copied',
  AFFILIATION_INVITED_ACCEPTED: 'Affiliation Invite Accepted',
  COPRODUCTION_DELETE: 'Coproduction Delete',
  ENOTAS_INTEGRATION_ENABLED: 'eNotas Integration Enabled',
  COPRODUCTION_CREATED: 'Coproduction Created',
  AUTOMATIONSELECTMODEL: 'Automation Created',
  AUTOMATIONACTIVE: 'Automation active',
  AUTOMATIONEDIT: 'Automation edited',
  AUTOMATIMODALTYPE: 'Automation Type Chosen',
  AUTOMATIONADDHEADER: 'Automation Header Added',
  AUTOMATIONMETHODUSED: 'Automation Method Used',
  AUTOMATIONDELETE: 'Automation delete',
  AUTOMATIONUSEVARIABLE: 'Automation Variable Used',
  OFFER_REQUIRE_PHONE_ENABLED: 'Offer Required Phone Enabled',
  OFFER_REQUIRE_ADDRESS_ENABLED: 'Offer Required Address Enabled',
  OFFER_CUSTOM_THANKS_PAGE_ENABLED: 'Offer Custom Thanks Page Enabled',
  NEWTHEMEACTIVE: 'Theme active',
  STUDENTAREABANNERUPDATE: 'Banner update',
  ORDER_BUMP_CREATED: 'Order Bump Created',
  ORDER_BUMP_UPDATED: 'Order Bump Updated',
  ORDER_BUMP_DELETED: 'Order Bump Deleted',
  ORDER_BUMP_LINK_COPIED: 'Order Bump Link Copied',
  ORDER_BUMP_ENABLED: 'Order Bump Enabled',
  ORDER_BUMP_DISABLED: 'Order Bump Disabled',
});

const NotifyLeads = Object.freeze({
  ALL: 'Para todos os Leads',
  ONLY_NEW: 'Apenas para novos Leads',
});

export default {
  Events,
  NotifyLeads,
  /**
   * Tracks how many leads were removed, either if manually or if they unsubscribed
   * @param {Number} count - Number of leads removed
   * @param {Boolean} unsubscribed - If leads unsubscribed or were removed manually
   */
  trackLeadsRemoval: (count, unsubscribed) =>
    AnalyticsService.track({
      event: Events.LEADS_REMOVAL,
      props: {
        count,
        unsubscribed,
      },
    }),
  /**
   * Tracks when a funnel email template is deleted
   * @param {Number} funnel_id Funnel id
   * @param {String} funnel_model_type Funnel type
   * @param {String} funnel_stage_type Stage type
   * @param {String} funnel_stage_lead_status Email status
   * @param {Boolean} automation_created_by_user Wheter email was created by user or not
   */
  trackEmailRemoval: (
    funnel_id,
    funnel_model_type,
    funnel_stage_type,
    funnel_stage_lead_status,
    automation_created_by_user
  ) =>
    AnalyticsService.track({
      event: Events.FUNNEL_EMAIL_REMOVED,
      props: {
        funnel_id,
        funnel_model_type,
        funnel_stage_type,
        funnel_stage_lead_status,
        automation_created_by_user,
      },
    }),
  /**
   * Tracks when a funnel email is edited by user
   * @param {Number} funnel_id Funnel id
   * @param {Boolean} always_notify_all If email template was set to notify all leads or not
   */
  trackEmailEdition: (funnel_id, always_notify_all) =>
    AnalyticsService.track({
      event: Events.FUNNEL_EMAIL_EDITED,
      props: {
        funnel_id,
        email_automation_sending_option: always_notify_all ? NotifyLeads.ALL : NotifyLeads.ONLY_NEW,
      },
    }),
  /**
   * Tracks when a funnel email is created by user
   * @param {Number} funnel_id Funnel id
   * @param {String} funnel_model_type Funnel model type
   * @param {String} funnel_stage_type Stage type
   * @param {String} funnel_stage_lead_status Email status
   * @param {Boolean} always_notify_all If email template was set to notify all leads or not
   */
  trackEmailCreation: (funnel_id, funnel_model_type, funnel_stage_type, funnel_stage_lead_status, always_notify_all) =>
    AnalyticsService.track({
      event: Events.FUNNEL_EMAIL_CREATED,
      props: {
        funnel_id,
        funnel_model_type,
        funnel_stage_type,
        funnel_stage_lead_status,
        email_automation_sending_option: always_notify_all ? NotifyLeads.ALL : NotifyLeads.ONLY_NEW,
      },
    }),

  /**
   * Tracks when checkout link was copied
   * @param {String} location - View of checkout link was clicked
   */
  trackCheckoutLinkCopied: location =>
    AnalyticsService.track({
      event: Events.CHECKOUT_LINK,
      props: {
        location: location,
      },
    }),

  /**
   * Tracks when sales link was copied
   * @param {String} location - View of sales link was clicked
   */
  trackSalesLinkCopied: location =>
    AnalyticsService.track({
      event: Events.SALES_PAGE_LINK,
      props: {
        location,
      },
    }),

  trackBannerLinkClicked: (location, offer_name, email, school_id) =>
    AnalyticsService.track({
      event: Events.BANNER_LINK_CLICKED,
      props: {
        location,
        offer_name,
        email,
        school_id,
      },
    }),

  trackBannerViewed: (location, offer_name, email, school_id) =>
    AnalyticsService.track({
      event: Events.MY_BANNER_VIEWED,
      props: {
        location,
        offer_name,
        email,
        school_id,
      },
    }),

  /**
   * Tracks when funnel screen was selected
   * @param {String} location - View where the redirect button was clicked
   */
  trackFunnelSelectionScreen: location =>
    AnalyticsService.track({
      event: Events.FUNNEL_SELECTION_SCREEN,
      props: {
        location,
      },
    }),
  /**
   * Tracks when a product was created
   * @param {String} title - Product title
   * @param {Number} id - Product ID
   * @param {String} type - Product type
   * @param {String} niche - Product niche
   * @param {Number} resource_id - Product library resource id
   * @param {String} location that was triggered
   */
  trackProductCreated: (title, id, type, niche, resource_id, location) =>
    AnalyticsService.track({
      event: Events.PRODUCT_CREATED,
      props: {
        product_title: title,
        product_id: id,
        product_type: type,
        product_niche: niche,
        resource_id,
        location,
      },
    }),
  /**
   * Tracks when a product was created
   * @param {String} title - Product title
   * @param {Number} id - Product ID
   * @param {String} type - Product type
   * @param {String} type - Product niche
   * @param {Number} resource_id - Product library resource id
   */
  trackProductDeleted: (title, id, type, niche, resource_id) =>
    AnalyticsService.track({
      event: Events.PRODUCT_DELETED,
      props: {
        product_title: title,
        product_id: id,
        product_type: type,
        product_niche: niche,
        resource_id: resource_id,
      },
    }),
  /**
   * Tracks when a product was created
   * @param {Number} id - Product ID
   * @param {String} title - Product title
   * @param {String} type - Precification type
   * @param {String} recurrence - Recurrence type
   * @param {String} product_type - Product type
   * @param {Boolean} facebook_pixel - Facebook pixel
   * @param {Boolean} google_analytics - Google analytics
   * @param {Number} price - Product price
   * @param {Number} installments - Installment amount limit
   * @param {Boolean} credit_card - Installment amount limit
   * @param {Boolean} boleto - Installment amount limit
   */
  trackProductPriceDefined: (
    id,
    title,
    type,
    recurrence,
    product_type,
    facebook_pixel,
    google_analytics,
    price,
    installments,
    credit_card,
    boleto,
    pix
  ) =>
    AnalyticsService.track({
      event: Events.PRODUCT_PRICE_DEFINED,
      props: {
        product_id: id,
        product_title: title,
        type: type,
        recurrence: recurrence,
        product_type: product_type,
        facebook_pixel: facebook_pixel,
        google_analytics: google_analytics,
        product_price: price,
        installments: installments,
        credit_card: credit_card,
        boleto: boleto,
        pix: pix,
      },
    }),
  /**
   * Tracks when a product was created
   * @param {Number} id - Product ID
   * @param {String} type - Precification type
   * @param {String} recurrence - Recurrence type
   * @param {String} product_type - Product type
   * @param {Boolean} facebook_pixel - Facebook pixel
   * @param {Boolean} google_analytics - Google analytics
   * @param {Number} price - Product price
   * @param {Number} installments - Installment amount limit
   * @param {Boolean} credit_card - Installment amount limit
   * @param {Boolean} boleto - Installment amount limit
   */
  trackProductPriceUpdated: (
    id,
    title,
    type,
    recurrence,
    product_type,
    facebook_pixel,
    google_analytics,
    price,
    installments,
    credit_card,
    boleto,
    pix
  ) =>
    AnalyticsService.track({
      event: Events.PRODUCT_PRICE_UPDATED,
      props: {
        product_id: id,
        product_title: title,
        type: type,
        recurrence: recurrence,
        product_type: product_type,
        facebook_pixel: facebook_pixel,
        google_analytics: google_analytics,
        product_price: price,
        installments: installments,
        credit_card: credit_card,
        boleto: boleto,
        pix: pix,
      },
    }),

  /**
   * Tracks when a product was created
   * @param {Number} product_id - Product ID
   * @param {Number} page_id - Page ID
   */
  trackProductSalesPageCreated: (product_id, page_id) =>
    AnalyticsService.track({
      event: Events.PRODUCT_SALES_PAGE_CREATED,
      props: {
        product_id,
        page_id,
      },
    }),

  /**
   * Tracks when a product was deleted
   * @param {Number} product_id - Product ID
   * @param {Number} page_id - Page ID
   */
  trackProductSalesPageDeleted: (product_id, page_id) =>
    AnalyticsService.track({
      event: Events.PRODUCT_SALES_PAGE_DELETED,
      props: {
        product_id,
        page_id,
      },
    }),

  /**
   * Tracks when a product was editing initiated
   * @param {Number} product_id - Product ID
   * @param {Number} page_id - Page ID
   */
  trackProductSalesPageEditingInitiated: (product_id, page_id) =>
    AnalyticsService.track({
      event: Events.PRODUCT_SALES_PAGE_EDITING_INITIATED,
      props: {
        product_id,
        page_id,
      },
    }),

  /**
   * Tracks when a product was published
   * @param {Number} product_id - Product ID
   * @param {Number} page_id - Page ID
   */
  trackProductSalesPagePublished: (product_id, page_id) =>
    AnalyticsService.track({
      event: Events.PRODUCT_SALES_PAGE_PUBLISHED,
      props: {
        product_id,
        page_id,
      },
    }),

  /**
   * Tracks when a product was published
   * @param {Number} product_id - Product ID
   * @param {Number} page_id - Page ID
   * @param {String} slug - Page ID
   */
  trackProductSalesPageSlugUpdated: (product_id, page_id, slug) =>
    AnalyticsService.track({
      event: Events.PRODUCT_SALES_PAGE_SLUG_UPDATED,
      props: {
        product_id,
        page_id,
        slug,
      },
    }),

  /**
   * Tracks when a product was published
   * @param {Number} product_id - Product ID
   * @param {Number} page_id - Page ID
   * @param {String} title - Page ID
   */
  trackProductSalesPageTitleUpdated: (product_id, page_id, title) =>
    AnalyticsService.track({
      event: Events.PRODUCT_SALES_PAGE_TITLE_UPDATED,
      props: {
        product_id,
        page_id,
        title,
      },
    }),

  /**
   * Tracks when a product was unpublished
   * @param {Number} product_id - Product ID
   * @param {Number} page_id - Page ID
   */
  trackProductSalesPageUnpublished: (product_id, page_id) =>
    AnalyticsService.track({
      event: Events.PRODUCT_SALES_PAGE_UNPUBLISHED,
      props: {
        product_id,
        page_id,
      },
    }),

  /**
   * Tracks when user visited /plan
   */
  trackPlanViewed: () =>
    AnalyticsService.track({
      event: Events.MY_PLAN_VIEWED,
      props: {},
    }),

  /**
   * Tracks when the user votes for a new product type
   * @param {String} email is the email of the logged in user
   * @param {Array} type is the list of selected types
   * @returns A promise of the tracking event
   */
  trackNewProductTypeVoted: (email, type) =>
    AnalyticsService.track({
      event: Events.NEW_PRODUCT_TYPE_VOTED,
      props: {
        email,
        type,
      },
    }),

  /**
   * Tracks when user visited /plans
   * @param {String} location that was triggered
   * @returns A promise of the tracking event
   */
  trackPlanListViewed: location =>
    AnalyticsService.track({
      event: Events.PLAN_LIST_VIEWED,
      props: {
        location,
      },
    }),

  /**
   * Tracks whwn user created a funnel
   * @param {String} funnel_model_name the name of the funnel template
   * @param {String} funnel_id the id of the funnel
   * @param {String} location that was triggered
   * @returns A promise of the tracking event
   */
  trackFunnelCreated: (funnel_model_name, funnel_id, location) =>
    AnalyticsService.track({
      event: Events.FUNNEL_CREATED,
      props: {
        funnel_model_name,
        funnel_id,
        location,
      },
    }),

  /**
   * Tracks when user selects objectives of its funnel
   * @param {Array} funnel_objective_choice objectives selected converted to pt-br name
   * @param {String} name name of the current user
   * @param {Number} user_id id of the current user
   * @returns {Promise} A promise of the tracking event
   */
  trackObjectiveSelection: (funnel_objective_choice, name, user_id) =>
    AnalyticsService.track({
      event: Events.FUNNEL_SELECTED_OBJECTIVES,
      props: {
        funnel_objective_choice,
        name,
        user_id,
      },
    }),

  /**
   * Tracks when checkout link was copied
   * @param {String} location - View of checkout link was clicked
   */
  trackProductCoverUpdated: location =>
    AnalyticsService.track({
      event: Events.PRODUCT_COVER_UPDATED,
      props: {
        location,
      },
    }),

  /**
   * Tracks when faq page visited
   * @returns A promise of the tracking event
   */
  trackFAQPageViewed: () =>
    AnalyticsService.track({
      event: Events.FAQ_PAGE_VIEWED,
      props: {},
    }),

  /**
   * Tracks when community page visited
   * @returns A promise of the tracking event
   */
  trackCommunityPageViewed: () =>
    AnalyticsService.track({
      event: Events.COMMUNITY_PAGE_VIEWED,
      props: {},
    }),
  trackCommunityOpenForm: () =>
    AnalyticsService.track({
      event: Events.COMMUNITY_PAGE_OPEN_FORM,
    }),
  trackCommunitySendForm: () =>
    AnalyticsService.track({
      event: Events.COMMUNITY_PAGE_SEND_FORM,
    }),

  /**
   * Tracks when product creating it was initiated
   * @param {String} location the location where it was triggered
   * @returns A promise of the tracking event
   */
  trackProductCreationInitiated: location =>
    AnalyticsService.track({
      event: Events.PRODUCT_CREATION_INITIATED,
      props: {
        location,
      },
    }),

  /**
   * Tracks when funnel creating it was initiated
   * @param {String} location the location where it was triggered
   * @returns A promise of the tracking event
   */
  trackFunnelCreationInitiated: location =>
    AnalyticsService.track({
      event: Events.FUNNEL_CREATION_INITIATED,
      props: {
        location,
      },
    }),

  /**
   * Tracks when funnel creating it was initiated
   * @param {String} name of the selected banner
   * @returns A promise of the tracking event
   */
  trackHomeBannerDetailDisplayed: name =>
    AnalyticsService.track({
      event: Events.HOME_BANNER_DETAIL_DISPLAYED,
      props: {
        name,
      },
    }),
  /**
   * Tracks when a bank account is created
   * @param {String} name of the selected banner
   * @returns A promise of the tracking event
   */
  bankAccountCreated: account =>
    AnalyticsService.track({
      event: Events.BANK_ACCOUNT_CREATED,
      props: account,
    }),
  /**
   * Tracks paywall displayed
   * @param {String} location - Location
   * @param {String} paywallReason - Paywall reason
   * @param {String} product - Product type
   * @param {String} email - Email
   * @param {Number} schoolId - School Id
   * @param {Number} school_id - School Id
   */
  trackPaywallDisplayed: (location, paywallReason, email, schoolId, school_id) =>
    AnalyticsService.track({
      event: Events.PAYWALL_DISPLAYED,
      props: {
        location,
        paywallReason,
        product: 'herospark',
        email,
        schoolId,
        school_id,
      },
    }),
  /**
   * Tracks if user enabled checkout banner feature on product create/edit
   */
  trackCheckoutBannerEnable: offerId =>
    AnalyticsService.track({
      event: Events.CHECKOUT_BANNER_ENABLED,
      props: { offer_id: offerId },
    }),
  /**
   * Tracks if user enabled facebook pixel feature on product create/edit
   */
  trackFacebookPixelEnable: () =>
    AnalyticsService.track({
      event: Events.FACEBOOK_PIXEL_ENABLED,
      props: {},
    }),
  /**
   * Tracks if user enabled google analytics feature on product create/edit
   */
  trackGoogleAnalyticsEnable: () =>
    AnalyticsService.track({
      event: Events.GOOGLE_ANALYTICS_ENABLED,
      props: {},
    }),
  /**
   * Tracks if user enabled google ads feature on product create/edit
   */
  trackGoogleAdsEnable: () =>
    AnalyticsService.track({
      event: Events.GOOGLE_ADS_ENABLED,
      props: {},
    }),
  /**
   * Tracks if user enabled whatsapp widget on product create/edit
   * @param {string, LocaleMessage} whatsappText - Whatsapp Text
   */
  trackWhatsappWidgetEnable: whatsappText =>
    AnalyticsService.track({
      event: Events.WHATSAPP_WIDGET_ENABLED,
      props: {
        message: whatsappText,
      },
    }),
  /**
   * Tracks when user visited /plan
   */
  trackSubdomainEdited: () =>
    AnalyticsService.track({
      event: Events.SUBDOMAIN_EDITED,
      props: {},
    }),
  /**
   * Tracks when a client chooses a plan
   * @param {String} location From where we are calling
   * @param {String} reason is what probably what motivated the interest
   * @returns A promise of the tracking event
   */
  trackLiveClassInterested: (location, reason) =>
    AnalyticsService.track({
      event: Events.LIVE_CLASS_INTERESTED,
      props: {
        location,
        reason,
      },
    }),
  /**
   * Tracks when a client chooses a plan
   * @param {String} location From where we are calling
   * @param {String} planSlug The slug of the plan
   * @returns A promise of the tracking event
   */
  planSelected: (location, planSlug) =>
    AnalyticsService.track({
      event: Events.PLAN_SELECTED,
      props: { location, plan: planSlug },
    }),

  /**
   * Tracks when filter and search members on MemberList page
   * @param {String} user_email Logged user email
   * @param {Number} school_id School id
   * @param {Number} member_count Number of selected members
   * @param {Array} filters List of selected filters
   * @returns A promise of the tracking event
   */
  trackMemberSearched: (user_email, school_id, member_count, filters) =>
    AnalyticsService.track({
      event: Events.MBM_SEARCHED,
      props: {
        user_email,
        school_id,
        member_count,
        filters,
      },
    }),

  /**
   * Tracks when send a message from MemberList page
   * @param {String} location From where we are calling
   * @param {String} user_email Logged user email
   * @param {Number} school_id School id
   * @param {Number} member_count Number of selected members
   * @param {Boolean} action If have attachment files or not
   * @returns A promise of the tracking event
   */
  trackMemberMessageSent: (location, user_email, school_id, member_count, action) =>
    AnalyticsService.track({
      event: Events.MBM_MESSAGE_SENT,
      props: {
        location,
        user_email,
        school_id,
        member_count,
        action,
      },
    }),

  /**
   * Tracks when enroll a new product to a member
   * @param {String} location From where we are calling
   * @param {String} user_email Logged user email
   * @param {Number} school_id School id
   * @param {Number} member_count Number of members
   * @param {Number} count_product Number of products
   * @returns A promise of the tracking event
   */
  trackMemberEnrolled: (location, user_email, school_id, member_count, count_product, products_type) =>
    AnalyticsService.track({
      event: Events.MBM_ENROLLED,
      props: {
        location,
        user_email,
        school_id,
        member_count,
        count_product,
        products_type,
      },
    }),

  /**
   * Tracks when disable a member's product
   * @param {String} location From where we are calling
   * @param {String} user_email Logged user email
   * @param {Number} school_id School id
   * @param {Number} member_count Number of selected members
   * @param {Number} count_product Number of products
   * @returns A promise of the tracking event
   */
  trackMemberEnrollmentDeactivated: (location, user_email, school_id, member_count, count_product) =>
    AnalyticsService.track({
      event: Events.MBM_ENROLLMENT_DEACTIVATED,
      props: {
        location,
        user_email,
        school_id,
        member_count,
        count_product,
      },
    }),

  /**
   * Tracks when remove member
   * @param {String} location From where we are calling
   * @param {String} user_email Logged user email
   * @param {Number} school_id School id
   * @param {Number} member_count Number of selected members
   * @returns A promise of the tracking event
   */
  trackMemberDeleted: (location, user_email, school_id, member_count) =>
    AnalyticsService.track({
      event: Events.MBM_DELETED,
      props: {
        location,
        user_email,
        school_id,
        member_count,
      },
    }),

  /**
   * Tracks when a new tag is added to a member
   * @param {String} location From where we are calling
   * @param {String} user_email Logged user email
   * @param {Number} school_id School id
   * @param {Number} member_count Number of selected members
   * @param {Number} tag_count Number of selected tags
   * @returns A promise of the tracking event
   */
  trackMemberTagAdded: (location, user_email, school_id, member_count, tag_count) =>
    AnalyticsService.track({
      event: Events.MBM_TAG_ADDED,
      props: {
        location,
        user_email,
        school_id,
        member_count,
        tag_count,
      },
    }),

  /**
   * Tracks when import a new member
   * @param {String} user_email Logged user email
   * @param {Number} school_id School id
   * @param {Number} member_count Number of selected members
   * @param {Number} product_count Number of selected products
   * @param {Array} products_type List of selected types
   * @returns A promise of the tracking event
   */
  trackMemberImported: (user_email, school_id, member_count, product_count, products_type) =>
    AnalyticsService.track({
      event: Events.MBM_IMPORTED,
      props: {
        user_email,
        school_id,
        member_count,
        product_count,
        products_type,
      },
    }),

  /**
   * Tracks when access the member detail page
   * @param {String} user_email Logged user email
   * @param {Number} school_id School id
   * @returns A promise of the tracking event
   */
  trackMemberDetailsOpened: (user_email, school_id) =>
    AnalyticsService.track({
      event: Events.MBM_DETAILS_OPENED,
      props: {
        user_email,
        school_id,
      },
    }),

  affiliationNewProgram: (location, school_id) =>
    AnalyticsService.track({
      event: Events.AFFILIATION_PROGRAM_NEW_PROGRAM,
      props: { location, school_id },
    }),

  affiliationFilledBasicInformation: school_id =>
    AnalyticsService.track({
      event: Events.AFFILIATION_PROGRAM_FILLED_BASIC_INFORMATION,
      props: { school_id },
    }),

  affiliationOfferAdded: (school_id, offersCount, product_id, offer_id) =>
    AnalyticsService.track({
      event: Events.AFFILIATION_PROGRAM_OFFERS_ADDED,
      props: { school_id, offers: offersCount, product_id, offer_id },
    }),

  affiliationFilledCommission: (school_id, product_id, commission) =>
    AnalyticsService.track({
      event: Events.AFFILIATION_PROGRAM_FILLED_COMMISSION,
      props: { school_id, product_id, commission },
    }),

  affiliationFilledExpirationDate: (school_id, expiration_date, date) =>
    AnalyticsService.track({
      event: Events.AFFILIATION_PROGRAM_FILLED_EXPIRATION_DATE,
      props: { school_id, expiration_date, date },
    }),

  affiliationProgramCreated: (school_id, program_id, productsCount, commission) =>
    AnalyticsService.track({
      event: Events.AFFILIATION_PROGRAM_CREATED,
      props: { school_id, program_id, products: productsCount, commission },
    }),

  affiliationProgramDeactivated: (school_id, program_id) =>
    AnalyticsService.track({
      event: Events.AFFILIATION_PROGRAM_DEACTIVATED,
      props: { school_id, program_id },
    }),

  affiliationProgramActivated: (school_id, program_id) =>
    AnalyticsService.track({
      event: Events.AFFILIATION_PROGRAM_ACTIVATED,
      props: { school_id, program_id },
    }),

  affiliateFacebookPixelAdded: school_id =>
    AnalyticsService.track({
      event: Events.AFFILIATE_FACEBOOK_PIXEL_ADDED,
      props: { school_id },
    }),

  affiliateInvitationStarted: (school_id, location) =>
    AnalyticsService.track({
      event: Events.AFFILIATE_INVITATION_STARTED,
      props: { school_id, location },
    }),

  affiliateInviteSent: (school_id, program_id, affiliate_name, affiliate_email) =>
    AnalyticsService.track({
      event: Events.AFFILIATE_INVITED_SENT,
      props: { school_id, program_id, affiliate_name, affiliate_email },
    }),

  affiliateAffiliationDeactivated: school_id =>
    AnalyticsService.track({
      event: Events.AFFILIATE_AFFILIATION_DEACTIVATED,
      props: { school_id },
    }),

  affiliateAffiliationActivated: school_id =>
    AnalyticsService.track({
      event: Events.AFFILIATE_AFFILIATION_ACTIVATED,
      props: { school_id },
    }),

  affiliationOfferLinkCopied: school_id =>
    AnalyticsService.track({
      event: Events.AFFILIATION_OFFER_LINK_COPIED,
      props: {},
    }),

  affiliationInviteAccepted: school_id =>
    AnalyticsService.track({
      event: Events.AFFILIATION_INVITED_ACCEPTED,
      props: { school_id },
    }),
  trackCoproductionDeleted: (school_id, product_name, product_type, commission) =>
    AnalyticsService.track({
      event: Events.COPRODUCTION_DELETE,
      props: {
        school_id,
        product_name,
        product_type,
        commission,
      },
    }),
  trackEnotasConfigEnabled: () =>
    AnalyticsService.track({
      event: Events.ENOTAS_INTEGRATION_ENABLED,
      props: {},
    }),
  trackCoproductionCreated: (school_id, product_name, product_type, commission) =>
    AnalyticsService.track({
      event: Events.COPRODUCTION_CREATED,
      props: {
        school_id,
        product_name,
        product_type,
        commission,
      },
    }),
  trackAutomationSelectModel: (location_trigger, automation_id) =>
    AnalyticsService.track({
      event: Events.AUTOMATIONSELECTMODEL,
      props: {
        location: location_trigger,
        automation_id,
      },
    }),
  trackAutomationActive: (location, automation_id) =>
    AnalyticsService.track({
      event: Events.AUTOMATIONACTIVE,
      props: {
        location: location,
        automation_id,
      },
    }),

  trackAutomationEdit: (type, method_choice, automation_id) =>
    AnalyticsService.track({
      event: Events.AUTOMATIONEDIT,
      props: {
        type_automation: type,
        method_choice: method_choice,
        automation_id,
      },
    }),

  trackAutomationModalType: (type, automation_id) =>
    AnalyticsService.track({
      event: Events.AUTOMATIMODALTYPE,
      props: {
        automation_type: type,
        automation_id,
      },
    }),

  trackAutomationAddHeader: (school_id, automation_id) =>
    AnalyticsService.track({
      event: Events.AUTOMATIONADDHEADER,
      props: {
        school_id: school_id,
        automation_id,
      },
    }),

  trackAutomationMethodUsed: (method_choice, automation_id) =>
    AnalyticsService.track({
      event: Events.AUTOMATIONMETHODUSED,
      props: {
        method_choice: method_choice,
        automation_id,
      },
    }),

  trackAutomationDelete: (type_automation, automation_id) =>
    AnalyticsService.track({
      event: Events.AUTOMATIONDELETE,
      props: {
        type_automation: type_automation,
        automation_id,
      },
    }),

  trackAutomationUseVariable: (type_variable, automation_type, automation_id) =>
    AnalyticsService.track({
      event: Events.AUTOMATIONUSEVARIABLE,
      props: {
        type_variable: type_variable,
        automation_type: automation_type,
        automation_id,
      },
    }),

  trackOfferRequirePhoneEnabled: offerId =>
    AnalyticsService.track({
      event: Events.OFFER_REQUIRE_PHONE_ENABLED,
      props: { offer_id: offerId },
    }),

  trackOfferRequireAddressEnabled: offerId =>
    AnalyticsService.track({
      event: Events.OFFER_REQUIRE_ADDRESS_ENABLED,
      props: { offer_id: offerId },
    }),

  trackOfferCustomThanksPageEnabled: offerId =>
    AnalyticsService.track({
      event: Events.OFFER_CUSTOM_THANKS_PAGE_ENABLED,
      props: { offer_id: offerId },
    }),

  trackNewThemeActive: () =>
    AnalyticsService.track({
      event: Events.NEWTHEMEACTIVE,
    }),

  trackStudentAreBannerSend: (location, format, link) =>
    AnalyticsService.track({
      event: Events.STUDENTAREABANNERUPDATE,
      props: {
        location: location,
        format: format,
        link: link,
      },
    }),

  trackOrderBumpCreated: (productId, offerId, offerName, itemsCount) =>
    AnalyticsService.track({
      event: Events.ORDER_BUMP_CREATED,
      props: {
        items_count: itemsCount,
        product_id: productId,
        offer_id: offerId,
        offer_name: offerName,
      },
    }),

  trackOrderBumpUpdated: (productId, offerId, itemsCount) =>
    AnalyticsService.track({
      event: Events.ORDER_BUMP_UPDATED,
      props: {
        items_count: itemsCount,
        product_id: productId,
        offer_id: offerId,
      },
    }),

  trackOrderBumpLinkCopied: (productId, offerId, itemsCount) =>
    AnalyticsService.track({
      event: Events.ORDER_BUMP_LINK_COPIED,
      props: {
        items_count: itemsCount,
        product_id: productId,
        offer_id: offerId,
      },
    }),

  trackOrderBumpDeleted: (productId, offerId, itemsCount) =>
    AnalyticsService.track({
      event: Events.ORDER_BUMP_DELETED,
      props: {
        items_count: itemsCount,
        product_id: productId,
        offer_id: offerId,
      },
    }),

  trackOrderBumpEnabled: (productId, offerId, itemsCount) =>
    AnalyticsService.track({
      event: Events.ORDER_BUMP_ENABLED,
      props: {
        items_count: itemsCount,
        product_id: productId,
        offer_id: offerId,
      },
    }),

  trackOrderBumpDisabled: (productId, offerId, itemsCount) =>
    AnalyticsService.track({
      event: Events.ORDER_BUMP_DISABLED,
      props: {
        items_count: itemsCount,
        product_id: productId,
        offer_id: offerId,
      },
    }),
};
