import store from '@/store';

export default {
  path: '/my-account',
  name: 'MyAccount',
  redirect: { name: 'MyAccountDashboard' },
  component: () => import('@/views/EmptyRouterView.vue'),
  children: [
    {
      path: '',
      name: 'MyAccountDashboard',
      component: () => import(/* webpackChunkName: "my-account" */ '@/myspark/views/Dashboard'),
      meta: { title: `Minha Conta – ${process.env.VUE_APP_APPLICATION_TITLE}` },
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/hasPermission']('core_my_account_can_read_my_account')) next();
        else next({ name: 'ProductsList' });
      },
    },
    {
      path: 'personal',
      name: 'MyAccountPersonalData',
      component: () => import(/* webpackChunkName: "my-account" */ '@/myspark/views/PersonalData'),
      meta: { title: `Dados Pessoais – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
    {
      path: 'settings',
      name: 'MyAccountSettings',
      component: () => import(/* webpackChunkName: "my-account" */ '@/myspark/views/AccountSettings'),
      meta: { title: `Configurações – ${process.env.VUE_APP_APPLICATION_TITLE}` },
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/hasPermission']('core_my_account_can_manage_account')) next();
        else next({ name: 'MyAccountDashboard' });
      },
    },
    {
      path: 'domains',
      name: 'MyAccountDomains',
      component: () => import(/* webpackChunkName: "my-account" */ '@/myspark/views/Domains'),
      meta: { title: `Domínios – ${process.env.VUE_APP_APPLICATION_TITLE}` },
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/hasPermission']('core_my_account_can_manage_dominio')) next();
        else next({ name: 'MyAccountDashboard' });
      },
    },
    {
      path: 'theme',
      name: 'MyAccountTheme',
      component: () => import('@/myspark/views/Theme'),
      meta: { title: `Temas – ${process.env.VUE_APP_APPLICATION_TITLE}` },
      beforeEnter: (to, from, next) => {
        if (
          store.getters['auth/hasPermission']('core_my_account_can_manage_theme') &&
          store.getters['school/hasFeature']('active_theme_view')
        )
          next();
        else next({ name: 'MyAccountDashboard' });
      },
    },
    {
      path: 'plan',
      name: 'MyAccountPlan',
      component: () => import(/* webpackChunkName: "my-account" */ '@/myspark/views/Plan'),
      meta: { title: `Meu Plano – ${process.env.VUE_APP_APPLICATION_TITLE}` },
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/hasPermission']('heimdall_my_account_can_manage_plan')) next();
        else next({ name: 'MyAccountDashboard' });
      },
    },
    {
      path: 'plans',
      name: 'MyAccountPlans',
      component: () => import(/* webpackChunkName: "my-account" */ '@/myspark/views/PlanListing'),
      meta: { title: `Planos – ${process.env.VUE_APP_APPLICATION_TITLE}` },
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/hasPermission']('heimdall_my_account_can_manage_plan')) next();
        else next({ name: 'MyAccountDashboard' });
      },
    },
    {
      path: 'invoices',
      name: 'MyAccountPlanInvoices',
      component: () => import(/* webpackChunkName: "my-account" */ '@/myspark/views/PlanInvoices'),
      meta: { title: `Histórico – ${process.env.VUE_APP_APPLICATION_TITLE}` },
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/hasPermission']('heimdall_my_account_can_manage_plan')) next();
        else next({ name: 'MyAccountDashboard' });
      },
    },
    {
      path: 'change-plan',
      name: 'MyAccountDowngradePlan',
      component: () => import(/* webpackChunkName: "my-account" */ '@/myspark/views/PlanDowngrade'),
      meta: { title: `Alterar Plano – ${process.env.VUE_APP_APPLICATION_TITLE}` },
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/hasPermission']('heimdall_my_account_can_manage_plan')) next();
        else next({ name: 'MyAccountDashboard' });
      },
    },
    {
      path: 'my-team',
      name: 'MyTeam',
      component: () => import(/* webpackChunkName: "my-account" */ '@/myspark/views/MyTeam'),
      meta: { title: `Sua equipe – ${process.env.VUE_APP_APPLICATION_TITLE}` },
      beforeEnter: (to, from, next) => {
        if (
          store.getters['school/hasFeature']('hero_admin_users') &&
          store.getters['auth/hasPermission']('core_my_account_can_manage_team')
        )
          next();
        else next({ name: 'MyAccountDashboard' });
      },
      children: [
        {
          path: 'roles-and-permissions',
          name: 'RolesAndPermissions',
          component: () => import(/* webpackChunkName: "permissions" */ '@/myspark/views/MyTeam/RolesAndPermissions'),
          meta: { title: `Papéis e Permissões – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
      ],
    },
    {
      path: 'vitrine',
      name: 'Vitrine',
      component: () => import(/* webpackChunkName: "my-account" */ '@/myspark/views/Vitrine'),
      meta: { title: `Página de vitrine – ${process.env.VUE_APP_APPLICATION_TITLE}` },
      beforeEnter: (to, from, next) => {
        if (
          store.getters['school/hasFeature']('active_showcase_hero') &&
          store.getters['auth/hasPermission']('core_my_account_can_manage_vitrine')
        )
          next();
        else next({ name: 'MyAccountDashboard' });
      },
    },
    {
      path: 'student-area',
      name: 'AreaAluno',
      component: () => import(/* webpackChunkName: "student-area" */ '@/myspark/views/StudentArea'),
      meta: { title: `Área do aluno– ${process.env.VUE_APP_APPLICATION_TITLE}` },
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/hasPermission']('core_my_account_can_manage_student_area')) next();
        else next({ name: 'MyAccountDashboard' });
      },
    },
    {
      path: 'global-coupon',
      name: 'GlobalCoupon',
      component: () => import(/* webpackChunkName: "global-coupon" */ '@/myspark/views/GlobalCoupon'),
      meta: { title: `Cupons globais – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
  ],
};
