import { leadApi } from '@/services';

export default {
  getLeads({ commit }, { currentPage, perPage, query }) {
    return leadApi.getLeads({ currentPage, perPage, query }).then(({ data }) => {
      commit('setLeads', data.leads);
      return data;
    });
  },
  deleteLeads({ commit }, leadsIds) {
    return leadApi.deleteLeads(leadsIds).then(() => {
      commit('deleteLeads', leadsIds);
    });
  },
};
