import httpClient from '@/services/sparkecommerce/http_client.js';

export default {
  getOrderBump: orderBumpId => {
    return httpClient.get(`order_bumps/${orderBumpId}`).then(({ data }) => data);
  },
  all: (coreProductId, page, itemsPerPage) => {
    return httpClient
      .get('order_bumps', {
        params: {
          core_product_id: coreProductId,
          page: page,
          items: itemsPerPage,
        },
      })
      .then(({ data, headers }) => ({ data, headers }));
  },
  create: payload => {
    return httpClient.post('order_bumps', payload);
  },
  update: (orderBumpId, payload) => {
    return httpClient.put(`order_bumps/${orderBumpId}`, payload);
  },
  delete: orderBumpId => {
    return httpClient.delete(`order_bumps/${orderBumpId}`);
  },
  enable: orderBumpId => {
    return httpClient.post(`order_bumps/${orderBumpId}/enable`);
  },
  disable: orderBumpId => {
    return httpClient.post(`order_bumps/${orderBumpId}/disable`);
  },
};
