import store from '@/store';
import { analyticsService } from '@/services';
import inviteService from '@/sparkaffiliates/services/invite';
import { SparkProducts } from '@/types/spark_products';

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/auth/views/Login'),
    meta: { title: `Login – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    beforeEnter: async (to, from, next) => {
      const planReferral = to.query['plan-referral'];
      if (planReferral) {
        window.sessionStorage.setItem('plan-referral', planReferral);
        ['consultor-advanced', 'consultor-personalizado'].includes(planReferral) &&
          analyticsService.track({
            event: 'asked for contact',
            props: {},
          });
      }

      const isAuthenticated = store.getters['auth/isAuthenticated'];

      const { product, inviteCode } = to.query;

      if (inviteCode) {
        // NOTE: if we are authenticated
        // we must consume the invite
        // and forward to the correct page...
        //
        // If we are not authenticated we store
        // the invite information to be consumed later,
        // either after the user answered the questionairy
        // or the login enters...
        if (isAuthenticated) {
          const { selectedSchool } = store.state.school;

          await inviteService.acceptInvite(
            inviteCode,
            selectedSchool.account_type,
            selectedSchool.id,
            selectedSchool.email,
            selectedSchool.name
          );

          const redirectTo = product == SparkProducts.SPARK_AFFILIATES ? 'SparkAffiliatesMainPanel' : 'HomePage';
          next({ name: redirectTo });
        } else {
          window.sessionStorage.setItem(
            'invite-code',
            JSON.stringify({
              inviteCode,
              product,
            })
          );
        }
      }

      if (isAuthenticated) {
        next({ ...to, name: 'MenuHeaderLayout' });
      } else {
        next();
      }
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { title: `Logout – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    beforeEnter: (to, from, next) => {
      store.dispatch('auth/logout');
      analyticsService.reset();
      next({ name: 'Login' });
    },
  },
  {
    path: '/sso',
    name: 'SSO',
    meta: { title: `SSO – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    beforeEnter: async (to, from, next) => {
      const { school_id, email, credentials, route, super_admin_id } = to.query;
      await store.dispatch('auth/sso', {
        school_id,
        email,
        credentials,
        super_admin_id,
      });
      next({ name: route || 'MenuHeaderLayout' });
    },
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('@/auth/views/SignUp'),
    meta: { title: `Criar Conta – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    beforeEnter: (to, from, next) => {
      const planReferral = to.query['plan-referral'];
      if (planReferral) {
        window.sessionStorage.setItem('plan-referral', planReferral);
        ['consultor-advanced', 'consultor-personalizado'].includes(planReferral) &&
          analyticsService.track({
            event: 'asked for contact',
            props: {},
          });
      }

      if (to.query.inviteCode) {
        window.sessionStorage.setItem(
          'invite-code',
          JSON.stringify({
            product: to.query.product,
            inviteCode: to.query.inviteCode,
          })
        );
      }

      if (store.getters['auth/isAuthenticated'] && !to.params.forceQuestionary) {
        next({ name: 'MenuHeaderLayout' });
      } else {
        next({ params: { forceQuestionary: to.params.forceQuestionary || false } });
      }
    },
  },
  {
    path: '/reset-password',
    component: () => import('@/views/EmptyRouterView.vue'),
    meta: { title: `Resetar Senha – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    children: [
      {
        path: '',
        name: 'RecoverPasswordEmail',
        component: () => import('@/auth/views/RecoverPassword/StepEmail'),
      },
      {
        path: ':token/:subDomain',
        name: 'RecoverPasswordConfirmPassword',
        component: () => import('@/auth/views/RecoverPassword/StepPassword'),
        props: true,
      },
    ],
  },
  {
    path: '/lp-sign-up',
    name: 'SignUpForAds',
    component: () => import('@/auth/views/SignUpForAds'),
    meta: { title: `Criar Conta – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    beforeEnter: (to, from, next) => {
      const planReferral = to.query['plan-referral'];
      if (planReferral) {
        window.sessionStorage.setItem('plan-referral', planReferral);
        ['consultor-advanced', 'consultor-personalizado'].includes(planReferral) &&
          analyticsService.track({
            event: 'asked for contact',
            props: {},
          });
      }

      if (store.getters['auth/isAuthenticated'] && !to.params.forceQuestionary) {
        next({ name: 'MenuHeaderLayout' });
      } else {
        next({ params: { forceQuestionary: to.params.forceQuestionary || false } });
      }
    },
  },
  {
    path: '/invite/:token/:subDomain',
    name: 'InviteValidation',
    component: () => import('@/auth/views/Invite/Validation'),
    meta: { title: `Invite – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    props: true,
  },
];
