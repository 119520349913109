import axiosXavier from '@/services/axios';
import generalHelper from '@/shared/helpers/general';

export default {
  invite(invitationToken, realmId, realmType, password, passwordConfirmation) {
    return axiosXavier.put('invitations', {
      invitation: {
        invitation_token: invitationToken,
        realm_id: realmId,
        realm_type: realmType,
        password,
        password_confirmation: passwordConfirmation,
      },
    });
  },
  createInvite(firstName, lastName, email, roleId, avatar) {
    return axiosXavier.post('invitations', {
      invitation: {
        see_all: true,
        type: 'Collaborator',
        first_name: firstName,
        last_name: lastName,
        email,
        role_ids: [roleId],
        cover_image_url: avatar,
      },
    });
  },
  getRoles() {
    return axiosXavier.get('roles?only_herospark_roles=true');
  },
  login(email, password, schoolId) {
    return axiosXavier.post('users/sign_in', {
      realm: { id: schoolId, type: 'School' },
      user: { email, password },
    });
  },
  sso({ school_id, email, credentials, refresh, super_admin_id }) {
    return axiosXavier
      .get('sso', {
        params: {
          school_id,
          email,
          credentials,
          refresh,
          super_admin_id,
        },
        headers: {
          Authorization: process.env.VUE_APP_TOKEN,
        },
      })
      .then(res => res.data)
      .catch(error => Promise.reject(error));
  },
  signUp: ({
    name,
    email,
    password,
    phone,
    tapfiliate_referrer_code,
    tapfiliate_must_send_referral,
    sms_token,
    sms_token_id,
    ddi,
    cpf,
    cnpj,
  }) =>
    axiosXavier.post(
      'schools/wizard',
      {
        school: {
          name,
          phone,
          email,
          password,
          spark_funnel: true,
          terms_of_use_consense: true,
          tapfiliate_referrer_code,
          tapfiliate_must_send_referral,
          herospark: true,
          sms_token,
          sms_token_id,
          ddi,
          cpf,
          cnpj,
        },
      },
      {
        headers: {
          Authorization: process.env.VUE_APP_TOKEN,
        },
      }
    ),
  recoverPassword({ email, schoolId }) {
    return axiosXavier.post(`users/password`, {
      realm: { id: schoolId, type: 'School' },
      user: { email },
    });
  },
  updatePassword({ school, password, token }) {
    return axiosXavier.put(`users/password`, {
      realm: school,
      user: {
        password,
        password_confirmation: password,
        reset_password_token: token,
      },
    });
  },
  getChannelsByUser: (userId, params = []) =>
    axiosXavier
      .get(`users/${userId}/channels?${generalHelper.parseToQueryString(params)}`)
      .then(res => res.data)
      .catch(err => Promise.reject(err)),
};
