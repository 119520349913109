import { PostPurchasePage } from '@/libs/upsell';

export default {
  isUpsellSelected(state) {
    return state.selectedOption === PostPurchasePage.UPSELL;
  },
  isThanksPageSelected(state) {
    return state.selectedOption === PostPurchasePage.THANKS_PAGE;
  },
};
