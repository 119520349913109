import axiosXavier from './axios';
import GeneralHelper from '@/shared/helpers/general';
import { banks } from '@/data/banks';

export default {
  getGateway(id, params) {
    return axiosXavier
      .get(`schools/${id}/gateway_credentials?${GeneralHelper.parseToQueryString(params)}`)
      .then(res => res.data);
  },
  createGateway(id, params) {
    return axiosXavier.post(`schools/${id}/gateway_credentials`, params).then(res => res.data);
  },
  updateGateway(params) {
    return axiosXavier.put(`gateway_credentials/${params.id}`, params).then(res => res.data);
  },
  withdraw(id, params) {
    return axiosXavier.post(`gateway_credentials/${id}/withdrawal`, params).then(res => res.data);
  },
  getBank(code) {
    // http://www.bcb.gov.br/pom/spb/estatistica/port/ParticipantesSTRport.csv
    return code ? banks.filter(({ bank_code }) => bank_code === code)[0] : banks;
  },
  getBankByCode(code) {
    const bank = banks.find(bank => bank.bank_code == code);
    if (!bank) throw new TypeError('There is no bank with code:' + code);
    return bank;
  },
};
