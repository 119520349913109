import httpClient from '@/services/sparkecommerce/http_client.js';

export default {
  getRequests: (export_type, user_email) => {
    const params = {
      export_type,
      user_email,
    };

    return httpClient.get('report_export_requests', { params }).then(({ data }) => data);
  },
};
