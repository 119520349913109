import axiosXavier from '../../services/axios';
import axios from 'axios';
import GeneralHelper from '@/shared/helpers/general';
import orderService from '@/services/order';

export default {
  getStudents(params) {
    return axiosXavier.get(`students?${GeneralHelper.parseToQueryString(params)}`);
  },

  getStudent: id => axiosXavier.get(`students/${id}`),

  updateStudent(student) {
    return axiosXavier.put(`students/${student.id}`, { user: student });
  },

  deleteStudent: id => axiosXavier.delete(`students/${id}`),

  deleteStudents(ids) {
    try {
      const requests = ids.map(id => this.deleteStudent(id));
      return axios.all(requests);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  password: (user, school) => axiosXavier.post('users/password', { realm: school, user }).then(res => res.data),

  analitycs: id => {
    const enrollmentParams = [{ key: 'student_id', value: id }, { key: 'per_page', value: 6 }];
    const ordersParams = [{ key: 'student_id', value: id }, { key: 'per_page', value: 20 }];
    return axios
      .all([
        axiosXavier
          .get(`students/${id}/lessons_progresses`)
          .then(res => ({ lessons_progresses: res.data.lessons_progresses })),
        axiosXavier.get(`enrollments?${GeneralHelper.parseToQueryString(enrollmentParams)}`).then(res => ({
          enrollments: res.data.enrollments,
          enrollmentsPageData: {
            total_count: res.data.total_count,
            current_page: res.data.current_page,
          },
        })),
        orderService.getUnitaryOrders(ordersParams).then(res => ({ orders: res.orders })),
        axiosXavier.get(`school/forums/sections/users/${id}/topics`).then(res => ({ topics: res.data.topics })),
      ])
      .then(res => {
        let result = {};
        res.map(data => Object.keys(data).forEach(value => (result[value] = data[value])));
        return result;
      });
  },

  createInvite: invitation => axiosXavier.post('invitations', invitation),

  getUserCategories: params =>
    axiosXavier.get(`users/categories?${GeneralHelper.parseToQueryString(params)}`).then(res => res.data),

  createUserCategory: user_category => axiosXavier.post('users/categories', { user_category }),

  updateUserCategory: user_category => axiosXavier.put(`users/categories/${user_category.id}`, { user_category }),

  deleteUsersCategory: category_id => axiosXavier.delete(`users/categories/${category_id}`),

  updateUsersCategories: (category_ids, user_ids) => {
    return axiosXavier.post(`users/categories/bulk_insert`, { category_ids, user_ids });
  },
};
