import debug from 'debug';

const logging = debug('hs:chat');

const ZENDESK_URL = 'https://static.zdassets.com/ekr/snippet.js?key=';

const { VUE_APP_ZENDESK_KEY } = process.env;

const ZENDESK_WIDGET_URL = `${ZENDESK_URL}${VUE_APP_ZENDESK_KEY}`;

let initialized = false;
let forceChat = false;
let identified = false;

const defaultSettings = {
  webWidget: {
    chat: { suppress: false },
    helpCenter: { suppress: false },
  },
};

export default class ZendeskService {
  static insertScript() {
    return new Promise((resolve, reject) => {
      const node = document.createElement('script');
      node.id = 'ze-snippet';
      document.body.appendChild(node);
      function onError() {
        logging('failed to load the script');
        reject();
      }
      try {
        node.src = ZENDESK_WIDGET_URL;
        node.onload = function() {
          logging('script loaded');
          resolve();
        };
        node.onerror = onError;
      } catch (e) {
        onError();
      }
    });
  }

  static configure() {
    logging('configured');

    window.zESettings = defaultSettings;
  }

  static alreadyInitialized() {
    return initialized;
  }

  static initialize() {
    logging('initializing...');

    initialized = true;

    ZendeskService.configure();
    return ZendeskService.insertScript().then(() => {
      // try to clear by force any previous session.
      ZendeskService.clearSession();

      zE('webWidget:on', 'chat:start', function() {
        logging('chat started');
      });

      zE('webWidget:on', 'chat:connected', function() {
        logging('chat connected');
      });

      zE('webWidget:on', 'close', function() {
        logging('chat closed.');

        forceChat = false;
        ZendeskService.updateSettings(defaultSettings);
      });
    });
  }

  // NOTE: you must identify the user
  // before it tries to connect
  // otherwise, it won't fill the form.
  // check out the zendesk's form
  // prefill for a different behavior.
  static tryToInitialize() {
    return ZendeskService.alreadyInitialized()
      ? (logging('already initialized'), Promise.resolve())
      : ZendeskService.initialize().then(() => {
          // NOTE: this logout is for clean up
          return ZendeskService.logout(true);
        });
  }

  static addTags(tags) {
    zE('webWidget', 'chat:addTags', tags);
  }

  static removeTags(tags) {
    zE('webWidget', 'chat:removeTags', tags);
  }

  static show() {
    logging('show widget.');
    zE('webWidget', 'show');
  }

  static hide() {
    logging('hiding widget.');
    zE('webWidget', 'hide');
  }

  static open(message = '') {
    logging('open widget.');
    zE('webWidget', 'open');
    if (message) zE('webWidget', 'chat:send', message);
  }

  static updateSettings(settings) {
    logging('rewrite settings.');
    zE('webWidget', 'updateSettings', settings);
  }

  static openChatOnSales() {
    logging('opening chat on sales.');
    ZendeskService.openOnChat('Consultor comercial', 'Vendas');
  }

  static openOnChat(title, department) {
    logging('forceably open chat.');
    forceChat = true;
    const departments = Boolean(department) && {
      select: department,
    };

    const message =
      department === 'Vendas' ? 'Olá, gostaria de falar com um consultor' : 'Olá, gostaria de falar com o suporte';

    const onlyChat = {
      webWidget: {
        chat: {
          suppress: false,
          title: {
            '*': title || 'Atendimento',
          },
          departments,
        },
        helpCenter: {
          suppress: true,
        },
      },
    };
    logging('chatting with settings', onlyChat);
    ZendeskService.updateSettings(onlyChat);
    ZendeskService.open(message);
  }

  static identify(name, email) {
    return ZendeskService.tryToInitialize()
      .then(() => {
        ZendeskService.show();

        if (identified) {
          return Promise.reject();
        }

        logging('identify as', email);
        identified = true;
        zE('webWidget', 'identify', { name, email });
      })
      .catch(() => logging('already identified.'));
  }

  static clearSession() {
    logging('clean session');
    zE('webWidget', 'clear');
  }

  static logout(verbose = true) {
    return new Promise(resolve => {
      if (ZendeskService.alreadyInitialized()) {
        verbose && logging('logging out');
        zE('webWidget', 'logout');
        ZendeskService.clearSession();
        ZendeskService.hide();
        identified = false;
      } else {
        logging('not ready yet');
      }
      resolve();
    });
  }
}

window.Z = ZendeskService;
