import { studentService } from '@/services';

export default {
  getMembers({ commit }, params) {
    return studentService.getStudents(params).then(({ data }) => {
      commit('getMembers', data.students);
      return data;
    });
  },

  async getMember({ commit }, memberId) {
    const { data } = await studentService.getStudent(memberId);
    commit('selectMember', data);
  },

  selectMember({ commit }, member) {
    commit('selectMember', member);
  },

  updateMember({ commit }, member) {
    return studentService.updateStudent(member).then(() => {
      commit('updateMember', member);
      commit('selectMember', member);
    });
  },

  deleteMembers({ commit }, members) {
    const membersId = members.map(member => member.id);
    return studentService.deleteStudents(membersId).then(() => {
      commit('deleteMembers', members);
    });
  },

  toggleSidebar({ commit }, data) {
    commit('toggleSidebar', data);
  },

  setProducts({ commit }, payload) {
    commit('setProducts', payload);
  },
};
