import { collectRoutesAllowedOnBreadcrumb } from '@/libs/router';
import store from '@/store';
import Vue from 'vue';
import Router from 'vue-router';
import SparkMembersRoutes from '@/sparkmembers/router';
import SparkAffiliatesRoutes from '@/sparkaffiliates/router';
import SparkPayRoutes from '@/sparkpay/router';
import SparkFunnelRoutes from '@/sparkfunnels/router';
import AuthRoutes from '@/auth/router';
import MySparkRoutes from '@/myspark/router';

store.dispatch('auth/authenticate');
Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...AuthRoutes,
    {
      path: '',
      name: 'LoadPage',
      component: () => import('@/views/LoadPage'),
    },
    {
      path: '/',
      name: 'MenuHeaderLayout',
      component: () => import('@/views/MenuHeaderLayout'),
      meta: { requiresAuth: true },
      redirect: { name: 'HomePage' },
      children: [
        {
          path: 'home',
          name: 'HomePage',
          component: () => import('@/views/HomePage/HomePage.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['auth/hasPermission']('core_homepage_can_manage')) next();
            else next({ name: 'ProductsList' });
          },
        },
        {
          path: 'agendamento',
          name: 'SchedulingLandingPage',
          component: () => import('@/views/LandingPage/views/SchedulingLandingPage'),
          meta: { title: `Agendamento com Consultor` },
        },
        {
          path: 'landing-page',
          name: 'LandingPage',
          component: () => import('@/views/LandingPage'),
          redirect: { name: 'SparkMembersLandingPage' },
          children: [
            {
              path: 'sparkfunnels',
              name: 'SparkFunnelsLandingPage',
              component: () => import('@/views/LandingPage/views/SparkFunnelsLandingPage'),
              meta: { title: `SparkFunnels – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'sparkmembers',
              name: 'SparkMembersLandingPage',
              component: () => import('@/views/LandingPage/views/SparkMembersLandingPage'),
              meta: { title: `SparkMembers – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'sparkaffiliates',
              name: 'SparkAffiliatesLandingPage',
              component: () => import('@/views/LandingPage/views/SparkAffiliatesLandingPage'),
              meta: { title: `SparkAffiliates – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
          ],
        },
        {
          path: 'accept-terms',
          name: 'AcceptTerms',
          component: () => import('@/views/AcceptTerms'),
          meta: { title: `Termos – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'questionary-page',
          name: 'QuestionaryPage',
          component: () => import('@/views/QuestionaryPage'),
          meta: { title: `Questionário – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'invite-create',
          name: 'InviteCreate',
          component: () => import('@/auth/views/Invite/Create.vue'),
          meta: { title: `Invite – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: '/messages',
          name: 'Messages',
          redirect: { name: 'MessagesList' },
          component: () => import(/* webpackChunkName: "messages" */ '@/views/Messages'),
          meta: { requiresAcceptedTerms: true },
          beforeEnter: (to, from, next) => {
            if (store.getters['auth/hasPermission']('core_messages_can_manage')) next();
            else next({ name: 'ProductsList' });
          },
          children: [
            {
              path: '',
              name: 'MessagesList',
              component: () => import(/* webpackChunkName: "messages" */ '@/views/Messages/views/List'),
              meta: { title: `Mensagens – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: ':channelId',
              name: 'MessageSubject',
              component: () => import(/* webpackChunkName: "messages" */ '@/views/Messages/views/Subject'),
              meta: { title: `Mensagem – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
          ],
        },
        {
          path: '/faq',
          name: 'FaqPage',
          component: () => import('@/views/ComingSoonPage.vue'),
        },
        {
          path: '/community',
          name: 'CommunityPage',
          component: () => import('@/views/LandingPage/CommunityPage/CommunityPage.vue'),
        },
        { ...SparkFunnelRoutes, meta: { ...SparkFunnelRoutes.meta, requiresAcceptedTerms: true } },
        { ...SparkMembersRoutes, meta: { ...SparkMembersRoutes.meta, requiresAcceptedTerms: true } },
        { ...SparkAffiliatesRoutes, meta: { ...SparkAffiliatesRoutes.meta, requiresAcceptedTerms: true } },
        { ...SparkPayRoutes, meta: { ...SparkPayRoutes.meta, requiresAcceptedTerms: true } },
        { ...MySparkRoutes, meta: { ...MySparkRoutes.meta, requiresAcceptedTerms: true } },
        {
          path: '/automations',
          name: 'Automations',
          redirect: { name: 'AutomationsDashboard' },
          component: () => import('@/views/Automations'),
          meta: { title: `Piloto automático – ${process.env.VUE_APP_APPLICATION_TITLE}` },
          beforeEnter: (to, from, next) => {
            if (
              store.getters['school/hasFeature']('active_automation_hero') &&
              store.getters['auth/hasPermission']('core_automatic_pilot_can_manage')
            )
              next();
            else next({ name: 'ProductsList' });
          },
          children: [
            {
              path: '',
              name: 'AutomationsDashboard',
              component: () =>
                import(/* webpackChunkName: "automations" */ '@/views/Automations/views/AutomationsTabs'),
              meta: { title: `Central de automações – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: ':automationId',
              name: 'AutomationsWebhookEdit',
              redirect: { name: 'AutomationsWebhook' },
              component: () =>
                import(/* webpackChunkName: "automations" */ '@/views/Automations/views/AutomationsEditWebhook'),
              meta: { title: `Editar automação – ${process.env.VUE_APP_APPLICATION_TITLE}` },
              children: [
                {
                  path: 'webhook',
                  name: 'AutomationsWebhook',
                  component: () => import(/* webpackChunkName: "wallet" */ '@/views/Automations/views/AutomationsEdit'),
                  meta: { title: `Editar automação – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                },
                {
                  path: 'logs',
                  name: 'AutomationsLogs',
                  component: () =>
                    import(/* webpackChunkName: "sales" */ '@/views/Automations/views/AutomationsEditWebhook/components/AutomationsLogs.vue'),
                  meta: { title: `Histórico de automações – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                },
                {
                  path: '/',
                  redirect: { name: 'AutomationsEdit' },
                  component: () => import(/* webpackChunkName: "wallet" */ '@/views/Automations/views/AutomationsEdit'),
                  meta: { title: `Editar automação – ${process.env.VUE_APP_APPLICATION_TITLE}` },
                },
              ],
            },
            {
              path: ':automationId',
              name: 'AutomationsEdit',
              component: () =>
                import(/* webpackChunkName: "automations" */ '@/views/Automations/views/AutomationsEdit'),
              meta: { title: `Editar automação – ${process.env.VUE_APP_APPLICATION_TITLE}`, hasHeader: true },
            },
          ],
        },
        {
          path: '/members-area',
          name: 'MembersArea',
          component: () => import('@/views/MembersArea'),
          beforeEnter: (to, from, next) => {
            if (store.getters['auth/hasPermission']('core_my_account_can_manage_student_area')) next();
            else next({ name: 'ProductsList' });
          },
        },
      ],
    },
    {
      path: '/emails',
      name: 'Emails',
      component: () => import('@/views/Email.vue'),
      meta: { title: `Editor de E-mail – ${process.env.VUE_APP_APPLICATION_TITLE}` },
    },
    {
      path: '/unsubscribe',
      name: 'Unsubscribe',
      component: () => import('@/views/Unsubscribe'),
    },
    {
      path: '/livemeets',
      name: 'LiveMeet',
      redirect: { name: 'HomePage' },
      component: () => import(/* webpackChunkName: "livemeet" */ '@/views/LiveMeet/views'),
      meta: { title: `Encontro Ao Vivo` },
      beforeEnter: (to, from, next) => {
        if (store.getters['school/hasFeature']('live_meet_content')) next();
        else next({ name: 'HomePage' });
      },
      children: [
        {
          path: 'testRoom',
          name: 'LiveMeetTestRoom',
          component: () => import(/* webpackChunkName: "livemeet" */ '@/views/LiveMeet/views/TestRoom'),
          meta: { title: `Teste Conteúdo – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: ':liveMeetId',
          name: 'LiveMeetRoom',
          component: () => import(/* webpackChunkName: "livemeet" */ '@/views/LiveMeet/views/Room/index.vue'),
        },
      ],
    },
    {
      path: '*',
      redirect: { name: 'Products' },
    },
  ],
});

const routesDontRequireLogout = ['SignUp', 'Login'];

document.addEventListener('UNAUTHORIZED', function() {
  !routesDontRequireLogout.includes(router.currentRoute.name) &&
    router.push({
      name: 'Logout',
    });
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (store.getters['auth/isAuthenticated']) {
      if (from.name === null) {
        return next({ ...to, name: 'LoadPage', query: { ...to.query, name: to.name } });
      }

      if (verifyTermsOfConsense(to, next)) {
        if (verifyFeatureAccess(to)) {
          if (verifySchoolValidation(to)) {
            setBreadcrumb(to);
            next();
          } else if (store.getters['school/forceValidation']) {
            if (store.getters['auth/hasPermission']('core_homepage_can_manage')) next({ name: 'HomePage' });
            else next({ name: 'ProductsList' });
          } else {
            setBreadcrumb(to);
            next();
          }
        } else {
          const featureRoute = to.matched.find(route => route.meta && route.meta.feature);
          next({ name: featureRoute.meta.noFeatureAccessRoute });
        }
      } else {
        next({ name: 'AcceptTerms' });
      }
    } else {
      next({ name: 'Login', query: { name: to.name }, params: to.params });
    }
  } else {
    next();
  }

  Vue.nextTick(() => {
    document.title = to.meta.title || process.env.VUE_APP_APPLICATION_TITLE;
  });
});

const verifySchoolValidation = to => {
  return (
    store.getters['school/isSchoolValidated'] ||
    ['HomePage', 'AcceptTerms'].includes(to.name) ||
    store.getters['school/isNewSchool'] ||
    store.state.auth.loggedUser?.email === 'contato@edools.com'
  );
};

const setBreadcrumb = to => {
  const breadcrumb = collectRoutesAllowedOnBreadcrumb(to.matched);
  store.dispatch('breadcrumb/setRoutes', breadcrumb);
  store.dispatch('breadcrumb/setActualRoute', to.name);
};

const verifyFeatureAccess = to => {
  const featureRoute = to.matched.find(route => route.meta && route.meta.feature);
  if (!featureRoute) return true;
  const featureIsEnabled = store.state.school.selectedSchool.features.find(
    feature => feature === featureRoute.meta.feature
  );
  return !featureRoute || (featureRoute && featureIsEnabled);
};

const verifyTermsOfConsense = (to, next) => {
  const requiresAcceptTerms = to.matched.find(route => route.meta && route.meta.requiresAcceptedTerms);
  const termsAreAccepted = store.getters['auth/hasAcceptedTerms'];
  if (to.name === 'AcceptTerms' && termsAreAccepted) next({ name: 'FunnelsList' });
  return !requiresAcceptTerms || (requiresAcceptTerms && termsAreAccepted);
};

export default router;
