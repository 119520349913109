class ToastManager {
  constructor() {}

  get componentVue() {
    return this._componentVue;
  }

  set componentVue(comp) {
    this._componentVue = comp;
  }

  configure(comp) {
    this._componentVue = comp;
  }

  mountToast(title, text, icon, variant = 'regular') {
    const h = this.componentVue.$createElement;

    const textElement = h('div', {
      class: ['toaster__content-description'],
      domProps: {
        innerHTML: text,
      },
    });

    const toasterText = text ? [textElement] : [];

    return h(
      'div',
      {
        class: ['toaster'],
      },
      [
        h(
          'div',
          {
            class: ['toaster__icon-container'],
          },
          [
            h('hs-icon', {
              props: { icon: icon, variant: variant, size: 16 },
              class: ['toaster__alert-icon'],
            }),
          ]
        ),
        h(
          'div',
          {
            class: ['toaster__content-container'],
          },
          [
            h('div', {
              class: ['toaster__content-title'],
              domProps: {
                innerHTML: title,
              },
            }),
            ...toasterText,
          ]
        ),

        h(
          'button',
          {
            on: { click: () => this.componentVue.$bvToast.hide() },
            class: ['toaster__close-button'],
          },
          [
            h('hs-icon', {
              props: { icon: 'times', size: 14 },
            }),
          ]
        ),
      ]
    );
  }

  successMessage(title, text = '', options = {}) {
    const msg = this.mountToast(title, text, 'check', 'light');

    this.componentVue.$bvToast.toast([msg], {
      variant: 'success',
      solid: true,
      ...options,
    });
  }

  dangerMessage(title, text = '', options = {}) {
    const msg = this.mountToast(title, text, 'ban');

    this.componentVue.$bvToast.toast([msg], {
      variant: 'danger',
      solid: true,
      ...options,
    });
  }

  warningMessage(title, text = '', options = {}) {
    const msg = this.mountToast(title, text, 'exclamation', 'solid');

    this.componentVue.$bvToast.toast([msg], {
      variant: 'warning',
      solid: true,
      ...options,
    });
  }

  infoMessage(title, text = '', options = {}) {
    const msg = this.mountToast(title, text, 'info', 'solid');

    this.componentVue.$bvToast.toast([msg], {
      variant: 'info',
      solid: true,
      ...options,
    });
  }
}

export default new ToastManager();
