import axiosHeimdall from './axios';
import GeneralHelper from '@/shared/helpers/general';
import store from '@/store';

export default {
  jwtOrToken: () => (store.state.auth.loggedUser?.jwt ? 'jwt' : 'token'),

  get(params) {
    return axiosHeimdall.get(`v3/customers?${GeneralHelper.parseToQueryString(params)}`, {
      authMethod: this.jwtOrToken(),
    });
  },

  create(customer) {
    return axiosHeimdall.post('v3/customers', { customer }, { authMethod: this.jwtOrToken() });
  },

  update(customer) {
    return axiosHeimdall.post('v3/customers', { customer }, { authMethod: this.jwtOrToken() });
  },

  async addPlatformAtSchool({ platformName, school }) {
    return this.create({
      customer: {
        platforms_attributes: [
          {
            name: platformName,
            subdomain: school.subdomain,
            url: `${school.subdomain}.${process.env.VUE_APP_DOMAIN_URL}`,
            type: platformName,
            uid: school.id,
            terms_acceptance_attributes: {
              name: school.name,
              email: school.email,
              at: school.created_at || new Date().toISOString(),
              language: 'portuguese',
            },
          },
        ],
      },
    });
  },

  async findOrCreateUid({ school, product = null }) {
    try {
      const response = await this.get([{ key: 'platform_uid', value: school.id }]);

      if (!response.data) {
        const sparkProducts = product ? [product] : ['sparkfunnels', 'sparkmembers'];

        const platforms_attributes = sparkProducts.reduce((platforms, feature) => {
          if (school.features?.includes(feature)) {
            const isFunnel = feature === 'sparkfunnels' || feature === 'spark_funnel';
            const platform = {
              name: `${isFunnel ? 'Funnels' : 'Members'}Platform`,
              subdomain: school.subdomain,
              url: `${school.subdomain}.${process.env.VUE_APP_DOMAIN_URL}`,
              type: `${isFunnel ? 'Funnels' : 'Members'}Platform`,
              uid: school.id,
              terms_acceptance_attributes: {
                name: school.name,
                email: school.email,
                at: school.created_at || new Date().toISOString(),
                language: 'portuguese',
              },
            };
            platforms.push(platform);
          }

          return platforms;
        }, []);

        const {
          data: {
            data: {
              customer: { uid },
            },
          },
        } = await this.create({
          customer: {
            name: school.name,
            email: school.email,
            entry_date: school.created_at || new Date().toISOString(),
            platforms_attributes,
          },
        });

        return uid;
      }

      return response.data.uid;
    } catch (error) {
      return null;
    }
  },

  async updateSchool(school) {
    try {
      const data = await this.get([{ key: 'platform_uid', value: school.id }]);

      const types = data.platforms.filter(platform => platform.uid == school.id);

      const response = await Promise.all(
        types.map(async item => {
          return await this.create({
            customer: {
              uid: data.uid,
              platforms_attributes: [
                {
                  subdomain: school.subdomain,
                  url: `${school.subdomain}.${process.env.VUE_APP_DOMAIN_URL}`,
                  type: item.type,
                  uid: school.id,
                },
              ],
            },
          });
        })
      );
      return response;
    } catch (error) {
      return null;
    }
  },
};
