import store from '@/store';

export default {
  path: 'sparkfunnels',
  name: 'SparkFunnels',
  meta: { feature: 'spark_funnel', noFeatureAccessRoute: 'SparkFunnelsLandingPage' },
  redirect: { name: 'FunnelsList' },
  component: () => import(/* webpackChunkName: "sparkfunnels" */ '@/views/EmptyRouterView.vue'),
  children: [
    {
      path: 'funnels',
      name: 'Funnels',
      redirect: { name: 'FunnelsList' },
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/hasPermission']('core_funnel_can_manage')) next();
        else next({ name: 'ProductsList' });
      },
      component: () => import(/* webpackChunkName: "funnels" */ '@/views/EmptyRouterView.vue'),
      children: [
        {
          path: '',
          name: 'FunnelsList',
          component: () => import(/* webpackChunkName: "funnels" */ '@/sparkfunnels/views/Funnels/List'),
          meta: { title: `Meus Funis – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'create',
          name: 'FunnelCreate',
          component: () => import(/* webpackChunkName: "funnels" */ '@/sparkfunnels/views/Funnels/Create'),
          meta: { title: `Criar Funil – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'campaigns',
          name: 'Campaigns',
          redirect: { name: 'CampaignsList' },
          component: () => import(/* webpackChunkName: "campaigns" */ '@/views/EmptyRouterView.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['auth/hasPermission']('core_campaign_can_manage')) next();
            else next({ name: 'ProductsList' });
          },
          children: [
            {
              path: '',
              name: 'CampaignsList',
              component: () => import(/* webpackChunkName: "campaigns" */ '@/sparkfunnels/views/Campaigns/List'),
              meta: { title: `Minhas Campanhas – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'create',
              name: 'CampaignCreate',
              component: () => import(/* webpackChunkName: "campaigns" */ '@/sparkfunnels/views/Campaigns/Create'),
              meta: { title: `Criar Campanha – ${process.env.VUE_APP_APPLICATION_TITLE}` },
              beforeEnter: (to, from, next) => {
                if (store.getters['school/hasFeature']('funnels_campaigns')) {
                  next();
                } else {
                  next({ name: 'CampaignsList', query: { pmodal: true } });
                }
              },
            },
          ],
        },
        {
          path: 'leads',
          name: 'LeadsManager',
          component: () => import(/* webpackChunkName: "leads" */ '@/sparkfunnels/views/LeadsManager/List'),
          meta: { title: `Meus Leads – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: ':funnelId',
          component: () => import(/* webpackChunkName: "funnelId" */ '@/views/EmptyRouterView.vue'),
          children: [
            {
              path: '',
              name: 'FunnelEdit',
              props: true,
              component: () => import(/* webpackChunkName: "funnelId" */ '@/sparkfunnels/views/Funnels/Edit'),
              meta: { title: `Editar Funil – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'analytics',
              name: 'FunnelAnalytics',
              props: true,
              component: () => import(/* webpackChunkName: "funnelId" */ '@/sparkfunnels/views/Analytics'),
              meta: { title: `Analytics do Funil – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'leads',
              name: 'FunnelLeads',
              props: true,
              component: () => import(/* webpackChunkName: "funnelId" */ '@/sparkfunnels/views/Leads'),
              meta: { title: `Leads do Funil – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
            {
              path: 'share',
              name: 'ShareFunnel',
              props: true,
              component: () => import(/* webpackChunkName: "funnelId" */ '@/sparkfunnels/views/Funnels/Share'),
              meta: { title: `Compartilhar Funil – ${process.env.VUE_APP_APPLICATION_TITLE}` },
            },
          ],
        },
      ],
    },
    {
      path: 'templates',
      name: 'Templates',
      redirect: { name: 'TemplatesList' },
      component: () => import(/* webpackChunkName: "templates" */ '@/views/EmptyRouterView.vue'),
      beforeEnter: (to, from, next) => {
        if (store.getters['school/hasFeature']('funnels_templates')) {
          next();
        } else {
          next({ name: 'Funnels' });
        }
      },
      children: [
        {
          path: '',
          name: 'TemplatesList',
          component: () => import(/* webpackChunkName: "templates" */ '@/sparkfunnels/views/Templates/List'),
          meta: { title: `Templates – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
        {
          path: 'create',
          name: 'TemplateCreate',
          component: () => import(/* webpackChunkName: "templates" */ '@/sparkfunnels/views/Templates/Create'),
          meta: { title: `Criar Template – ${process.env.VUE_APP_APPLICATION_TITLE}` },
        },
      ],
    },
  ],
};
