export default {
  html: `<!doctype html>
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
      <title>
      </title>
      <!--[if !mso]><!-- -->
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <!--<![endif]-->
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <style type="text/css">
        #outlook a {
          padding:0;
        }
        body {
          margin:0;
          padding:0;
          -webkit-text-size-adjust:100%;
          -ms-text-size-adjust:100%;
        }
        table, td {
          border-collapse:collapse;
          mso-table-lspace:0pt;
          mso-table-rspace:0pt;
        }
        img {
          border:0;
          height:auto;
          line-height:100%;
          outline:none;
          text-decoration:none;
          -ms-interpolation-mode:bicubic;
        }
        p {
          display:block;
          margin:13px 0;
        }
      </style>
      <!--[if mso]>
  <xml>
  <o:OfficeDocumentSettings>
  <o:AllowPNG/>
  <o:PixelsPerInch>96</o:PixelsPerInch>
  </o:OfficeDocumentSettings>
  </xml>
  <![endif]-->
      <!--[if lte mso 11]>
  <style type="text/css">
  .mj-outlook-group-fix { width:100% !important; }
  </style>
  <![endif]-->
      <!--[if !mso]><!-->
      <link href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700" rel="stylesheet" type="text/css">
      <style type="text/css">
        @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
      </style>
      <!--<![endif]-->
      <style type="text/css">
        @media only screen and (min-width:480px) {
          .mj-column-per-100 {
            width:100% !important;
            max-width: 100%;
          }
        }
      </style>
      <style type="text/css">
        @media only screen and (max-width:480px) {
          table.mj-full-width-mobile {
            width: 100% !important;
          }
          td.mj-full-width-mobile {
            width: auto !important;
          }
        }
      </style>
    </head>
    <body style="background-color:#ffffff;">
      <div
           style="background-color:#ffffff;"
           >
        <!--[if mso | IE]>
  <table
  align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
  >
  <tr>
  <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
  <![endif]-->
        <div  style="margin:0px auto;max-width:600px;">
          <table
                 align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
                 >
            <tbody>
              <tr>
                <td
                    style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"
                    >
                  <!--[if mso | IE]>
  <table role="presentation" border="0" cellpadding="0" cellspacing="0">
  <tr>
  <td
  class="" style="vertical-align:top;width:600px;"
  >
  <![endif]-->
                  <div
                       class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
                       >
                    <table
                           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
                           >
                      <tr>
                        <td
                            align="left" style="background:rgba(0,0,0,0);font-size:0px;padding:10px 25px;word-break:break-word;"
                            >
                          <div
                               style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:1;text-align:left;color:#000000;"
                               >
                            <p id="i2zx2" class="p1">Olá
                              <span id="i0htx-2">{{ lead.name }}</span>
                              <b id="ih1lk-2">,
                              </b>
                              <span id="id7kg-2">tudo bem?<br><br></span>
                            </p>
                            <p id="irzyl" class="p2">
                              <span id="idyff">Se você está recebendo este email, é porque você baixou
                              </span>
                              <b id="idhvn">[NOME DO MATERIAL]
                              </b>
                              <span id="i26m9">, e eu gostaria de te apresentar uma oportunidade que pode ser interessante para você.<br><br></span>
                            </p>
                            <p id="i0jpj" class="p1">Não sei se você sabe, mas o
                              <b>[NOME DO MATERIAL]
                              </b>tem uma versão mais completa, o
                              <b>[NOME DO PRÓXIMO MATERIAL DO FUNIL OU PRODUTO]
                              </b>.
                              <br>
                              <br>
                            </p>
                            <p id="iueho3" class="p2">
                              <span id="ihck9">Nesta semana, irei disponibilizar um cupom de XX% OFF para quem tiver interesse em adquirir
                              </span>
                              <b id="izs14">[NOME DO PRODUTO]
                              </b>
                              <span id="is648">.<br><br></span>
                            </p>
                            <p id="inrnl3" class="p1">Então, se você tem interesse e gostaria de receber o cupom de desconto, você precisa agir rápido e fazer parte da nossa Lista VIP.
                              <br>
                              <br>
                            </p>
                            <p id="i4z4wo" class="p1">E para fazer parte da nossa lista VIP é muito simples:
                            </p>
                            <p id="iyf72s" class="p1">- Clique no botão abaixo e
                              <b>[CADASTRE-SE OU ENTRE NO GRUPO]
                              </b>
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                            align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;padding-top:29px;padding-bottom:29px;word-break:break-word;"
                            >
                          <table
                                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;line-height:100%;"
                                 >
                            <tr>
                              <td
                                  align="center" bgcolor="#414141" role="presentation" style="border:none;border-radius:3px;cursor:auto;mso-padding-alt:10px 25px;background:#414141;" valign="middle"
                                  >
                                <p
                                   style="display:inline-block;background:#414141;color:#ffffff;font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:10px 25px;mso-padding-alt:0px;border-radius:3px;"
                                   >
                                  ENTRAR NA LISTA VIP
                                </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td
                            align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;"
                            >
                          <div
                               style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:1;text-align:center;color:#000000;"
                               >
                            <b>(Fique tranquilo, fazer parte da lista VIP não tem custo algum)
                            </b>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                            align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;"
                            >
                          <div
                               style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:1;text-align:left;color:#000000;"
                               >
                            <p id="i6vtw" class="p1">
                              <b>⚠ Importante:
                                <br>
                              </b>
                              <br>Apenas a lista VIP terá acesso ao cupom. Então se você tem interesse no 
                              <b>[NOME DO MATERIAL]
                              </b> e gostaria de aproveitar XX% OFF.
                              <br>
                              <br>Aqui está um spoiler do que você vai encontrar no 
                              <b>[PRINT DA [AREA INTERNA DO PRODUTO OU PRINT INTERAÇÃO DO GRUPO]
                              </b>
                              <br>
                            </p>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!--[if mso | IE]>
  </td>
  </tr>
  </table>
  <![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--[if mso | IE]>
  </td>
  </tr>
  </table>
  <table
  align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
  >
  <tr>
  <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
  <![endif]-->
        <div  style="margin:0px auto;max-width:600px;">
          <table
                 align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
                 >
            <tbody>
              <tr>
                <td
                    style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"
                    >
                  <!--[if mso | IE]>
  <table role="presentation" border="0" cellpadding="0" cellspacing="0">
  <tr>
  <td
  class="" style="vertical-align:top;width:600px;"
  >
  <![endif]-->
                  <div
                       class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
                       >
                    <table
                           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
                           >
                      <tr>
                        <td
                            align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;"
                            >
                          <table
                                 border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
                                 >
                            <tbody>
                              <tr>
                                <td  style="width:550px;">
                                  <img
                                       height="auto" src="http://placehold.it/350x250/78c5d6/fff" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="550"
                                       />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!--[if mso | IE]>
  </td>
  </tr>
  </table>
  <![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--[if mso | IE]>
  </td>
  </tr>
  </table>
  <table
  align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
  >
  <tr>
  <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
  <![endif]-->
        <div  style="margin:0px auto;max-width:600px;">
          <table
                 align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
                 >
            <tbody>
              <tr>
                <td
                    style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;"
                    >
                  <!--[if mso | IE]>
  <table role="presentation" border="0" cellpadding="0" cellspacing="0">
  <tr>
  <td
  class="" style="vertical-align:top;width:600px;"
  >
  <![endif]-->
                  <div
                       class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
                       >
                    <table
                           border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
                           >
                      <tr>
                        <td
                            align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;"
                            >
                          <div
                               style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:1;text-align:left;color:#000000;"
                               >Meu recado de hoje era este...
                            <br>
                            <br>Um abraço e até mais!
                            <br>
                            <br>
                            <b>[ASSINATURA]
                            </b>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                            style="font-size:0px;word-break:break-word;"
                            >
                          <!--[if mso | IE]>
  <table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td height="65" style="height:65px;">
  <![endif]-->
                          <div
                               style="height:65px;"
                               >
                            &nbsp;
                          </div>
                          <!--[if mso | IE]>
  </td></tr></table>
  <![endif]-->
                        </td>
                      </tr>
                      <tr>
                        <td
                            align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;"
                            >
                          <div
                               style="font-family:Ubuntu, Helvetica, Arial, sans-serif;font-size:13px;line-height:1;text-align:left;color:#000000;"
                               >Ps. Com 
                            <b>[NOME DO MATERIAL OU PRODUTO],
                            </b> você poderá:
                            <br>
                            <br>[DESCREVER VANTAGEM 1 RESUMIDA]
                            <br>[DESCREVER VANTAGEM 2 RESUMIDA]
                            <br>[DESCREVER VANTAGEM 3 RESUMIDA]
                            <br>
                            <br>(Se você quer saber mais sobre o 
                            <b>[NOME DO MATERIAL OU PRODUTO]
                            </b>, 
                            <a href="" class="link">clique aqui</a>)
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!--[if mso | IE]>
  </td>
  </tr>
  </table>
  <![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--[if mso | IE]>
  </td>
  </tr>
  </table>
  <![endif]-->
      </div>
    </body>
  </html>
  `,
  mjml: `
  <mjml>
    <mj-body>
      <mj-section>
        <mj-column>
        <mj-text>
            <p id="i2zx2" class="p1">Olá
              <span id="i0htx-2" class="mention">{{ lead.name }}</span>
              <b id="ih1lk-2">,
              </b>
              <span id="id7kg-2">tudo bem?<br/><br/></span>
            </p>
            <p id="irzyl" class="p2">
              <span id="idyff">Se você está recebendo este email, é porque você baixou
              </span>
              <b id="idhvn">[NOME DO MATERIAL]
              </b>
              <span id="i26m9">, e eu gostaria de te apresentar uma oportunidade que pode ser interessante para você.<br/><br/></span>
            </p>
            <p id="i0jpj" class="p1">Não sei se você sabe, mas o
              <b>[NOME DO MATERIAL]
              </b>tem uma versão mais completa, o
              <b>[NOME DO PRÓXIMO MATERIAL DO FUNIL OU PRODUTO]
              </b>.
              <br/>
              <br/>
            </p>
            <p id="iueho3" class="p2">
              <span id="ihck9">Nesta semana, irei disponibilizar um cupom de XX% OFF para quem tiver interesse em adquirir
              </span>
              <b id="izs14">[NOME DO PRODUTO]
              </b>
              <span id="is648">.<br/><br/></span>
            </p>
            <p id="inrnl3" class="p1">Então, se você tem interesse e gostaria de receber o cupom de desconto, você precisa agir rápido e fazer parte da nossa Lista VIP.
              <br/>
              <br/>
            </p>
            <p id="i4z4wo" class="p1">E para fazer parte da nossa lista VIP é muito simples:
            </p>
            <p id="iyf72s" class="p1">- Clique no botão abaixo e
              <b>[CADASTRE-SE OU ENTRE NO GRUPO]
              </b>
            </p>
          </mj-text>
          <mj-button padding-top="29px" padding-bottom="29px">
            ENTRAR NA LISTA VIP
          </mj-button>
          <mj-text align="center">
            <b>(Fique tranquilo, fazer parte da lista VIP não tem custo algum)
            </b>
          </mj-text>
          <mj-text>
            <p id="i6vtw" class="p1">
              <b>⚠ Importante:
                <br>
              </b>
              <br>Apenas a lista VIP terá acesso ao cupom. Então se você tem interesse no 
              <b>[NOME DO MATERIAL]
              </b> e gostaria de aproveitar XX% OFF.
              <br>
              <br>Aqui está um spoiler do que você vai encontrar no 
              <b>[PRINT DA [AREA INTERNA DO PRODUTO OU PRINT INTERAÇÃO DO GRUPO]
              </b>
              <br>
            </p>
          </mj-text>
        </mj-column>
      </mj-section>
      <mj-section>
        <mj-column>
          <mj-image src="http://placehold.it/350x250/78c5d6/fff"/>
        </mj-column>
      </mj-section>
      <mj-section>
        <mj-column>
          <mj-text>Meu recado de hoje era este...
            <br>
            <br>Um abraço e até mais!
            <br>
            <br>
            <b>[ASSINATURA]
            </b>
          </mj-text>
          <mj-spacer height="65px"></mj-spacer>
          <mj-text>Ps. Com 
            <b>[NOME DO MATERIAL OU PRODUTO],
            </b> você poderá:
            <br>
            <br>[DESCREVER VANTAGEM 1 RESUMIDA]
            <br>[DESCREVER VANTAGEM 2 RESUMIDA]
            <br>[DESCREVER VANTAGEM 3 RESUMIDA]
            <br>
            <br>(Se você quer saber mais sobre o 
            <b>[NOME DO MATERIAL OU PRODUTO]
            </b>, 
            <a href="" class="link">clique aqui</a>)
          </mj-text>
        </mj-column>
      </mj-section>
    </mj-body>
  </mjml>`,
};
