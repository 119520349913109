import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    items: [],
    templates: [],
    selectedFunnel: null,
    loadedFunnel: null,
    selectedStage: null,
    loadedStages: [],
    editingEmailTemplate: null,
    selectedEmailTemplate: null,
    emailTemplates: [],
    loadedMedia: null,
    stagesEmailsContent: [],
    objectives: [],
    objectivesSelected: new Set(),
    strategies: [],
    strategyTemplates: new Set(),
  },
  actions,
  mutations,
  getters,
};
