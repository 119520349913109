import dayjs from 'dayjs';
import Vue from 'vue';
import get from 'lodash/get';

export default {
  getMembers(state, data) {
    if (data.length) {
      return (state.members = data.map(student => ({
        ...student,
        created_at: dayjs(student.created_at).format('DD/MM/YYYY'),
        status: get(student, 'registrations[0].status', 'lead'),
        last_purchase: get(student, 'registrations[0].enrollments[0].school_product.title', null),
      })));
    }
    state.members = data;
  },

  selectMember(state, member) {
    state.selectedMember = member;
  },

  updateMembers(state, members) {
    state.members = members;
  },

  updateMember(state, data) {
    const indexMember = state.members.findIndex(member => member.id === data.id);
    Vue.set(state.members, indexMember, data);
  },

  deleteMembers(state, data) {
    state.members = state.members.filter(member => data.every(dt => dt.id !== member.id));
  },

  toggleSidebar(state, data) {
    state.sidebar = data;
  },

  setProducts(state, products) {
    const product = products.map(product => ({
      id: product.id,
      title: product.title,
      kind: product.library_resource?.resource.kind || product.library_resource?.resource.type,
    }));
    state.products = [...product];
  },
};
