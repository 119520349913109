export default {
  checkoutSettings: state => {
    const hasWhatsappNumber = Boolean(state.checkoutSettings.whatsapp_settings?.whatsapp_number);

    return {
      ...state.checkoutSettings,
      whatsapp_settings: hasWhatsappNumber ? state.checkoutSettings.whatsapp_settings : null,
    };
  },
  timerSettings: state => state.checkoutSettings.timer_settings,
  whatsappSettings: state => state.checkoutSettings.whatsapp_settings,
  bannerSettings: state => state.checkoutSettings.banner_settings,
  topBannerSettings: state => state.checkoutSettings.banner_settings?.top_banner,
  sideBannerSettings: state => state.checkoutSettings.banner_settings?.side_banner,
  backgroundSettings: state => state.checkoutSettings.background_settings,
};
