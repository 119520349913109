import { enrollmentService } from '@/services';

export default {
  async getEnrollment({ commit }, enrollmentId) {
    try {
      const enrollment = await enrollmentService.getEnrollmentData(enrollmentId);
      commit('setSelectedEnrollment', enrollment);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  selectEnrollment({ commit }, enrollment) {
    commit('setSelectedEnrollment', enrollment);
  },
};
