import axiosXavier from '@/services/axios';

const CAMPAIGNS_ROUTE = 'campaigns';

export default {
  getCampaigns: (currentPage, perPage) => {
    let url = `${CAMPAIGNS_ROUTE}/?`;
    if (currentPage) {
      url += `page=${currentPage}&`;
    }
    if (perPage) {
      url += `per_page=${perPage}&`;
    }
    return axiosXavier.get(url);
  },
  getCampaign: id => axiosXavier.get(`${CAMPAIGNS_ROUTE}/${id}`),
  createCampaign: ({ name, schoolId, funnelId, days, hours, minutes, sendAt, subject, body, mjml }) =>
    axiosXavier.post(`${CAMPAIGNS_ROUTE}`, {
      name,
      school_id: schoolId,
      funnel_id: funnelId,
      email_templates_attributes: [
        {
          school_id: schoolId,
          days,
          hours,
          minutes,
          send_at: sendAt,
          settings: {
            body,
            subject,
          },
          mjml_body: mjml,
        },
      ],
    }),
  deleteCampaign: id => axiosXavier.delete(`${CAMPAIGNS_ROUTE}/${id}`),
  updateCampaign: ({ id, name, status }) => axiosXavier.put(`${CAMPAIGNS_ROUTE}/${id}`, { name, status }),
  updateCampaignStatus: ({ id, action }) => axiosXavier.put(`${CAMPAIGNS_ROUTE}/${id}/${action}`),
};
