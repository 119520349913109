export default {
  hidePopovers({ commit }) {
    commit('hidePopovers');
  },
  hideSideMenu({ commit }) {
    commit('hideSideMenu');
  },
  toggleProfilePopover({ commit }) {
    commit('toggleProfilePopover');
  },
  toggleSideMenu({ commit }) {
    commit('toggleSideMenu');
  },
};
