import axios from 'axios';
import axiosXavier from '../../services/axios';
import store from '@/store';

export const axiosSparkpay = axios.create({
  baseURL: process.env.VUE_APP_SPARKPAY_URL,
});

axiosSparkpay.interceptors.request.use(
  config => {
    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    const token = store.state.auth.loggedUser.jwt;

    if (token) config.headers['Authorization'] = token;

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosSparkpay.interceptors.response.use(response => response.data, error => Promise.reject(error));

export default {
  fetchOriginKey: () => {
    return axiosSparkpay
      .post('domain', {
        domain_origin: window.location.origin,
      })
      .then(({ key }) => key);
  },
  postRefund: id => axiosXavier.post(`payments/${id}/refund`),
  recipient: () => axiosSparkpay.get(`/api/v2/recipient`).then(({ recipient }) => recipient),
  recipientValidated: () => axiosSparkpay.get(`/api/v2/validate_recipient`),
  bankAccount: {
    list: () => axiosSparkpay.get(`/api/v2/bank_accounts`),
    create: params => axiosSparkpay.post(`/api/v2/bank_accounts`, params),
    update: (params, id) => axiosSparkpay.put(`/api/v2/bank_accounts/${id}`, params),
  },
  withdraw: params => axiosSparkpay.post(`/api/v2/withdrawals`, params),
  withdrawList: params => axiosSparkpay.get('/api/v2/withdrawals', params),
  verifyValidRefund: params => axiosSparkpay.get('/api/v2/verify_valid_refund', { params }),
  getAnticipationValue: amount_cents => axiosSparkpay.get('/api/v2/anticipation_values', { params: { amount_cents } }),
};
