import _ from 'lodash';

const disallowedToFormBreadcrumb = [
  undefined,
  'Analytics',
  'AffiliatesPage',
  'AutomationsDashboard',
  'Certificates',
  'CertificatesBasicEditor',
  'Contents',
  'Coupons',
  'FunnelsList',
  'FunnelStages',
  'LeadsList',
  'MainPanel',
  'MembersList',
  'ProductDashboard',
  'ProductsList',
  'ProductSalesPage',
  'ProgramInfo',
  'ProgramPage',
  'SparkFunnels',
  'SparkMembers',
  'SparkPay',
  'SubscriptionsList',
  'SalesStrategyList',
  'TemplatesList',
  'UnitsList',
  'WalletBalance',
  'WalletWithdrawals',
  'WalletBankAccount',
  'SalesReport',
  'AutomationsWebhookEdit',
];

const breadcrumbOrDefault = (route, defaultValue) => _.get(route, 'meta.breadcrumb', defaultValue);

export const collectRoutesAllowedOnBreadcrumb = routes =>
  routes.reduce((allowed, route) => {
    const ok = _.has(route, 'meta.breadcrumb')
      ? breadcrumbOrDefault(route, null) !== null
      : !disallowedToFormBreadcrumb.includes(route.name);
    ok && allowed.push({ name: breadcrumbOrDefault(route, route.name) });
    return allowed;
  }, []);
