import ToastHelper from '@/shared/helpers/toast';
import Vue from 'vue';
import App from './App.vue';
import i18nHelper from '@/shared/helpers/i18n';
import router from './router';
import store from './store';
import ACL from './acl';
import FCL from './fcl';
import { Plugin as Fragment } from 'vue-fragment';

Vue.prototype.$FCL = FCL(store);
Vue.prototype.$ACL = ACL(store);

Vue.use(Fragment);

const Environments = Object.freeze({
  Production: 'production',
  Staging: 'staging',
  Development: 'development',
});

const createVueInstance = async () => {
  const i18n = await i18nHelper.init('pt-BR');

  const comp = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app');

  ToastHelper.configure(comp);
};

const errorHandler = () => {
  Vue.config.errorHandler = (err, vm, info) => {
    if ([Environments.Staging, Environments.Development].includes(process.env.NODE_ENV)) {
      // eslint-disable-next-line no-console
      console.log(err, vm, info);
    }
    // Track the native JS error to New Relic
    if (window.newrelic && 'noticeError' in window.newrelic) {
      window.newrelic.noticeError(err, { component: vm.$options.name, info });
    }
  };
};

const init = async () => {
  errorHandler(), await createVueInstance();
};

export default {
  init,
};
