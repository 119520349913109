const getDefaultState = () => ({
  body: null,
  callbackRoute: null,
  variables: null,
});

export default {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setHtml(state, html) {
    state.html = html;
  },
  setMjml(state, mjml) {
    state.mjml = mjml;
  },
  setCallbackRoute(state, callbackRoute) {
    state.callbackRoute = callbackRoute;
  },
  setVariables(state, variables) {
    state.variables = variables;
  },
};
