export default {
  slug: 'basic_pro',
  segmentSlug: 'basic_pro',
  title: 'Basic',
  active: true,
  price: 197,
  currency: 'R$',
  content: 'Esse plano é para quem tem alguma experiência no digital.',
  highlited: true,
  callConsultant: false,
  features: {
    previousPlan: 'Free',
    highlight: [
      { title: 'Funis ilimitados', star: true },
      { title: 'Até 5.000 leads', star: true },
      { title: 'Campanhas ilimitadas', star: true },
      { title: 'Importação de leads', star: true },
      { title: 'Domínio customizado', star: true },
      { title: 'Análise avançada de entregabilidade de emails', star: true },
      { title: 'Taxa 7,9%', star: true },
      { title: 'Até 20 GB', star: true },
      { title: 'Até 1.000 membros gratuitos', star: true },
    ],
    groups: [
      {
        title: 'Estratégia de Vendas',
        star: true,
        features: [
          {
            title: 'Funils de vendas',
            value: 'Ilimitado',
            ready: true,
            star: false,
          },
          {
            title: 'Pageviews',
            value: 'Ilimitado',
            ready: true,
            star: false,
          },
          {
            title: 'Contatos (leads)',
            value: 'Até 5.000',
            ready: true,
            star: true,
          },
          {
            title: 'Envio de emails',
            value: 'Ilimitado',
            ready: true,
            star: false,
          },
          {
            title: 'Gerenciador de leads',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Detalhes de atividades de leads',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Checkout integrado',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Integração com EADBox',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Integração com EDools',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Campanhas de emails',
            value: 'Ilimitado',
            ready: true,
            star: true,
          },
          {
            title: 'Importação de leads em .csv',
            value: true,
            ready: true,
            star: true,
          },
          {
            title: 'Modelos de funis exclusivos e personalizados',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Exportação de leads em .csv',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Remoção da marca Herospark dos emails',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Integração com Hotmart',
            value: false,
            ready: true,
            star: false,
          },
        ],
      },
      {
        title: 'Editor de Páginas',
        star: true,
        features: [
          {
            title: 'Subdomínio *.herospark.co',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Página de produto',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Página de vendas',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Página de captura',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Editor visual de páginas',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Domínio customizado',
            value: true,
            ready: true,
            star: true,
          },
          {
            title: 'Remoção da marca Herospark das páginas',
            value: false,
            ready: true,
            star: false,
          },
        ],
      },
      {
        title: 'Analytics',
        star: true,
        features: [
          {
            title: 'Dados de conversão por funil',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Dados de faturamento por funil',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Configuração Facebook Pixel nas landing pages e páginas de ofertas',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Configuração de Google Analytics nas landing pages e páginas de ofertas',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Análise avançada de entregabilidade de emails',
            value: true,
            ready: true,
            star: true,
          },
        ],
      },
      {
        title: 'Checkout & Pagamentos',
        star: false,
        features: [
          {
            title: 'Parcelamento com e sem juros',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Integração da página de pagamentos',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Produtos de assinatura',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Integração com e-notas',
            value: true,
            ready: false,
            star: false,
          },
          {
            title: 'Cupom de desconto (por produto)',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Saque direto em conta bancária',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Gestão de pagamentos',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Relatório de vendas',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Pagamento por boleto ou cartão de crédito',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Integração com Whatsapp',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Pagamentos via PIX',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Taxa cartão de crédito',
            value: '7,9% + R$1',
            ready: true,
            star: false,
          },
          {
            title: 'Taxa boleto bancário',
            value: '7,9% + R$3',
            ready: true,
            star: false,
          },
          {
            title: 'Taxa de parcelamento',
            value: '2,92% ao mês',
            ready: true,
            star: false,
          },
          {
            title: 'Taxa de saque',
            value: 'R$4,73',
            ready: true,
            star: false,
          },
          {
            title: 'Disponibilidade de saldo para compras com Boleto',
            value: 'D + 2 dias',
            ready: true,
            star: false,
          },
          {
            title: 'Disponibilidade de saldo para compras com Cartão',
            value: 'D + 30 dias',
            ready: true,
            star: false,
          },
        ],
      },
      {
        title: 'Estratégia de Produtos',
        star: false,
        features: [
          {
            title: 'Criação de produtos',
            value: 'Ilimitado',
            ready: true,
            star: false,
          },
          {
            title: 'Ofertas por produto',
            value: 'Ilimitado',
            ready: true,
            star: false,
          },
          {
            title: 'Aprovação automática do produto',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Ebooks',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Cursos online',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Cursos grátis',
            value: false,
            ready: false,
            star: false,
          },
          {
            title: 'Conteúdo ao vivo',
            value: true,
            ready: false,
            star: false,
          },
          {
            title: 'Afiliados',
            value: true,
            ready: false,
            star: false,
          },
          {
            title: 'Certificação gerada automaticamente',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Portal de cursos (vitrine de produtos)',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Catálogo de produtos',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Liberação de produto por categoria de aluno',
            value: false,
            ready: true,
            star: false,
          },
        ],
      },
      {
        title: 'Recursos de Aprendizagem',
        star: true,
        features: [
          {
            title: 'Videoaulas com Youtube ou Vimeo',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Aulas de áudio, texto, ebook ou imagem',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Atividades (múltipla escolha, associativa, verdadeiro ou falso)',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Conteúdos adicionais por aula',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Material complementar por curso',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Certificados simples',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Múltiplos modelos de certificados',
            value: true,
            ready: true,
            star: true,
          },
          {
            title: 'Criação de combos de conteúdo(experiência Netflix)',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Liberação de conteúdo ao longo do tempo',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Pré-requisito de avanço entre aulas',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Automações por aula',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Certificados com customização avançada de layout',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'DRM social(CPF na tela)',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Trilhas de aprendizagem',
            value: false,
            ready: false,
            star: false,
          },
          {
            title: 'Upload e hospedagem de vídeos com criptografia',
            value: '20Gb',
            ready: true,
            star: true,
          },
        ],
      },
      {
        title: 'Área de Membros',
        star: true,
        features: [
          {
            title: 'Área de meus cursos para alunos',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Estrutura do curso com módulos e submódulos',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Continuar de onde parou',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Navegação entre aulas (anterior e próxima)',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Marcação de aulas concluídas',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Gestão de membros/alunos',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Progresso do curso',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Anotações nas aulas',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Perfil do membro/aluno',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Anti login simultâneo',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Subdomínio *.herospark.co',
            value: true,
            ready: true,
            star: false,
          },
          {
            title: 'Domínio customizado',
            value: true,
            ready: true,
            star: true,
          },
          {
            title: 'Adição manual de membros',
            value: true,
            ready: true,
            star: true,
          },
          {
            title: 'Analytics de engajamento',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Portal de cursos/vitrine de produtos',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Ações em massa por gatilho de membros',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Categorização de membros',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Acompanhamento de progresso de membros',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Remoção da marca Herospark',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Checkpoint de progresso',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Fóruns de discussão',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Usuários administrativos',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Usuários gratuitos (importacão em massa ou matriculas grátis)',
            value: 'Até 1000',
            ready: true,
            star: false,
          },
        ],
      },
      {
        title: 'Suporte e Relacionamento',
        star: true,
        features: [
          {
            title: 'Atendimento por chat',
            value: 'Prioritário ao anterior',
            ready: true,
            star: true,
          },
          {
            title: 'Atendimento por email',
            value: 'Prioritário ao anterior',
            ready: true,
            star: true,
          },
          {
            title: 'Atendimento por ticket',
            value: 'Prioritário ao anterior',
            ready: true,
            star: true,
          },
          {
            title: 'Participação em comitê de clientes para priorização de produto',
            value: false,
            ready: true,
            star: false,
          },
          {
            title: 'Acesso a grupos de mentoria',
            value: false,
            ready: true,
            star: false,
          },
        ],
      },
    ],
  },
};
