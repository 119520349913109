import TokenHelper from '@/shared/helpers/token';
import authApi from '@/auth/services/auth';
import userService from '@/services/user';
import schoolApi from '@/services/school';
import customerService from '@/services/heimdall/customer';
import tapfiliateService from '@/services/tapfiliate';
import affiliatesService from '@/sparkaffiliates/services/affiliates';
import dayjs from 'dayjs';
import ZendeskService from '@/services/zendesk';
import Features from '@/types/features';

export default {
  authenticate({ commit }) {
    commit('setToken', TokenHelper.getToken());
  },
  acceptTerms({ commit }, { userId }) {
    return userService.acceptTerms(userId).then(data => {
      commit('setConsense', true);
      return data;
    });
  },
  createInvite(context, { firstName, lastName, email, roleId }) {
    return authApi.createInvite(firstName, lastName, email, roleId);
  },
  getRoles({ commit }) {
    return authApi.getRoles().then(({ data }) => {
      commit('setRoles', data.roles);
      return data;
    });
  },
  getChannelsByUser({ commit }, { userId, params }) {
    return authApi.getChannelsByUser(userId, params).then(data => {
      try {
        const channels = data.channels.map(channel => {
          const member = channel.members.find(member => member.user && member.user.id === userId);
          channel.read = member.last_view && dayjs(member.last_view).isAfter(dayjs(channel.updated_at));
          return channel;
        });

        commit('setChannels', channels);
        return data;
      } catch (error) {
        return error;
      }
    });
  },
  invite({ commit, rootState, dispatch }, { invitation_token, realm_id, realm_type, password, password_confirmation }) {
    return authApi
      .invite(invitation_token, realm_id, realm_type, password, password_confirmation)
      .then(async ({ data }) => {
        commit('auth/setLoggedUser', data, { root: true });
        commit('auth/setToken', data.jwt, { root: true });
        commit('school/setSelectedSchool', { ...rootState.school.selectedSchool, id: realm_id }, { root: true });
        await dispatch('school/getSchoolValidation', null, { root: true });
        return data;
      });
  },
  login({ commit, dispatch }, { email, password, school }) {
    try {
      return authApi.login(email, password, school.id).then(async res => {
        const uid = await customerService.findOrCreateUid({ school });

        commit('setLoggedUser', { ...res.data, uid });
        commit('setToken', res.data.jwt);
        commit('school/setSelectedSchool', { ...school, uid }, { root: true });
        await dispatch('school/getSchoolValidation', null, { root: true });
        await dispatch('getChannelsByUser', { userId: res.data.id, params: [{ key: 'per_page', value: '50' }] });

        return res;
      });
    } catch (error) {
      return error;
    }
  },
  async sso({ commit, dispatch }, { school_id, email, credentials, super_admin_id, refresh = false }) {
    try {
      commit('setToken', null);
      return authApi.sso({ school_id, email, credentials, refresh, super_admin_id }).then(async user => {
        commit('setToken', user.jwt);
        const school = await schoolApi.getSchoolById(school_id);
        const uid = await customerService.findOrCreateUid({ school });
        commit('setLoggedUser', { ...user, uid });
        commit('school/setSelectedSchool', { ...school, uid }, { root: true });
        await dispatch('school/getSchoolValidation', null, { root: true });
        await dispatch('getChannelsByUser', { userId: user.id, params: [{ key: 'per_page', value: '50' }] });
        return user;
      });
    } catch (error) {
      return error;
    }
  },
  logout({ commit, dispatch }) {
    ZendeskService.logout(true);
    commit('setToken', null);
    commit('school/setSelectedSchool', null, { root: true });
    localStorage.removeItem(process.env.VUE_APP_STORED_DATA);
    dispatch('jwt/unload', null, { root: true });
  },
  signUp(
    { commit, dispatch },
    {
      name,
      email,
      password,
      product,
      phone,
      affiliateCode,
      tapfiliate_referrer_code,
      tapfiliate_must_send_referral,
      sms_token,
      sms_token_id,
      ddi,
      cpf,
      cnpj,
    }
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await authApi.signUp({
          name,
          email,
          password,
          phone,
          tapfiliate_referrer_code,
          tapfiliate_must_send_referral,
          sms_token,
          sms_token_id,
          ddi,
          cpf,
          cnpj,
        });

        const uid = data.school.id;

        commit('setLoggedUser', { ...data.admin, uid, phone: phone, role: { name: 'Administrador Geral' } });
        commit('setToken', data.admin.jwt);
        commit(
          'school/setSelectedSchool',
          {
            ...data.school,
            phone: phone,
            account_type: data.school.account_type || 'herospark',
            features: [...data.features],
            library: { ...data.library },
            forum: { ...data.forum },
            uid,
          },
          { root: true }
        );
        await dispatch('school/getSchoolValidation', null, { root: true });
        await dispatch(
          'school/enableFeature',
          {
            featureId: process.env.VUE_APP_SPARKMEMBERS_FEATURE_ID,
            featureKey: 'spark_members',
          },
          {
            root: true,
          }
        );

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updatePassword({ commit, rootState }, { password, token }) {
    return authApi.updatePassword({ school: rootState.school.selectedSchool, password, token }).then(res => {
      commit('setLoggedUser', res.data);
      commit('setToken', res.data.jwt);
      return res;
    });
  },
  updateUser({ commit, state }, user) {
    return userService.update(user).then(res => {
      const { first_name, last_name, email, cover_image_url } = user;
      commit('setLoggedUser', {
        ...state.loggedUser,
        ...JSON.parse(JSON.stringify({ first_name, last_name, email, cover_image_url })),
      });
      return res;
    });
  },
};
