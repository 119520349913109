export default {
  payloadMounted: state => ({
    name: state.name,
    schoolId: state.funnel.school_id,
    funnelId: state.funnel.id,
    days: state.emailTemplate.datetime.days,
    hours: state.emailTemplate.datetime.hours,
    minutes: state.emailTemplate.datetime.minutes,
    sendAt: state.emailTemplate.datetime.sendAt,
    subject: state.emailTemplate.data.subject,
    body: state.emailTemplate.data.body,
    mjml: state.emailTemplate.data.mjml,
  }),
};
