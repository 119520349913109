import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    illustration: require('@/assets/images/SparkFunnels/Funnels/Templates/default-illustration.svg'),
    language: null,
    objectives: [],
    stages: [],
    subtitle: '',
    title: '',
    emails: {},
    tabIndex: 0,
    stageOpened: null,
  },
  actions,
  getters,
  mutations,
};
