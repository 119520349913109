import axiosXavier from './axios';
import GeneralHelper from '@/shared/helpers/general';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';
import store from '@/store';

export default {
  getUnitaryOrders(params) {
    if (store.state.school.selectedSchool.features.includes('spark_checkout')) {
      return SparkEcommerceApiClient.payments.getUnitarySales(params);
    } else {
      return axiosXavier.get(`orders?${GeneralHelper.parseToQueryString(params)}`).then(res => res.data);
    }
  },
  getRecurrencyOrders(params) {
    return SparkEcommerceApiClient.payments.getRecurrencySales(params);
  },
  getStrategySalesOrders(params) {
    return SparkEcommerceApiClient.payments.getStrategySales(params, true);
  },
  getOrder({ id }) {
    if (store.state.school.selectedSchool.features.includes('spark_checkout')) {
      return SparkEcommerceApiClient.payments.getById(id);
    } else {
      return axiosXavier.get(`orders/${id}`).then(res => res.data);
    }
  },
  cancelOrder({ id }) {
    if (store.state.school.selectedSchool.features.includes('spark_checkout')) {
      return SparkEcommerceApiClient.payments.cancel(id);
    } else {
      return axiosXavier.put(`orders/${id}/cancel`).then(res => res.data);
    }
  },
};
