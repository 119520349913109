import Features from '@/types/features';

/**
 * Global method that returns a boolean if the school has the features
 * @param {Features[]} features - List of features required for a action/feature
 **/

export default function(store) {
  const FCL = features => features.every(feature => store.getters['school/hasFeature'](feature));

  FCL.hasSparkCheckout = () => FCL([Features.SPARK_CHECKOUT]);

  FCL.hasExpressCheckout = () => FCL([Features.EXPRESS_CHECKOUT]);

  FCL.hasInvoiceMetabaseReport = () => FCL([Features.INVOICE_METABASE_REPORT]);

  FCL.canCreateSubscriptionOffer = () => FCL.hasSparkCheckout() || !FCL([Features.MEMBERS_SUBSCRIPTION_OFFERS]);

  FCL.canUseCustomDomain = () => FCL([Features.ACTIVE_CUSTOM_DOMAIN_CONFIG]);

  FCL.canUseReleaseLimitation = () => FCL([Features.RELEASE_FOR_TIME_LESSON]);

  FCL.canCreateANewUserAdmin = () => FCL([Features.HERO_ADMIN_USERS]);

  FCL.canActiveThemeView = () => FCL([Features.ACTIVE_THEME_VIEW]);

  FCL.canActiveCertificateView = () => FCL([Features.ACTIVE_CERTIFICATE_VIEW]);

  FCL.canImportMembers = () => FCL([Features.MEMBERS_IMPORT_USERS]);

  FCL.canCreateComboProduct = () => FCL([Features.ALLOW_COMBO_PRODUCT]);

  FCL.canActiveForumView = () => FCL([Features.ACTIVE_FORUM_VIEW]);

  FCL.canFunnelsCampaigns = () => FCL([Features.FUNNELS_CAMPAIGNS]);

  FCL.canFunnelsImportLeads = () => FCL([Features.FUNNELS_IMPORT_LEADS]);

  FCL.canFunnelsTemplates = () => FCL([Features.FUNNELS_TEMPLATES]);

  FCL.canMembersAnalytics = () => FCL([Features.MEMBERS_ANALYTICS]);

  FCL.canMembersAutomation = () => FCL([Features.MEMBERS_AUTOMATION]);

  FCL.canMembersSubscriptionOffers = () => FCL([Features.MEMBERS_SUBSCRIPTION_OFFERS]);

  FCL.hasSparkAffiliates = () => FCL([Features.SPARK_AFFILIATES]);

  FCL.canSparkHomepage = () => FCL([Features.SPARK_HOMEPAGE]);

  FCL.canSparkMembers = () => FCL([Features.SPARK_MEMBERS]);

  FCL.canSparkSalesPageEditor = () => FCL([Features.SPARK_SALES_PAGE_EDITOR]);

  FCL.isUserAnAffiliate = () => FCL([Features.AFFILIATE]);

  FCL.canProgressReport = () => FCL([Features.HERO_PROGRESS_REPORT]);

  FCL.canActiveShowCaseHero = () => FCL([Features.ACTIVE_SHOWCASE_HERO]);

  FCL.canActiveShowCaseHero = () => FCL([Features.ACTIVE_SHOWCASE_HERO]);

  FCL.canActiveAutomationHero = () => FCL([Features.ACTIVE_AUTOMATION_HERO]);

  FCL.hasSparkCoproduction = () => FCL([Features.SPARK_COPRODUCTION]);

  FCL.canActiveNetflixHero = () => FCL([Features.ACTIVE_NETFLIX_HERO]);

  FCL.isCoproductor = () => FCL([Features.COPRODUCTOR]);

  FCL.offerRequireAddress = () => FCL([Features.OFFER_REQUIRE_ADDRESS]);

  FCL.canCreateOrderBump = () => FCL([Features.ORDER_BUMP]);

  FCL.canSubscriptionFrequency = () => FCL([Features.SUBSCRIPTION_FREQUENCY_LIMIT]);

  FCL.canSubscriptionOverduePayments = () => FCL([Features.SUBSCRIPTION_OVERDUE_PAYMENTS]);

  FCL.hasMemberArea = () => !FCL([Features.DISABLE_ENROLLMENT]);

  FCL.hasMulticardPaymentOption = () => FCL([Features.MULTICARD_PAYMENT_OPTION]);

  FCL.canLiveMeet = () => FCL([Features.LIVE_MEET_CONTENT]);

  FCL.hasBankSlipSubscription = () => FCL([Features.BANK_SLIP_SUBSCRIPTION]);

  FCL.hasPixSubscription = () => FCL([Features.PIX_SUBSCRIPTION]);

  FCL.hasCheckoutTracking = () => FCL([Features.CHECKOUT_TRACKING]);

  FCL.hasThemeLightMode = () => FCL([Features.THEME_LIGHT_MODE]);

  FCL.hasMembersCoursePage = () => FCL([Features.MEMBERS_COURSE_PAGE]);

  FCL.hasDrmOptionsEnabled = () => FCL([Features.DRM_OPTIONS_ENABLED]);

  FCL.hasDeactivateCopyright = () => FCL([Features.DEACTIVATE_COPYRIGHT]);

  FCL.hasXavierDisableMembersConfigView = () => !FCL([Features.XAVIER_DISABLE_MEMBERS_CONFIG_VIEW]);

  return FCL;
}
