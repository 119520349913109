import axiosXavier from '@/services/axios';

export default {
  // Path
  create: ({ name, all_courses, course_ids, image_url }) =>
    axiosXavier.post('paths', { path: { name, all_courses, course_ids, image_url } }).then(res => res.data),
  get: id => axiosXavier.get(`paths/${id}`),
  update: path => axiosXavier.put(`paths/${path.id}`, { path }),
  delete: id => axiosXavier.delete(`paths/${id}`),
  deletePathCourse: (id, course_id) => axiosXavier.put(`paths/${id}/remove_course?course_id=${course_id}`),
};
