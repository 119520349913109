import { sortLocaleWithKey } from '@/libs/strings';

export default {
  getSortedMembersList(state) {
    return state.members.sort((a, b) => b.id - a.id) || [];
  },

  getProducts(state) {
    return sortLocaleWithKey(state.products, 'title');
  },

  getSidebar(state) {
    return state.sidebar;
  },
};
