import httpClient from '@/services/sparkecommerce/http_client.js';

export default {
  get: () => {
    return httpClient.get('invoice_settings').then(({ data }) => data);
  },
  upsert: invoiceParams => {
    return httpClient.post('invoice_settings', invoiceParams);
  },
};
