import axios from 'axios';
import sparkui from '@edools/sparkui';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueCurrencyFilter from 'vue-currency-filter';
import Vuelidate from 'vuelidate';
import bootstrap from './bootstrap';
import VueFriendlyIframe from 'vue-friendly-iframe';
import './shared/helpers/class-component-hooks';

import './shared/assets/styles/global.css';
import '@edools/sparkui/dist/hr-design-system.css';
import './assets/global.scss';

import './libs/fontAwesome';

Vue.config.productionTip = false;
Vue.use(sparkui);
Vue.use(VueFriendlyIframe);
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueCurrencyFilter, {
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
});

bootstrap.init();

if (process.env.VUE_APP_DEVTOOLS_ENABLED) {
  Vue.config.devtools = true;
}
