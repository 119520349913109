export enum Permissions {
  CAN_HOMEPAGE = 'core_homepage_can_manage',
  CAN_PRODUCTPAGE = 'core_product_can_view',
  CAN_CREATEPRODUCT = 'core_product_can_create',
  CAN_DELETEPRODUCT = 'core_product_can_destroy',
  CAN_CONFIGPRODUCT = 'core_product_can_manage_product',
  CAN_SALEPRODUCT = 'core_product_can_manage_sale',
  CAN_FUNNELPAGE = 'core_funnel_can_manage',
  CAN_LEADSPAGE = 'core_lead_can_manage',
  CAN_CAMPAIGNPAGE = 'core_campaign_can_manage',
  CAN_SALESPAGE = 'core_sales_can_manage',
  CAN_WALLETPAGE = 'sparkpay_wallet_can_view',
  CAN_AFFILIATESPAGE = 'core_affiliate_can_manage',
  CAN_AUTOMATICPILOTPAGE = 'core_automatic_pilot_can_manage',
  CAN_MANAGEMYACCOUNT = 'core_my_account_can_read_my_account',
  CAN_MYACCOUNT_BUSINESS = 'core_my_account_can_manage_account',
  CAN_MYACCOUNT_DOMAINS = 'core_my_account_can_manage_dominio',
  CAN_MYACCOUNT_PLANS = 'heimdall_my_account_can_manage_plan',
  CAN_MYACCOUNT_TEAM = 'core_my_account_can_manage_team',
  CAN_MYACCOUNT_VITRINE = 'core_my_account_can_manage_vitrine',
  CAN_MYACCOUNT_STUDENT = 'core_my_account_can_manage_student_area',
  CAN_MESSAGESPAGE = 'core_messages_can_manage',
  CAN_THEME_VIEW = 'core_my_account_can_manage_theme',
}

export default Permissions;
