import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    members: [],
    products: [],
    selectedMember: {},
    sidebar: {
      open: false,
      data: {},
    },
  },
  actions,
  mutations,
  getters,
};
