import axiosXavier from '../../services/axios';
import { forumService, sectionService } from '@/services';

export default {
  get: lesson_id => axiosXavier.get(`lessons/${lesson_id}`).then(res => res.data),
  create: payload => axiosXavier.post('lessons', payload).then(res => res.data),
  update: payload => axiosXavier.put(`lessons/${payload.id}`, payload),
  delete: payload => axiosXavier.delete(`lessons/${payload.id}`, payload),
  async addCommentsInLesson({ title, description, editable = true, school_product_id, lesson_id }) {
    let forum = null;
    let section = null;

    const { forums } = await forumService.getAll([{ key: 'school_product_id', value: school_product_id }]);

    if (forums.length === 0) {
      forum = await forumService.create({
        title,
        description,
        editable,
        school_product_id,
      });
    } else forum = forums[0];

    const { sections } = await forumService.getForumSections(forum.id);

    if (sections.length === 0) {
      section = await sectionService.create({
        title,
        forum_id: forum.id,
        public: true,
      });
    } else section = sections[0];

    await this.update({
      id: lesson_id,
      section_id: section.id || null,
    });

    return { forum, section };
  },
};
