import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    products: [],
    selectedProduct: {},
    productPaymentOptions: [],
    productPaymentOption: {},
  },
  actions,
  mutations,
};
