import axiosXavier from '../../services/axios';
import SparkEcommerceApiClient from '@/services/sparkecommerce/api_client.js';
import store from '@/store';

export default {
  get(params) {
    if (store.state.school.selectedSchool.features.includes('spark_checkout')) {
      return SparkEcommerceApiClient.coupons.all(params);
    } else {
      let url = 'coupons?';
      params.forEach(p => {
        url += `&${p.key}=${p.value}`;
      });
      return axiosXavier.get(url).then(res => res.data);
    }
  },
  getGlobal(params) {
    return SparkEcommerceApiClient.coupons.allGlobal(params);
  },
  create(coupon) {
    if (store.state.school.selectedSchool.features.includes('spark_checkout')) {
      return SparkEcommerceApiClient.coupons.create(coupon);
    } else {
      return axiosXavier.post('coupons', { coupon });
    }
  },
  createGlobal(coupon) {
    return SparkEcommerceApiClient.coupons.createGlobalCoupon(coupon);
  },
  delete(couponId) {
    if (store.state.school.selectedSchool.features.includes('spark_checkout')) {
      return SparkEcommerceApiClient.coupons.delete(couponId);
    } else {
      return axiosXavier.delete(`coupons/${couponId}`);
    }
  },
};
