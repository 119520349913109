import router from '@/router';
import axios from 'axios';
import store from '@/store';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_SPARKAFFILIATES_URL,
});

httpClient.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json';
    const token = store.state.auth.loggedUser.jwt;

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error)
);

httpClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401 && !['SignUp', 'Login'].includes(router.currentRoute.name)) {
      router.push({ name: 'Logout' });
    }

    if (error.response && error.response.status === 404 && ['HomePage'].includes(router.currentRoute.name)) {
      router.push({ name: 'Logout' });
    }

    return Promise.reject(error);
  }
);

export default httpClient;
