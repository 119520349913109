import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    html: '',
    mjml: '',
    callbackRoute: null,
    variables: [],
  },
  mutations,
};
