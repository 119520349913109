export const DEFAULT_CHECKOUT_SETTINGS = {
  whatsapp_settings: null,
  require_address: false,
  require_phone: false,
  timer_settings: null,
  email_confirmation: true,
  background_settings: {
    color: '#E3E3E3',
  },
  banner_settings: null,
};

export const DEFAULT_TIMER_SETTINGS = {
  text: 'Não perca tempo',
  text_color: '#FFFFFF',
  background_color: '#FF305C',
  time: 900,
};

export const DEFAULT_WHATSAPP_SETTINGS = {
  whatsapp_text: 'Olá, fiquei com dúvidas sobre o seu produto',
  whatsapp_number: '',
};
