export enum Features {
  ACTIVE_AUTOMATION_HERO = 'active_automation_hero',
  ACTIVE_CERTIFICATE_VIEW = 'active_certificate_view',
  ACTIVE_CUSTOM_DOMAIN_CONFIG = 'active_custom_domain_config',
  ACTIVE_FORUM_VIEW = 'active_forum_view',
  ACTIVE_SHOWCASE_HERO = 'active_showcase_hero',
  ACTIVE_THEME_VIEW = 'active_theme_view',
  AFFILIATE = 'affiliate',
  ALLOW_COMBO_PRODUCT = 'allow_combo_product',
  EXPRESS_CHECKOUT = 'express_checkout',
  FUNNELS_CAMPAIGNS = 'funnels_campaigns',
  FUNNELS_IMPORT_LEADS = 'funnels_import_leads',
  FUNNELS_TEMPLATES = 'funnels_templates',
  HERO_ADMIN_USERS = 'hero_admin_users',
  MEMBERS_ANALYTICS = 'members_analytics',
  MEMBERS_AUTOMATION = 'members_automation',
  MEMBERS_IMPORT_USERS = 'members_import_users',
  MEMBERS_SUBSCRIPTION_OFFERS = 'members_subscription_offers',
  RELEASE_FOR_TIME_LESSON = 'release_for_time_lesson',
  SPARK_AFFILIATES = 'spark_affiliates',
  SPARK_CHECKOUT = 'spark_checkout',
  INVOICE_METABASE_REPORT = 'invoice_metabase_report',
  SPARK_HOMEPAGE = 'spark_homepage',
  SPARK_MEMBERS = 'spark_members',
  SPARK_SALES_PAGE_EDITOR = 'spark_sales_page_editor',
  HERO_PROGRESS_REPORT = 'active_hero_progress_report',
  SPARK_COPRODUCTION = 'spark_coproduction',
  COPRODUCTOR = 'coproductor',
  ACTIVE_NETFLIX_HERO = 'active_netflix_hero',
  OFFER_REQUIRE_ADDRESS = 'offer_require_address',
  ORDER_BUMP = 'order_bump',
  SUBSCRIPTION_FREQUENCY_LIMIT = 'subscription_frequency_limit',
  SUBSCRIPTION_OVERDUE_PAYMENTS = 'subscription_overdue_payments',
  DISABLE_ENROLLMENT = 'disable_members_area',
  MULTICARD_PAYMENT_OPTION = 'multicard_payment_option',
  LIVE_MEET_CONTENT = 'live_meet_content',
  BANK_SLIP_SUBSCRIPTION = 'bank_slip_subscription',
  PIX_SUBSCRIPTION = 'pix_subscription',
  UPSELL = 'upsell',
  CHECKOUT_TRACKING = 'checkout_tracking',
  THEME_LIGHT_MODE = 'theme_light_mode',
  MEMBERS_COURSE_PAGE = 'members_page_course_single',
  DRM_OPTIONS_ENABLED = 'enable_drm_options',
  DEACTIVATE_COPYRIGHT = 'deactivate_copyright',
  XAVIER_DISABLE_MEMBERS_CONFIG_VIEW = 'xavier_disable_members_config_view',
}
export default Features;
