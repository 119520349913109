import axiosXavier from '@/services/axios';

export default {
  get: school_id => axiosXavier.get(`schools/${school_id}/certificate_layouts/`).then(res => res.data),

  create: certificate_layout =>
    axiosXavier
      .post(`schools/${certificate_layout.school_id}/certificate_layouts/`, {
        certificate_layout,
      })
      .then(res => res.data),

  update: certificate_layout =>
    axiosXavier
      .put(`certificate_layouts/${certificate_layout.id}`, {
        id: certificate_layout.id,
        certificate_layout,
      })
      .then(res => res.data),
};
