export default {
  setDomains(state, domains) {
    state.selectedSchool.domains = domains;
  },
  setFeatures(state, features) {
    if (state.selectedSchool) state.selectedSchool.features = features;
  },
  addFeature(state, feature) {
    state.selectedSchool.features = [...state.selectedSchool.features, feature];
  },
  setSchools(state, schools) {
    state.items = schools;
  },
  setSelectedSchool(state, school) {
    state.selectedSchool = { ...state.selectedSchool, ...school };
  },
  setNewSchool(state, school) {
    state.newSchool = { ...state.school, ...school };
  },
  setPhoneValidated(state, data) {
    state.selectedSchool.allow_change_phone = data['allow_change_phone'];
    state.selectedSchool.phone_verified_at = data['phone_verified_at'];
  },
  setSchoolValidation(state, data) {
    state.selectedSchool.validated_info = data;
  },
};
