import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  actions,
  mutations,
  state: {
    selectedTab: 0,
    actualPlan: null,
  },
};
