import axiosXavier from './axios';
import browserHelper from '@/shared/helpers/browser';

export default {
  getJWT(from) {
    let return_url = `?locale=${browserHelper.getBrowserLanguage()}`;
    return_url += from ? `&from=${from}` : '';
    return axiosXavier.post(`user_token${return_url}`);
  },
};
