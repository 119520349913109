import axiosXavier from './axios';

export default {
  getById: id =>
    axiosXavier
      .get(`channels/${id}`)
      .then(res => res.data)
      .catch(err => Promise.reject(err)),

  getChannelMessages: id =>
    axiosXavier
      .get(`channels/${id}/messages`)
      .then(res => res.data)
      .catch(err => Promise.reject(err)),

  create: channel =>
    axiosXavier
      .post('channels', {
        channel,
      })
      .then(res => res.data)
      .catch(err => Promise.reject(err)),

  createMessage: message =>
    axiosXavier
      .post(`channels/${message.channel_id}/messages`, {
        message,
      })
      .then(res => res.data)
      .catch(err => Promise.reject(err)),
};
