export default {
  slug: 'pro',
  segmentSlug: 'pro',
  title: 'Growth',
  active: false,
  price: 0,
  currency: 'R$',
  content: '',
  highlited: false,
  callConsultant: false,
  features: {
    previousPlan: null,
    highlight: [],
    groups: [],
  },
};
