export default {
  SET_CONFIGS(state, configs) {
    state.configs = configs;
  },
  UPDATE_GENERAL_CONFIG(state, { key, value }) {
    const oldState = state.configs;
    state.configs = {
      ...oldState,
      general: {
        ...oldState.general,
        [key]: value,
      },
    };
    state.hasChanges = true;
  },
  UPDATE_SECTION_CONFIG(state, { key, value, kind }) {
    const oldState = state.configs;
    const section = oldState.sections.find(section => section.kind === kind);
    if (state.hasDefaultSections) {
      section[key] = value;
      state.configs = {
        ...oldState,
        sections: [
          ...oldState.sections.map(section => (section.kind === kind ? { ...section, [key]: value } : section)),
        ],
      };
      state.hasChanges = true;
      state.hasDefaultSections = false;
      return;
    }

    state.configs = {
      ...oldState,
      sections: [...oldState.sections.map(section => (section.kind === kind ? { ...section, [key]: value } : section))],
    };
    state.hasChanges = true;
    state.hasDefaultSections = false;
  },
  SET_SECTIONS_INITIAL_STATE(state, sections) {
    state.configs.sections = [
      {
        active: true,
        card_format: 'horizontal',
        kind: 'school_products',
        order: 1,
      },
    ];
    state.hasDefaultSections = true;
  },
  SET_GENERAL_INITIAL_STATE(state) {
    state.configs.general = {
      show_product_name: true,
      favicon_url: null,
    };
  },
  SET_FOOTER_INITIAL_STATE(state) {
    state.configs.footer = {
      show_herospark_logo: true,
      show_external_links: false,
      show_custom_text: false,
      external_links: [],
      custom_text: null,
    };
    state.hasDefaultFooter = true;
  },
  UPDATE_FOOTER_CONFIG(state, { data }) {
    const oldState = state.configs;
    state.configs = {
      ...oldState,
      footer: {
        ...oldState.footer,
        ...data,
      },
    };

    state.hasChanges = true;
    state.hasDefaultFooter = false;
  },
  SET_BANNER_CONFIG(state, banners) {
    const oldState = state.configs;
    state.configs = {
      ...oldState,
      header: {
        ...oldState.header,
        banners: [...banners],
      },
    };
  },
  UPDATE_BANNER_CONFIG(state, { data }) {
    const oldState = state.configs;

    state.configs = {
      ...oldState,
      header: {
        ...oldState.header,
        banners: [...data],
      },
    };
    state.hasChanges = true;
  },
  SET_BANNER_INITIAL_STATE(state) {
    state.configs.header = {
      banners: [],
    };
  },
  RESET_HAS_UPDATES(state) {
    const oldState = state.configs;
    state.configs = {
      ...oldState,
      has_updates: false,
    };

    state.hasChanges = false;
  },
  SET_UPDATED_AT(state, { data }) {
    state.updatedAt = data;
  },
};
