import Permissions from '@/types/permissions';
/**
 * Global method that returns a boolean if the user has the permission
 * @param {Permissions[]} permissions - List of permissions
 **/

export default function(store) {
  const ACL = permissions => permissions.every(permission => store.getters['auth/hasPermission'](permission));

  ACL.canHomePage = () => ACL([Permissions.CAN_HOMEPAGE]);

  ACL.canProductPage = () => ACL([Permissions.CAN_PRODUCTPAGE]);

  ACL.canCreateProduct = () => ACL([Permissions.CAN_CREATEPRODUCT]);

  ACL.canDeleteProduct = () => ACL([Permissions.CAN_DELETEPRODUCT]);

  ACL.canProductConfig = () => ACL([Permissions.CAN_CONFIGPRODUCT]);

  ACL.canProductSale = () => ACL([Permissions.CAN_SALEPRODUCT]);

  ACL.canFunnelPage = () => ACL([Permissions.CAN_FUNNELPAGE]);

  ACL.canLeadsPage = () => ACL([Permissions.CAN_LEADSPAGE]);

  ACL.canCampaignPage = () => ACL([Permissions.CAN_CAMPAIGNPAGE]);

  ACL.canSalesPage = () => ACL([Permissions.CAN_SALESPAGE]);

  ACL.canWalletPage = () => ACL([Permissions.CAN_WALLETPAGE]);

  ACL.canAffiliatesPage = () => ACL([Permissions.CAN_AFFILIATESPAGE]);

  ACL.canAutomaticPilotPage = () => ACL([Permissions.CAN_AUTOMATICPILOTPAGE]);

  ACL.canMyAccountSettings = () => ACL([Permissions.CAN_MANAGEMYACCOUNT]);

  ACL.canMyAccountBusiness = () => ACL([Permissions.CAN_MYACCOUNT_BUSINESS]);

  ACL.canMyAccountDomains = () => ACL([Permissions.CAN_MYACCOUNT_DOMAINS]);

  ACL.canMyAccountPlans = () => ACL([Permissions.CAN_MYACCOUNT_PLANS]);

  ACL.canMyAccountTeam = () => ACL([Permissions.CAN_MYACCOUNT_TEAM]);

  ACL.canMyAccountVitrine = () => ACL([Permissions.CAN_MYACCOUNT_VITRINE]);

  ACL.canMyAccountStudent = () => ACL([Permissions.CAN_MYACCOUNT_STUDENT]);

  ACL.canMessagesPage = () => ACL([Permissions.CAN_MESSAGESPAGE]);

  ACL.canThemeView = () => ACL([Permissions.CAN_THEME_VIEW]);

  return ACL;
}
