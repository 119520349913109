import axiosXavier from './axios';
import GeneralHelper from '@/shared/helpers/general';

export default {
  get: id => axiosXavier.get(`collaborators/${id}`).then(res => res.data),

  getUsers: params => axiosXavier.get(`collaborators?${GeneralHelper.parseToQueryString(params)}`),

  update: user =>
    axiosXavier.put(`collaborators/${user.id}`, {
      id: user.id,
      ...{ user },
    }),

  delete: userId => axiosXavier.delete(`collaborators/${userId}`),

  acceptTerms(userId) {
    return axiosXavier.put(`collaborators/${userId}/terms`, {
      consense: true,
    });
  },
};
