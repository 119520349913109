import TokenHelper from '@/shared/helpers/token';
import Vue from 'vue';

export default {
  setLoggedUser(state, user) {
    state.loggedUser = { ...user };
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  setToken(state, token) {
    token ? TokenHelper.saveToken(token) : TokenHelper.removeToken();
    state.token = TokenHelper.getToken(token);
  },
  setConsense(state, value) {
    Vue.set(state.loggedUser, 'terms_of_use_consense', value);
  },
  setChannels(state, channels) {
    state.channels = channels;
  },
};
