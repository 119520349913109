const TOKEN_KEY = 'access_token';

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenHelper = {
  getToken() {
    try {
      return localStorage.getItem(TOKEN_KEY);
    } catch (e) {
      return '';
    }
  },

  saveToken(accessToken) {
    const initToken = 'Token token=';

    if (accessToken.indexOf(initToken) !== 0 && accessToken.includes(':')) {
      accessToken = `${initToken}${accessToken}`;
    } else if (!accessToken.includes('Bearer')) {
      accessToken = `Bearer ${accessToken}`;
    }
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },
};

export default TokenHelper;
