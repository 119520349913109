import offers from '@/services/sparkecommerce/offers.js';
import products from '@/services/sparkecommerce/products.js';
import orderBump from '@/services/sparkecommerce/order-bump';
import coupons from '@/services/sparkecommerce/coupons.js';
import payments from '@/services/sparkecommerce/payments.js';
import invoiceSettings from '@/services/sparkecommerce/invoice-settings.js';
import upsell from '@/services/sparkecommerce/upsell.js';
import salesReport from '@/services/sparkecommerce/sales-reports.js';
import reportExportRequests from '@/services/sparkecommerce/report-export-requests.js';

export default {
  offers,
  products,
  orderBump,
  coupons,
  payments,
  invoiceSettings,
  upsell,
  salesReport,
  reportExportRequests,
};
