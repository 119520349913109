import axiosXavier from '@/services/axios';
import generalHelper from '@/shared/helpers/general';

export default {
  get: id => axiosXavier.get(`live_meets/${id}`).then(res => res.data),
  getProductLives: (product_id, params = {}) =>
    axiosXavier
      .get(`school_products/${product_id}/live_meets?${generalHelper.parseToQueryString(params)}`)
      .then(res => res.data),
  getAll: params => axiosXavier.get(`live_meets?${generalHelper.parseToQueryString(params)}`).then(res => res.data),
  getRecordTime() {
    return axiosXavier.get('/utilizations/live_meet').then(res => res.data);
  },
};
