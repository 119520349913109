import { getDefaultState } from '.';
import Vue from 'vue';

export default {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setSendAt(state, sendAt) {
    Vue.set(state.emailTemplate.datetime, 'sendAt', sendAt);
  },
  setBody(state, body) {
    Vue.set(state.emailTemplate.data, 'body', body);
  },
  setMjml(state, mjml) {
    Vue.set(state.emailTemplate.data, 'mjml', mjml);
  },
  setSubject(state, subject) {
    Vue.set(state.emailTemplate.data, 'subject', subject);
  },
  setFunnel(state, funnel) {
    Vue.set(state, 'funnel', funnel);
    state.funnel = funnel;
  },
  setName(state, name) {
    Vue.set(state, 'name', name);
  },
  setTabIndex(state, index) {
    Vue.set(state, 'tabIndex', index);
  },
};
