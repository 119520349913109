import axiosXavier from '@/services/axios';
import GeneralHelper from '@/shared/helpers/general';

export default {
  getAll(params) {
    return axiosXavier.get(`school/forums?${GeneralHelper.parseToQueryString(params)}`).then(res => res.data);
  },
  create: forum =>
    axiosXavier
      .post('school/forums', {
        forum,
      })
      .then(res => res.data),
  getForumSections: forum_id => axiosXavier.get(`school/forums/${forum_id}/sections`).then(res => res.data),
  getForumTopics: (forum_id, params) =>
    axiosXavier
      .get(`school/forums/${forum_id}/topics?${GeneralHelper.parseToQueryString(params)}`)
      .then(res => res.data),
  answerTopic: (forum_id, section_id, topic_id, message) =>
    axiosXavier
      .post(`school/forums/${forum_id}/sections/${section_id}/topics/${topic_id}/posts`, { body: message })
      .then(res => res.data),
};
