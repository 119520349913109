import store from '@/store';
import generalHelper from '@/shared/helpers/general';

const isInternalUser = () => {
  const { email } = store?.state?.auth?.loggedUser || { email: '' };
  return ['contato@edools.com'].includes(email);
};

export default {
  group: ({ groupId, traits }) => {
    if (isInternalUser()) return;

    return window['rudderanalytics'].group(groupId, traits);
  },
  identify: (callback, extraArgs) => {
    if (isInternalUser()) return;

    if (store.getters['auth/isAuthenticated']) {
      const { id, email, phone, role } = store.state.auth.loggedUser;
      const { uid } = store.state.school.selectedSchool;
      const schoolId = store.state.school.selectedSchool.id;
      let properties = {
        school_id: schoolId,
        role: role.name,
        ...generalHelper.splitName(store.getters['auth/name']),
        email,
        phone,
        SchoolSubdomain: store.getters['school/getDefaultDomain'],
        ...(store.state.school.selectedSchool.domains && {
          SchoolCustomDomain: store.state.school.selectedSchool.domains[0],
        }),
        customer_id: uid,
        ...extraArgs,
      };
      let eventCallback = callback ? callback : {};

      window['rudderanalytics'].identify(id, properties, eventCallback);
    }
  },
  reset: () => {
    window['rudderanalytics'].reset();
  },
  track: ({ event, props, context, callback }) => {
    if (isInternalUser()) return;

    const schoolId = store?.state?.school?.selectedSchool?.id;
    const propsWithSchoolId = schoolId ? { ...props, school_id: schoolId } : { ...props };

    const url = new URL(window.location.href);

    url.searchParams.set('event', event);
    window.history.pushState({}, '', url.href);
    let eventContext = context ? context : {};
    let eventCallback = callback ? callback : {};
    window['rudderanalytics'].track(event, propsWithSchoolId, eventContext, eventCallback);
  },
};
