import store from '@/store';

export default function(to, _from, next) {
  const id = parseInt(to.params.id);

  if (!store.state.product.selectedProduct || store.state.product.selectedProduct.id !== id) {
    store.dispatch('product/getProduct', { id }).then(
      res => {
        const { id, type } = res.library_resource.resource;
        dispatchByCourseType({ id, type });
        next();
      },
      () => {
        next({ name: 'Products' });
      }
    );
  } else {
    next();
  }
}

export function dispatchByCourseType({ id, type }) {
  const courseType = type === 'path' ? 'Path' : 'Course';
  store.dispatch(`course/get${courseType}`, id);
}
