import axios from 'axios';
import { checkUnauthorizedResponse } from '@/libs/authentication';
import store from '@/store';

const axiosHeimdall = axios.create({
  baseURL: `${process.env.VUE_APP_HEIMDALL_BASE_URL}/api/`,
});

axiosHeimdall.interceptors.request.use(
  config => {
    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/json';

    switch (config.authMethod) {
      case 'jwt':
        config.headers['Authorization'] = `Bearer ${store.state.auth.loggedUser.jwt}`;
        break;
      case 'token':
        config.headers['Authorization'] = process.env.VUE_APP_HEIMDALL_TOKEN;
        break;
      default:
        config.headers['Authorization'] = '';
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosHeimdall.interceptors.response.use(response => response, checkUnauthorizedResponse);

export default axiosHeimdall;
