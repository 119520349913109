import axiosXavier from '../axios';
import axiosHeimdall from './axios';
import PLANS from './plans';

const ACTIVE_PLANS = PLANS.filter(plan => plan.active);

const nextPlans = {
  0: 1, // new free -> basic
  1: 1, // old free -> basic
  2: 2, // growth -> advanced
  3: 2, // basic -> advanced
  4: 3, // advanced -> plus
};

const getPlans = () => PLANS;
const getPlan = slug => PLANS.find(curr => curr.slug === slug);
const getActivePlans = () => ACTIVE_PLANS;

// get schools's current plan
const getCurrent = async school => {
  try {
    let {
      data: { plan_slug },
    } = await axiosHeimdall.get(`v3/orders/${school.id}/current_plan_slug`, { authMethod: 'jwt' });

    const orders = await getOrders();
    const lastOrder = orders[0];

    if (lastOrder?.canceled_at) return getPlan(PLANS[1].slug);

    const plan = getPlan(plan_slug);
    return {
      ...plan,
      price: lastOrder?.price,
      months_duration: lastOrder?.months_duration,
      activated_at: lastOrder?.activated_at,
    };
  } catch (error) {
    return error || new Error('Something went wrong');
  }
};

// get school's next plan
const getNextPlan = currentPlan => {
  currentPlan = getPlan(currentPlan.slug);
  return ACTIVE_PLANS[nextPlans[PLANS.indexOf(currentPlan)]] || null;
};
const getPreviousPlan = currentPlan => {
  // test if current plan is one of old plans and set it like Basic plan, that have the same previous plan.
  // 0 -> old free plan / 2 -> growth.
  const index = [0, 2].includes(PLANS.indexOf(currentPlan)) ? 1 : ACTIVE_PLANS.indexOf(currentPlan);
  if (currentPlan.slug === 'pro' || currentPlan.slug === 'basic_pro') return ACTIVE_PLANS[0];
  if (currentPlan.slug === 'free' || currentPlan.slug === 'free_legado' || currentPlan.slug === 'heroPerpetual')
    return null;
  return ACTIVE_PLANS[index - 1];
};

const canDowngrade = (currentPlan, nextPlan) => {
  currentPlan = getPlan(currentPlan.slug);
  nextPlan = getPlan(nextPlan.slug);
  const indexCurr = PLANS.indexOf(currentPlan);
  const indexNext = PLANS.indexOf(nextPlan);
  if ((currentPlan.slug === 'pro' || currentPlan.slug === 'basic_pro') && nextPlan.slug === 'free') return true;
  if (currentPlan.slug === 'heroPerpetual') return false;
  return indexCurr > indexNext && (indexCurr !== 1 && nextPlan !== 2);
};

// orders info and update
const getOrders = async () => {
  const orders = await axiosHeimdall.get(`v3/orders`, { authMethod: 'jwt' });
  return orders.data;
};

const getFeatureLimit = async () => {
  return await axiosHeimdall.get(`v3/platforms/features_limits`, { authMethod: 'jwt' });
};

const downgradePlan = ({ id, data }) => axiosHeimdall.delete(`v3/orders/${id}`, { authMethod: 'jwt', data: data });

const changePlan = ({ order, customer, payment_params }) =>
  axiosHeimdall.post(`v3/orders`, { order, customer, payment_params }, { authMethod: 'jwt' });

// invoices infos
const getInvoices = async () => {
  try {
    const { data = [] } = await axiosXavier.get(`crm/get_invoices`);

    if (!data.length) return [];

    return data
      .filter((invoice, index) => index === 0 || invoice.status === 'payment_confirmed')
      .map(invoice => ({
        amount: invoice.amount_charged,
        date: invoice.date,
        url: invoice.invoice_url,
        status: invoice.status,
        cardInfo: {
          lastDigits: invoice.card_info && invoice.card_info.lastDigits,
          brand: invoice.card_info && invoice.card_info.brand && invoice.card_info.brand.toLowerCase(),
        },
      }));
  } catch (error) {
    return error || new Error('Something went wrong');
  }
};

const getPaymentInfo = async () => {
  const { data = [] } = await axiosXavier.get(`crm/get_invoices`);

  if (!data.length) return null;

  return {
    nextCharge: data[0].date,
    creditCard: {
      brand: data[0].card_info && data[0].card_info.brand && data[0].card_info.brand.toLowerCase(),
      last4Digits: data[0].card_info && data[0].card_info.lastDigits,
    },
  };
};

export default {
  canDowngrade,
  downgradePlan,
  getActivePlans,
  getCurrent,
  getInvoices,
  getOrders,
  getNextPlan,
  getPaymentInfo,
  getPlan,
  getPlans,
  getPreviousPlan,
  changePlan,
  getFeatureLimit,
};
