export default {
  setActiveLeadsCount(state, active_leads_count) {
    state.active_leads_count = active_leads_count;
  },
  setActiveStudentsCount(state, active_students_count) {
    state.active_students_count = active_students_count;
  },
  setDashboard(state, data) {
    state.dashboard = data;
  },
  setHomeInfo(state, data) {
    Object.assign(state, data);
  },
  setCanSeeMoney(state, canSeeMoney) {
    state.canSeeMoney = canSeeMoney;
  },
};
