import axiosXavier from '../../services/axios';

export default {
  create: section =>
    axiosXavier
      .post(`/school/forums/${section.forum_id}/sections`, {
        section,
      })
      .then(res => res.data),
};
