import { productService, pagesService } from '@/services';
import i18n from '@/shared/helpers/i18n';
import GenerateUUIDHelper from '@/sparkmembers/helpers/generateUUIDHelper';

export default {
  getAllProducts(context, { params }) {
    return productService.getAll(params);
  },
  getProduct({ commit }, { id }) {
    return productService.get(id).then(({ data }) => {
      commit('setProduct', data);
      return data;
    });
  },
  setProduct({ commit }, product) {
    commit('setProduct', product);
  },
  updateProduct({ commit, state }, school_product) {
    const newProduct = { ...state.selectedProduct, ...school_product };
    return productService.update(school_product).then(() => {
      commit('setProduct', newProduct);
      return newProduct;
    });
  },
  getProductPaymentOptions({ commit }, { id }) {
    return productService.getProductPaymentOptions(id).then(({ data }) => {
      commit('setProductPaymentOptions', data.payment_options);
      return data.payment_options;
    });
  },
  getProductPaymentOptionsWithHeaders({ commit }, { id, params }) {
    return productService.getProductPaymentOptions(id, params).then(({ data, headers }) => {
      commit('setProductPaymentOptions', data.payment_options);
      return {
        paymentOptions: data.payment_options,
        headers,
      };
    });
  },
  getProductPaymentOption({ commit }, payment_option) {
    commit('setProductPaymentOption', payment_option);
    return payment_option;
  },
  updateProductPaymentOption({ commit }, payload) {
    return productService.updateProductPaymentOption(payload.school_product_id, payload).then(() => {
      payload.price = payload.price / 100;
      payload.base_price = payload.base_price / 100;

      commit('setProductPaymentOption', payload);
      return payload;
    });
  },
  async getProductPageList({ commit, state, rootState }) {
    try {
      const pages = await productService.getProductPageList(state?.selectedProduct?.id);
      const data = await pagesService.getPages({
        jwt: `Bearer ${rootState.jwt.encoded}`,
        pageList: pages.map(p => p.page_id),
      });

      data.forEach((value, i) => (pages[i] = { ...pages[i], ...value }));

      commit('setProductPages', pages);
    } catch (error) {
      return null;
    }
  },
  async createProductPage({ commit, state, rootState }) {
    try {
      const index = 1 + state.selectedProduct?.pages?.length || 0;
      const page = await productService.createProductPage(state?.selectedProduct?.id);

      page.title = `${i18n.$t('sparkmembers.setup.sales-page.editor.new-page.prefix-page-title')} ${index}`;
      page.name = GenerateUUIDHelper();

      const updatedPage = {
        ...page,
        ...(await pagesService.updatePage({ jwt: `Bearer ${rootState.jwt.encoded}`, page })),
      };

      commit('addProductPage', updatedPage);
      return updatedPage;
    } catch (error) {
      return null;
    }
  },
  deleteProductPage({ commit }, pageId) {
    return productService.deleteProductPage(pageId).then(() => commit('removeProductPage', pageId));
  },
  updateProductPage({ commit, rootState }, page) {
    return pagesService
      .updatePage({ jwt: `Bearer ${rootState.jwt.encoded}`, page })
      .then(() => commit('updateProductPage', page));
  },
};
